import React from 'react'
import { Map, Placemark, useYMaps } from '@pbe/react-yandex-maps'
import PvzPinIcon from 'public/pin.png'

interface MapPropsType {
  coordinates?:
    | any[]
    | {
        name?: string
        coordinates: number[]
      }[]
    | []
  width?: string | number | undefined
  height?: string | number | undefined
  handleMarkClick?: (value: any) => void
  markerDisabled?: boolean
  disabled?: boolean
  onMapClick?: (data: any) => void
  zoom?: number
}

function Maps({
  coordinates,
  width,
  height,
  handleMarkClick,
  markerDisabled,
  disabled,
  onMapClick,
  zoom = 11
}: MapPropsType) {
  const ymaps = useYMaps(['Map', 'geocode'])

  const onMapClickHandler = async (event: any) => {
    const coords = event.get('coords')
    const geocode = ymaps && (await ymaps.geocode(coords, { kind: 'house' }))
    const locationObject: any = geocode?.geoObjects?.get(0)

    if (typeof onMapClick === 'function')
      onMapClick({
        coordinates: coords,
        fullname: locationObject?.getAddressLine() ?? '',
        // country: locationObject?.getCountry(),
        city: locationObject?.getLocalities()[0],
        street: `${locationObject?.getThoroughfare()} ${locationObject?.getPremiseNumber()}`
      })
  }

  return (
    <Map
      modules={['geocode']}
      width={width as number | string}
      height={height as number | string}
      state={{
        center: coordinates?.length ? coordinates?.[0]?.coordinates : ([43.318471, 45.680886] as number[]),
        zoom
      }}
      onClick={disabled ?? onMapClickHandler}
    >
      {coordinates &&
        Boolean(coordinates?.length) &&
        coordinates.map((v) => (
          <Placemark
            key={JSON.stringify(v)}
            onClick={() => markerDisabled && handleMarkClick && handleMarkClick(v)}
            geometry={v.coordinates}
            options={{
              iconLayout: 'default#image',
              iconImageHref: PvzPinIcon,
              iconImageSize: [46, 84],
              iconImageOffset: [-23, -84]
            }}
          />
        ))}
    </Map>
  )
}

export default Maps
