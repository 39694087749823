import React from 'react'
import { Text } from 'components/UI'
import s from './style.module.scss'

const CustomLegend = ({
  orderedItemsTotal,
  deliveredItemsTotal
}: {
  orderedItemsTotal: number
  deliveredItemsTotal: number
}) => (
  <div className={s['custom-legend']}>
    <div className={s['custom-legend__ordered']}>
      <Text size='sm' className={s['custom-legend__title']} as='p' weight='medium' align='center'>
        Заказано товаров на сумму
      </Text>
      <Text size='md' as='p' weight='medium' align='start'>
        {orderedItemsTotal} ₽
      </Text>
    </div>
    <div className={s['custom-legend__delivered']}>
      <Text size='sm' as='p' className={s['custom-legend__title']} weight='medium' align='center'>
        Доставлено товаров на сумму
      </Text>
      <Text size='md' as='p' weight='medium' align='start'>
        {deliveredItemsTotal} ₽
      </Text>
    </div>
  </div>
)

export default CustomLegend
