import React from 'react'
import { useLocation } from 'react-router-dom'
import { ProductLayout } from 'components/product'
import Product from 'components/product/Product'

const ProductAddNewPage = () => {
  const location = useLocation()
  const locationState = location.state

  return (
    <ProductLayout title='Добавление нового товара'>
      <Product mode={locationState?.productId ? 'copy' : 'create'} />
    </ProductLayout>
  )
}

export default ProductAddNewPage
