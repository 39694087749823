export const routes = {
  HOME: '/',
  AUTH: '/auth',
  DASHBOARD: '/',
  ORDERS: '/orders',
  PRODUCTS: '/products',
  PRODUCTS_ADD: '/products/add',
  PRODUCTS_ADD_NEW: '/products/add/new',
  PRODUCTS_EDIT: '/products/edit',
  REVIEWS: '/reviews',
  CHATS: '/chats',
  CHATS_ID: '/chats/:id',
  SETTINGS: '/settings',
  REGISTRATION: '/registration'
}
