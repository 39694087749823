import React, { useState } from 'react'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { IPhoneAuthForm, TPhoneAuthResponse } from 'models'
import { usePhoneAuthMutation } from 'redux/auth/authApi'
import { Button, FormError, Input, Text } from 'components/UI'
import Otp from './Otp'
import s from './phone-auth.module.scss'

interface IPhoneAuthProps {
  onAuthTypeChange: (data: string) => void
}

const PhoneAuth = ({ onAuthTypeChange }: IPhoneAuthProps) => {
  const [phoneAuth, { isLoading }] = usePhoneAuthMutation()
  const [isShowEnterCode, setIsShowEnterCode] = useState(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [resendTimer, setResendTimer] = useState<number>(50)

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<IPhoneAuthForm>()

  const onSubmitNumber = async ({ phoneNumber }: IPhoneAuthForm) => {
    try {
      const response: TPhoneAuthResponse = await phoneAuth({ phoneNumber: phoneNumber.replace(/[- )(]/g, '') }).unwrap()
      setResendTimer(response?.resend as number)
      setIsShowEnterCode(true)
    } catch (err: any) {
      if (!err?.status) {
        setErrMsg('Нет ответа от сервера')
      } else if (err.status) {
        setErrMsg(err.message)
      } else {
        setErrMsg('Регистрация не удалась')
      }
    }
  }

  if (isShowEnterCode) {
    return (
      <Otp
        phone={getValues('phoneNumber')}
        resendTimer={resendTimer}
        onAuthTypeChange={onAuthTypeChange}
        onChangeNumber={setIsShowEnterCode}
        onRepeatCode={onSubmitNumber}
      />
    )
  }

  return (
    <form className={cx(s['phone-auth'], 'offset-top-40')} onSubmit={handleSubmit(onSubmitNumber)}>
      <Text as='p' family='object-sans' weight='regular' className={s.number}>
        Номер телефона
      </Text>
      <Text as='p' family='object-sans' weight='regular' className={s.description} color='gray'>
        Введите номер телефона и мы отправим СМС с&nbsp;кодом подтверждения
      </Text>
      <Input
        name='phoneNumber'
        view='auth'
        type='tel'
        register={register}
        rules={{ required: { value: true, message: 'Не заполнено поле телефон' } }}
        errors={errors}
        disabled={isLoading}
        classNameInputWrapper='offset-top-8'
        autoComplete='off'
        autoFocus
        fluid
      />
      <FormError message={errMsg} />
      <Button className={cx('offset-top-12', s['button-submit'])} type='submit' disabled={isLoading} fluid>
        Получить код
      </Button>
      <Button className='offset-top-92' onClick={() => onAuthTypeChange('email')} view='link' fluid>
        Войти с помощью почты
      </Button>
    </form>
  )
}

export default PhoneAuth
