import React, { useEffect, useState } from 'react'

interface ITimerProps {
  range: number
  setDue: (value: boolean | ((prevVar: boolean) => boolean)) => void
}

const Timer = ({ range, setDue }: ITimerProps) => {
  const [time, setTime] = useState(range)

  useEffect(() => {
    if (time > 0) setTimeout(() => setTime(time - 1), 1000)
    if (time <= 0) setDue(true)
  }, [time])

  return (
    <span>
      {Math.floor(time / 60) < 10 ? `0${Math.floor(time / 60)}` : Math.floor(time / 60)}:
      {time % 60 < 10 ? `0${time % 60}` : time % 60}
    </span>
  )
}

export default Timer
