import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames/bind'
import { Text } from 'components/UI'
import { ReactComponent as Avatar } from 'public/avatar.svg'
import { useChatContext } from '../../../pages/Chat/Chat'
import { useAppSelector } from '../../../redux/hook'
import { SOCKET_EVENTS } from '../constants_chat'
import { IChat } from '../types'
import styles from './styles/conservationItems.module.scss'

const cn = classNames.bind(styles)

interface IConserVationItems {
  _id: string
  updatedAt: Date
  lastMessage: string
  counter: number
  companion: IChat['companion']
}

const ConservationItems: React.FC<IConserVationItems> = ({ _id, updatedAt, lastMessage, counter, companion }) => {
  const { setDialogs, socket, setPending, typingDialogs } = useChatContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const [activeDialog, setActiveDialog] = useState<boolean | null>(null)

  const getCorrectTime = () => {
    const messageDate = new Date(updatedAt)
    const dateNow = new Date()

    if (!updatedAt) return ''

    if (
      messageDate.getDate() + messageDate.getMonth() + messageDate.getFullYear() !==
      dateNow.getDate() + dateNow.getMonth() + dateNow.getFullYear()
    ) {
      return `${messageDate.getDate()}.${
        messageDate.getMonth() < 9 ? `0${messageDate.getMonth() + 1}` : messageDate.getMonth() + 1
      }`
    }

    return `${messageDate.getHours()}:${
      messageDate.getMinutes() < 10 ? `0${messageDate.getMinutes()}` : messageDate.getMinutes()
    }`
  }

  useEffect(() => {
    setActiveDialog(_id === id)
  }, [id])

  useEffect(() => {
    setPending([])
  }, [id])

  const handlerSetDialog = () => {
    navigate(`/chats/${_id}`)

    socket.emit(SOCKET_EVENTS.READING_MESSAGES, {
      _id,
      user: activeShop?._id
    })

    setDialogs((prev: any) =>
      prev.map((dialog: any) => {
        if (dialog._id.toString() === _id) {
          dialog.counter = 0
        }
        return dialog
      })
    )
  }

  return (
    <div
      key={_id}
      className={cn(styles.dialogs__item, {
        [styles.dialogs__item_active]: activeDialog
      })}
      onClick={handlerSetDialog}
    >
      <div className={styles.dialogs__avatar}>
        <Avatar />
        {/* <img src='https://ui-avatars.com/api/?rounded=true&background=E3E7E9&size=44' alt='' /> */}
      </div>
      <div className={styles.dialogs__right}>
        <div className={styles.dialogs__row}>
          <Text className={styles.dialogs__row_title}>{companion?.name}</Text>
          <div className={styles.dialogs__row_date}>{getCorrectTime()}</div>
        </div>
        <div className={styles.dialogs__row_last_message}>
          {typingDialogs.includes(_id.toString()) ? (
            'печататет...'
          ) : (
            <Text weight='regular' className={styles.dialogs__row_last_message_text}>
              {lastMessage}
            </Text>
          )}

          {counter > 0 && (
            <div className={styles.dialogs__row_counter}>
              <span />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConservationItems
