import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Conservation from 'components/Chat/Conservation/Conservation'
import { PageHeader } from 'components/UI'
import { IChat, IMessage } from 'components/Chat/types'
import $api from '../../components/http/axios'
import useSocket from '../../components/Hooks/useSocket'
import MessagesContainer from '../../components/Chat/Messages/MessagesContainer'
import { useAppSelector } from '../../redux/hook'
import { SOCKET_EVENTS } from '../../components/Chat/constants_chat'
import styles from './styles/chat.module.scss'

const chatContext = React.createContext<any>({})

function Chat() {
  const { id } = useParams()
  const socket = useSocket()
  const [dialogs, setDialogs] = useState<IChat[]>([])
  const [images, setImages] = useState({})
  const [loading, setLoading] = useState(true)
  const [typingDialogs, setTypingDialogs] = useState<any[]>([])
  const [pending, setPending] = useState([])
  const [sendLoading, setSendLoading] = useState(false)

  const { activeShop } = useAppSelector(({ store }) => store.seller)

  useEffect(() => {
    $api
      .get('/api/seller/getconversationsshop')
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setDialogs(
            data.map((item: any) => {
              item.counter = item.messages.filter((m: any) => m.user !== activeShop?._id && !m.read).length
              return item
            })
          )
        } else {
          setDialogs([])
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const addMessageToDialog = (dialogId: string, message: IMessage) => {
    setDialogs((prev: any[]) => {
      const dialog = prev.find((d) => d._id === dialogId)

      if (dialog) {
        dialog.messages.push(message)
        if (message.url === 'url') {
          dialog.lastMessage = 'изображение'
        } else {
          dialog.lastMessage = message.message
        }
      }

      return [dialog, ...prev.filter((d) => d._id !== dialogId)]
    })
  }

  const clearDialog = (dialogId: string) => {
    const item: any = dialogs.find(({ _id }) => _id === dialogId)

    if (item) {
      item.messages = []
    }

    setDialogs([...dialogs])
  }

  useEffect(() => {
    let timeout: any = null

    socket.socket.on(SOCKET_EVENTS.TYPING_MESSAGE, (dialog) => {
      setTypingDialogs((prev) => [...prev, dialog])
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setTypingDialogs((prev) => prev.filter((d) => d !== dialog))
      }, 5000)
    })

    return () => {
      clearTimeout(timeout)
      socket.socket.off(SOCKET_EVENTS.TYPING_MESSAGE)
    }
  }, [])

  const memoedValue = useMemo(
    () => ({
      images,
      dialogs,
      loading,
      setLoading,
      setImages,
      setDialogs,
      typingDialogs,
      setTypingDialogs,
      sendLoading,
      clearDialog,
      pending,
      setPending,
      setSendLoading,
      addMessageToDialog,
      socket: socket.socket,
      conversation: id
    }),
    [
      images,
      dialogs,
      loading,
      setLoading,
      setImages,
      setDialogs,
      typingDialogs,
      setTypingDialogs,
      sendLoading,
      clearDialog,
      pending,
      setPending,
      setSendLoading,
      addMessageToDialog,
      socket,
      id
    ]
  )

  return (
    <chatContext.Provider value={memoedValue}>
      <div className={styles.chat_wrapper}>
        <PageHeader title='Сообщения' className={styles['chat__header-wrapper']} />
        <div className={styles.chat}>
          <Conservation setSendLoading={setSendLoading} clearDialog={clearDialog} />
          {id && <MessagesContainer key={id} conversation={id} />}
        </div>
      </div>
    </chatContext.Provider>
  )
}

const useChatContext = () => useContext<any>(chatContext)

export default Chat
export { useChatContext }
