import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import MessagesHeader from './MessagesHeader'
import SendMessage from './SendMessage'
import { SOCKET_EVENTS } from '../constants_chat'
import { useAppSelector } from '../../../redux/hook'
import Messages from './Messages'
import $api from '../../http/axios'
import { useChatContext } from '../../../pages/Chat/Chat'
import styles from './styles/messages_container.module.scss'

interface IMessages {
  conversation: string
}

const MessagesContainer: React.FC<IMessages> = ({ conversation }) => {
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const { socket, addMessageToDialog } = useChatContext()

  useEffect(() => {
    socket.emit(SOCKET_EVENTS.READING_MESSAGES, {
      conversation,
      user: activeShop?._id
    })
    return () => {
      socket.off(SOCKET_EVENTS.READING_MESSAGES)
    }
  }, [])

  const newMessageAdd = (message: string, file: File | null, setFIle: any) => {
    const newMessage = {
      message,
      uid: uuidv4(),
      conversation,
      user: activeShop?._id,
      pending: true
    }

    if (file) {
      // @ts-ignore
      newMessage.url = 'url'

      const formData = new FormData()
      formData.append('file', file)

      $api.post('/api/chat/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          ...newMessage
        }
      })
    } else {
      socket.emit(SOCKET_EVENTS.NEW_MESSAGE, newMessage)
    }

    addMessageToDialog(conversation, {
      ...newMessage,
      self: true
    })
    setFIle(null)
  }

  const handleInput = () => {
    socket.emit(SOCKET_EVENTS.TYPING_MESSAGE, {
      conversation,
      user: activeShop?._id
    })
  }

  return (
    <div className={styles.messages}>
      <div className={styles.messages__header}>
        <MessagesHeader />
      </div>
      <div className={styles.messages__list}>
        <Messages conversation={conversation} />
      </div>
      <div className={styles.messages__send}>
        <SendMessage newMessageAdd={newMessageAdd} onInput={handleInput} />
      </div>
    </div>
  )
}

export default MessagesContainer
