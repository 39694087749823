import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ILegalInformationError } from 'models'
import { Button, ButtonGroup, Input, Select } from 'components/UI'
import { validateInn } from 'utils/validateInn'
import { companyTypes, Fields } from './const'
import { setFormError } from '../../utils'
import { AuthentificationData } from '../..'

export interface IRequisitesStepProps {
  data?: AuthentificationData
  fieldErrors?: Array<ILegalInformationError>
  goToNextStep: (data: AuthentificationData) => void
  onClose: VoidFunction
}

const RequisitesStep = ({ data, fieldErrors, goToNextStep, onClose }: IRequisitesStepProps) => {
  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors }
  } = useForm<AuthentificationData>({
    mode: 'onChange',
    defaultValues: {
      ...data
    }
  })

  const validateInnFunc = (value?: string) => {
    const companyTypeVal = getValues(Fields.companyType)
    return validateInn(value, companyTypeVal)
  }

  useEffect(() => {
    setFormError(fieldErrors, Fields, getValues, setError)
  }, [])

  return (
    <form onSubmit={handleSubmit(goToNextStep)}>
      <Controller
        name={Fields.companyType}
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field }) => (
          <Select
            view='auth'
            label='Тип организации'
            errors={errors}
            arrowIconSize='lg'
            {...field}
            fluid
            required
            arrowIcon
          >
            {companyTypes.map(({ key, value }) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      />
      <Controller
        name={Fields.inn}
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: /^\d*$/, message: 'Некорректный формат' },
          minLength: { value: 10, message: 'Некорректный ИНН' },
          maxLength: { value: 12, message: 'Некорректный ИНН' },
          validate: validateInnFunc
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='ИНН'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            minLength={10}
            maxLength={12}
            {...rest}
            required
            fluid
          />
        )}
      />
      <ButtonGroup gap={8} className='offset-top-24'>
        <Button color='light-blue' size='lg' onClick={onClose}>
          Вернуться в профиль
        </Button>
        <Button type='submit' size='lg'>
          Далее
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default RequisitesStep
