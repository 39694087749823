import React from 'react'
import { Input } from 'components/UI'
import styles from './styles/header_conservation.module.scss'
import { IChat } from '../types'

interface IHeaderProps {
  dialogs: IChat[]
  setSearchDialogsResult: (payload: IChat | null) => void
}

const HeaderConservation: React.FC<IHeaderProps> = ({ dialogs, setSearchDialogsResult }) => {
  const findChat = (e: any) => {
    if (e.target.value.length)
      dialogs.forEach((dialog) => {
        const regex = new RegExp(e?.target?.value?.toUpperCase())
        const found = dialog?.companion?.name?.toUpperCase()?.match(regex)
        if (found && found[0]?.length) setSearchDialogsResult(dialog)
      })
    else setSearchDialogsResult(null)
  }

  return (
    <div className={styles.header}>
      <Input name='search-chat' onChange={findChat} fluid className={styles.header__search_input} placeholder='Поиск' />
    </div>
  )
}

export default HeaderConservation
