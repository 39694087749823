import classNames from 'classnames/bind'
import { Text } from 'components/UI'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useChatContext } from '../../../pages/Chat/Chat'
import { useAppSelector } from '../../../redux/hook'
import { SOCKET_EVENTS } from '../constants_chat'
import TextMessage from './TextMessage'
import styles from './styles/messages.module.scss'

const cn = classNames.bind(styles)

interface IMessages {
  conversation: string
}

interface IMessage {
  conversation: string
  deletedFor?: Array<any>
  message: string
  onModelUser: string
  read: boolean
  thumb: string
  uid: string
  url: string
  user: any
  pending?: boolean
  createdAt: Date
  updatedAt: Date
  _id: string
}

const Messages: React.FC<IMessages> = ({ conversation }) => {
  const scrollRef = useRef<any>()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const { socket, dialogs } = useChatContext()

  const [messages, setMessages] = useState<any[]>([])

  useEffect(() => {
    if (scrollRef.current) {
      // @ts-ignore
      scrollRef.current.scrollToBottom()
      socket.emit(SOCKET_EVENTS.READING_MESSAGES, {
        conversation,
        user: activeShop?._id
      })
    }

    return () => {
      socket.off(SOCKET_EVENTS.READING_MESSAGES)
    }
  }, [messages.length])

  useEffect(() => {
    setMessages(dialogs.find((dialog: { _id: string }) => dialog._id === conversation)?.messages || [])
  }, [dialogs, conversation])

  useEffect(() => {
    socket.emit(SOCKET_EVENTS.READING_MESSAGES, {
      conversation,
      user: activeShop?._id
    })

    return () => {
      socket.off(SOCKET_EVENTS.READING_MESSAGES)
    }
  }, [])

  const groupMessagesByDate = (messagesArr: IMessage[]) => {
    const grouped: Map<string, IMessage[]> = new Map()

    messagesArr.forEach((message: IMessage) => {
      const messageDate = new Date(message.createdAt)
      const today = new Date()
      let displayDate

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
        year: messageDate.getFullYear() === today.getFullYear() ? undefined : 'numeric'
      }

      if (messageDate.toDateString() === today.toDateString()) {
        displayDate = 'Сегодня'
      } else if (messageDate.getDate() === today.getDate() - 1) {
        displayDate = 'Вчера'
      } else {
        displayDate = Intl.DateTimeFormat('ru-RU', options).format(messageDate)
      }

      if (!grouped.has(displayDate)) {
        grouped.set(displayDate, [])
      }
      grouped.get(displayDate)?.push(message)
    })

    return grouped
  }

  const groupedMessages = useMemo(() => groupMessagesByDate(messages), [messages])

  return (
    <Scrollbars autoHide ref={scrollRef} height='100%' className={styles.message_block}>
      {groupedMessages &&
        Array.from(groupedMessages.entries()).map(([date, group]) => (
          <div key={`date-groupp-${date}`}>
            {date && (
              <div className={styles['date-header-wrapper']}>
                <Text color='dark-grey' className={styles['date-header']}>
                  {date}
                </Text>
              </div>
            )}
            {group.map((message: IMessage) => (
              <div
                key={message.uid}
                className={cn({
                  message_image: message.thumb,
                  self_message: message.user === activeShop?._id
                })}
              >
                <div className={styles.message_text}>
                  {message.url ? (
                    <img src={message.thumb} width={150} height={150} alt='' />
                  ) : (
                    <TextMessage message={message} self={message.user === activeShop?._id} />
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
    </Scrollbars>
  )
}

export default Messages
