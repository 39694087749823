import React from 'react'

import { useLocation } from 'react-router-dom'
import { PageHeader, DotsLoader, PageEmpty } from 'components/UI'
import { getProductById } from 'hooks'
import s from './product-layout.module.scss'

interface IProductsLayoutProps {
  children: React.ReactNode
  title?: string
}

const ProductLayout = ({ children, title }: IProductsLayoutProps) => {
  const location = useLocation()
  const locationState = location.state

  const { mainInfo, characteristics, description, images, categoryId, requestError, loading } = getProductById(
    locationState?.productId
  )

  const ProductLayoutContent = () => {
    if (loading) return <DotsLoader center />
    if (requestError) return <PageEmpty title='Произошла ошибка при загрузке данных! Попробуйте обновить страницу!' />

    return React.cloneElement(children as React.ReactNode | any, {
      characteristicsInit: characteristics,
      mainInfoInit: mainInfo,
      categoryIdInit: categoryId,
      descriptionInit: description,
      imagesInit: images
    })
  }

  return (
    <div className={s['product-layout']}>
      <PageHeader title={title} withBorder={false} />
      <main className={s.main}>
        <ProductLayoutContent />
      </main>
    </div>
  )
}

export default ProductLayout
