import React from 'react'
import cx from 'classnames'
import { Icon, Text } from 'components/UI'
import s from './product-navigation-menu-item.module.scss'

interface IProductNavigationProps {
  index: number
  name: string
  completed: boolean
  activeIndex: number
  onClick: (stepTo: number) => void
}

const ProductNavigationMenuItem = ({ index, name, completed, activeIndex, onClick }: IProductNavigationProps) => (
  <div
    className={cx(s['product-navigation-menu-item'], {
      [s.active]: activeIndex === index,
      [s.completed]: completed
    })}
    onClick={() => onClick(index)}
  >
    <div className={s['product-navigation-menu-item__circle']}>
      {completed && <Icon name='check' size='xs' color='white' />}
    </div>
    <Text className={s['product-navigation-menu-item__text']} size='md' color='gray'>
      {name}
    </Text>
  </div>
)

export default ProductNavigationMenuItem
