import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { Icon, Text } from 'components/UI'
import s from './navigation.module.scss'

interface IMenuItem {
  title: string
  href: string
  icon: string
}

interface IMenuNavLinkProps {
  menuLink: IMenuItem
}

const MenuNavLink: React.FC<IMenuNavLinkProps> = ({ menuLink }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isActive = location.pathname === menuLink.href

  return (
    <li className={cn(s['menu-nav-link'], { [s.active]: isActive })} onClick={() => navigate(menuLink.href)}>
      <Icon name={menuLink.icon} color='dark-grey' />
      <Link to={menuLink.href}>
        <Text size='md' color='dark-grey'>
          {menuLink.title}
        </Text>
      </Link>
    </li>
  )
}

export default MenuNavLink
