import { useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { useAppSelector } from 'redux/hook'
import { API_URL } from '../http/axios'

export default function useSocket() {
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  // const [play] = useSound();

  const audio = new Audio(require('../../public/sounds/Boop-sound-effect.mp3'))

  const [registrationInfo, setRegistrationInfo] = useState<any>()
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false)

  const [notification, setNotification] = useState<any[]>([])

  const { current: socket } = useRef(
    io(API_URL, {
      query: {
        id: activeShop?._id
      },
      withCredentials: true
    })
  )

  // регистрируем обработчики
  useEffect(() => {
    // обрабатываем получение обновленного списка сообщений
    socket.on('new_order:read', (messages) => {
      audio.play()
      setNotification((prevState) => [...prevState, ...messages])
    })

    socket.on('registration_step:read', (messages) => {
      setRegistrationInfo(messages)
    })

    socket.on('registration_success', () => {
      setRegistrationSuccess(true)
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('custom_error')
      socket.off('new_order:read')
      socket.off('registration_step:read')
      socket.off('seller_registration:create')
      socket.off('registration_success')
    }
  }, [])

  const removeNotification = (index: number) => {
    setNotification((prevState) => prevState.filter((_, i) => i !== index))
  }

  const sendRegistration = (data: any) => {
    socket.emit('seller_registration:create', data)
  }

  const verifyOtp = (data: any) => {
    socket.emit('verify_otp_registration', data)
  }

  return {
    notification,
    removeNotification,
    registrationInfo,
    registrationSuccess,
    sendRegistration,
    verifyOtp,
    socket
  }
}
