import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IAuthState {
  token: string | null
  isAuthCompleted: boolean
}

const initialState: IAuthState = {
  token: null,
  isAuthCompleted: false
}

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logout: () => {
      localStorage.setItem('accessToken', 'undefined')
      localStorage.setItem('refreshToken', 'undefined')

      return initialState
    },
    setToken: (state: IAuthState, action: PayloadAction<string>) => {
      localStorage.setItem('accessToken', action.payload)

      return {
        ...state,
        token: action.payload
      }
    },
    setIsAuthCompleted: (state: IAuthState, action: PayloadAction<boolean>) => ({
      ...state,
      isAuthCompleted: action.payload
    })
  }
})

export default authSlice.reducer

export const { logout, setToken, setIsAuthCompleted } = authSlice.actions
