import React, { useEffect, useState } from 'react'
import { useQueryParams } from 'hooks'
import { PageHeader, PageTabs } from 'components/UI'
import { MainInfoSettings, TelegramSettings } from 'components/settings'
import s from './settings.module.scss'

interface ITab {
  name: string
  key: string
}

const tabs = [
  { name: 'Основная информация', key: 'main-info' },
  { name: 'Уведомления в Telegram', key: 'notifications-telegram' }
] as ITab[]

const SettingsPage = () => {
  const { getQueryParams, setQueryParams } = useQueryParams()
  const activeTab = tabs.findIndex((v: ITab) => v.key === getQueryParams('tag')) ?? 0
  const activeTabFilter = getQueryParams('tag')

  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab)

  const onTabChange = (index: number) => {
    setQueryParams('tag', tabs[index].key)
    setActiveTabIndex(index)
  }

  const renderTagContent = () => {
    switch (activeTabIndex) {
      case 0:
        return <MainInfoSettings />
      case 1:
        return <TelegramSettings />
      default:
        return <MainInfoSettings />
    }
  }

  useEffect(() => {
    if (!activeTabFilter) {
      setQueryParams('tag', tabs[0].key)
      setActiveTabIndex(0)
    }
  }, [])

  return (
    <div className={s.settings}>
      <PageHeader title='Настройки' className={s['settings__header-wrapper']}>
        <PageTabs>
          {tabs.map((v: ITab, i: number) => (
            <PageTabs.Tab<number>
              key={v.name}
              tabKey={i}
              title={v.name}
              active={activeTabIndex}
              onChange={onTabChange}
            />
          ))}
        </PageTabs>
      </PageHeader>
      <div className={s['settings__content-wrapper']}>
        <div className={s.settings__content}>{renderTagContent()}</div>
      </div>
    </div>
  )
}

export default SettingsPage
