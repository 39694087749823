import React from 'react'
import cx from 'classnames'
import s from './style.module.scss'

interface ITextProps {
  children: React.ReactNode
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div' | 'li' | 'b' | 'i' | 'small' | 'strong'
  className?: string
  style?: React.CSSProperties
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'
  color?: string | 'dark-grey' | 'gray' | 'gray-light' | 'middle-grey' | 'extra-grey' | 'white' | 'red' | 'blue'
  requiredColor?: string
  align?: 'start' | 'end' | 'center' | 'justify'
  weight?: 'bold' | 'semibold' | 'medium' | 'regular' | 'light'
  overflow?: 'fade' | 'ellipsis'
  transform?: 'lowercase' | 'uppercase' | 'capitalize'
  decoration?: 'underline' | 'line-through'
  whiteSpace?: 'pre-line' | 'nowrap'
  family?: 'primary' | 'secondary' | 'object-sans'
  cursor?: 'pointer' | 'auto'
  required?: boolean
  disable?: boolean
  onClick?: VoidFunction
}

function Text({
  children,
  as: ElementType = 'span',
  className,
  style,
  size,
  color = 'primary',
  requiredColor,
  align,
  weight = 'medium',
  overflow,
  transform,
  decoration,
  whiteSpace,
  family,
  cursor,
  required,
  disable,
  onClick,
  ...props
}: ITextProps) {
  const classList = cx(
    s.text,
    {
      [s[`text-${size}`]]: size,
      [s[`color-${color}`]]: color,
      [s[`required-color-${requiredColor}`]]: requiredColor,
      [s[`text-${align}`]]: align,
      [s[`text-${weight}`]]: weight,
      [s[`text-overflow-${overflow}`]]: overflow,
      [s[`text-transform-${transform}`]]: transform,
      [s[`text-decoration-${decoration}`]]: decoration,
      [s[`white-space-${whiteSpace}`]]: whiteSpace,
      [s[`font-family-${family}`]]: family,
      [s[`cursor-${cursor}`]]: cursor,
      [s.required]: required,
      [s.disable]: disable
    },
    className
  )

  return (
    <ElementType className={classList} style={style} onClick={onClick} {...props}>
      {children}
    </ElementType>
  )
}

export default Text
