import { Fields as checkDataStepFields } from 'components/registration/steps/checkDataStep/const'
import { Fields as companyAddressStepFields } from 'components/registration/steps/companyAddressStep/const'

import { IInitRegistrationFormAddress } from 'models'

export interface Suggestion {
  unrestricted_value: string
  value: string
}

type TSuggestionsType = {
  value?: string
  unrestricted_value?: string
  data?: {
    kpp?: string
    capital?: any
    invalid?: any
    management?: {
      name?: string
      post?: string
      disqualified?: any
    }
    founders?: any
    managers?: any
    predecessors?: any
    successors?: any
    branch_type?: string
    branch_count?: number
    source?: any
    qc?: any
    hid?: string
    type?: string
    state?: {
      status?: string
      code?: any
      actuality_date?: number
      registration_date?: number
      liquidation_date?: any
    }
    opf?: {
      type?: string
      code?: string
      full?: string
      short?: string
    }
    name?: {
      full_with_opf?: string
      short_with_opf?: string
      latin?: any
      full?: string
      short?: string
    }
    inn?: string
    ogrn?: string
    okpo?: string
    okato?: string
    oktmo?: string
    okogu?: string
    okfs?: string
    okved?: string
    okveds?: any
    authorities?: any
    documents?: any
    licenses?: any
    finance?: {
      tax_system?: any
      income?: any
      expense?: any
      revenue?: any
      debt?: any
      penalty?: any
      year?: any
    }
    address?: {
      value?: string
      unrestricted_value?: string
      invalidity?: any
      data?: {
        postal_code?: string
        country?: string
        country_iso_code?: string
        federal_district?: string
        region_fias_id?: string
        region_kladr_id?: string
        region_iso_code?: string
        region_with_type?: string
        region_type?: string
        region_type_full?: string
        region?: string
        area_fias_id?: any
        area_kladr_id?: any
        area_with_type?: any
        area_type?: any
        area_type_full?: any
        area?: any
        city_fias_id?: string
        city_kladr_id?: string
        city_with_type?: string
        city_type?: string
        city_type_full?: string
        city?: string
        city_area?: any
        city_district_fias_id?: any
        city_district_kladr_id?: any
        city_district_with_type?: any
        city_district_type?: any
        city_district_type_full?: any
        city_district?: any
        settlement_fias_id?: any
        settlement_kladr_id?: any
        settlement_with_type?: any
        settlement_type?: any
        settlement_type_full?: any
        settlement?: any
        street_fias_id?: string
        street_kladr_id?: string
        street_with_type?: string
        street_type?: string
        street_type_full?: string
        street?: string
        stead_fias_id?: any
        stead_cadnum?: any
        stead_type?: any
        stead_type_full?: any
        stead?: any
        house_fias_id?: string
        house_kladr_id?: string
        house_cadnum?: string
        house_flat_count?: any
        house_type?: string
        house_type_full?: string
        house?: string
        block_type?: any
        block_type_full?: any
        block?: any
        entrance?: any
        floor?: any
        flat_fias_id?: any
        flat_cadnum?: any
        flat_type?: string
        flat_type_full?: string
        flat?: string
        flat_area?: any
        square_meter_price?: any
        flat_price?: any
        room_fias_id?: any
        room_cadnum?: any
        room_type?: any
        room_type_full?: any
        room?: any
        postal_box?: any
        fias_id?: string
        fias_code?: string
        fias_level?: string
        fias_actuality_state?: string
        kladr_id?: string
        geoname_id?: string
        capital_marker?: string
        okato?: string
        oktmo?: string
        tax_office?: string
        tax_office_legal?: string
        timezone?: string
        geo_lat?: string
        geo_lon?: string
        beltway_hit?: any
        beltway_distance?: any
        metro?: any
        divisions?: any
        qc_geo?: string
        qc_complete?: any
        qc_house?: any
        history_values?: any
        unparsed_parts?: any
        source?: string
        qc?: string
      }
    }
    phones?: any
    emails?: any
    ogrn_date?: number
    okved_type?: string
    employee_count?: any
  }
}

type TRootSuggestionsType = {
  suggestions?: TSuggestionsType[]
}

type TResponseSuggestions = {
  address: IInitRegistrationFormAddress

  [checkDataStepFields.kpp]: string
  [checkDataStepFields.name]: string
  [checkDataStepFields.firstName]: string
  [checkDataStepFields.lastName]: string
  [checkDataStepFields.middleName]: string
  [checkDataStepFields.ogrn]: string
  [companyAddressStepFields.city]: string
  [companyAddressStepFields.country]: string
  [companyAddressStepFields.street]: string
  [companyAddressStepFields.zip]: string
}

const PROTOCOL = process.env.NODE_ENV === 'development' ? 'http' : 'https'
const HOST = `${PROTOCOL}://suggestions.dadata.ru/suggestions/api/4_1/rs`

const options: RequestInit = {
  method: 'POST',
  mode: 'cors',
  headers: {
    Authorization: 'Token ea3c378800cc19b8c9fd7181aca030dab9a838da',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

const parseData = (data?: TRootSuggestionsType): TResponseSuggestions => {
  const fullname = data?.suggestions?.[0]?.data?.address?.value || ''
  const city = data?.suggestions?.[0]?.data?.address?.data?.city || ''
  const street = `${data?.suggestions?.[0]?.data?.address?.data?.street || ''} ${data?.suggestions?.[0]?.data?.address?.data?.house || ''} ${data?.suggestions?.[0]?.data?.address?.data?.room || ''}`
  const geoLat = data?.suggestions?.[0]?.data?.address?.data?.geo_lat || ''
  const geoLon = data?.suggestions?.[0]?.data?.address?.data?.geo_lon || ''
  const region = data?.suggestions?.[0]?.data?.address?.data?.country || ''
  const zip = data?.suggestions?.[0]?.data?.address?.data?.postal_code || ''

  const [lastName, firstName, middleName] = data?.suggestions?.[0]?.data?.management?.name?.split(' ') || ['', '', '']

  const kpp = data?.suggestions?.[0]?.data?.kpp || ''
  const name = data?.suggestions?.[0]?.value || ''
  const ogrn = data?.suggestions?.[0]?.data?.ogrn || ''

  return {
    address: {
      fullname,
      city,
      street,
      coordinates: [Number(geoLat), Number(geoLon)]
    },
    kpp,
    firstName,
    lastName,
    middleName,
    name,
    ogrn,
    city,
    country: region,
    street,
    zip
  }
}
export class DaData {
  static async findByAddress(query: string): Promise<Suggestion[]> {
    const response = await fetch(`${HOST}/suggest/address`, {
      ...options,
      body: JSON.stringify({ query })
    })

    const json = await response.json()

    return json.suggestions
  }

  static async findById(query: string): Promise<TResponseSuggestions> {
    const response = await fetch(`${HOST}/findById/party`, {
      ...options,
      body: JSON.stringify({ query })
    })

    const json = await response.json()

    return parseData(json)
  }
}
