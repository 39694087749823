import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import cx from 'classnames'
import logoImg from 'public/Logo.svg'
import { ILegalInformationError, TSellerShop } from 'models'
import { useAppSelector } from 'redux/hook'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { useMessage, useWindowSize } from 'hooks'
import { Modal, Text } from 'components/UI'
import { AuthentificationData, Steps } from './types'
import { formTitles, initialData } from './constants'
import { Fields as requisitesStepFields } from './steps/requisitesStep/const'
import { Fields as checkDataStepFields } from './steps/checkDataStep/const'
import { prepareLegalInformationToForm, prepareSellerRegisterData } from './utils'
import { BankRequisitesStep, CheckDataStep, CompanyAddressStep, ModerateMessageStep, RegSteps, RequisitesStep } from '.'
import $api from '../http/axios'
import s from './styles.module.scss'

export interface RegistrationProps {
  shop: TSellerShop | undefined
  showModal: boolean
  onClose: VoidFunction
  fieldErrors?: Array<ILegalInformationError>
  globalErrors?: string
  mode?: 'create' | 'edit'
}

const Registration = ({ shop, showModal, onClose, fieldErrors, globalErrors, mode = 'create' }: RegistrationProps) => {
  const [data, setData] = useState<AuthentificationData>(initialData)
  const [activeStep, setActiveStep] = useState(Steps.REQUISITES)
  const [isFetching, setIsFetching] = useState(false)
  const [getSellerInfo] = useGetSellerInfoMutation()
  const { deviceWidth } = useWindowSize()
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const { mutate: getUserLegalInformation } = useMutation({
    mutationFn: async () => $api.get(`/api/seller/${activeShop?._id}/legal-information`),
    onSuccess: (requestData) =>
      setData({ ...prepareLegalInformationToForm(requestData.data.data), shopId: activeShop?._id })
  })

  const getInitialFormData = () => ({
    ...initialData,
    [requisitesStepFields.companyType]: activeShop?.[requisitesStepFields.companyType] ?? undefined,
    [requisitesStepFields.inn]: activeShop?.[requisitesStepFields.inn] ?? undefined
  })

  const goToNextStep = (values?: AuthentificationData) => {
    if (values) setData(values)
    setActiveStep((prev) => prev + 1)
  }

  const goToPrevStep = (values?: AuthentificationData) => {
    if (values) setData(values)
    setActiveStep((prev) => prev - 1)
  }

  const handleRegister = async (values: AuthentificationData) => {
    setData(values)
    setIsFetching(true)

    try {
      await $api({
        method: mode === 'create' ? 'POST' : 'PATCH',
        url: `/api/seller/${activeShop?._id}/legal-information`,
        data: prepareSellerRegisterData({
          ...values,
          email: shop?.email,
          [checkDataStepFields.kpp]: values.companyType === 'IP' ? '000000000' : values.kpp
        })
      })

      goToNextStep()
      await getSellerInfo()
    } catch (err: any) {
      useMessage(err?.response?.data?.error, 'error')
      console.error(err?.response?.data)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    setActiveStep(Steps.REQUISITES)
  }, [activeShop?._id])

  useEffect(() => {
    if (mode === 'create') setData(getInitialFormData())
    else getUserLegalInformation()
  }, [mode, activeShop?._id])

  return (
    <Modal isOpen={showModal} onRequestClose={onClose} shouldCloseOnOverlayClick={false}>
      <div className={s['registration-wrapper']}>
        {deviceWidth === 'small' && (
          <div className={s['registration-wrapper__logo']}>
            <img src={logoImg} alt='' />
          </div>
        )}
        {activeStep !== Steps.MODERATE && (
          <>
            <RegSteps activeStep={activeStep} />
            <Text as='p' align='center' className={cx(s['registration-wrapper__title'], 'offset-top-24')}>
              {formTitles[activeStep]}
            </Text>
          </>
        )}
        <div className={cx({ 'offset-top-48': activeStep !== Steps.MODERATE })}>
          {activeStep === Steps.REQUISITES && (
            <RequisitesStep data={data} fieldErrors={fieldErrors} goToNextStep={goToNextStep} onClose={onClose} />
          )}
          {activeStep === Steps.CHECK_DATA && (
            <CheckDataStep
              data={data}
              fieldErrors={fieldErrors}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
              mode={mode}
            />
          )}
          {activeStep === Steps.COMPANY_ADDRESS && (
            <CompanyAddressStep
              data={data}
              fieldErrors={fieldErrors}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
              mode={mode}
            />
          )}
          {activeStep === Steps.BANK_REQUISITES && (
            <BankRequisitesStep
              data={data}
              fieldErrors={fieldErrors}
              globalErrors={globalErrors}
              goToNextStep={handleRegister}
              goToPrevStep={goToPrevStep}
              isFetching={isFetching}
              mode={mode}
            />
          )}
          {activeStep === Steps.MODERATE && <ModerateMessageStep onClose={onClose} />}
        </div>
      </div>
    </Modal>
  )
}

export default Registration
