import React from 'react'
import { IProductImage } from 'models'
import { ProductImage } from 'components/product'
import { Icon } from 'components/UI'
import s from './product-media-files.module.scss'

interface IImageItemProps {
  image: IProductImage
  onRemove: (index: string) => void
}

const ImageItem = ({ image, onRemove }: IImageItemProps) => (
  <div className={s['image-item']}>
    <ProductImage src={image.link} width={105} height={105} border={false} fit='cover' />
    <div className={s['image-item__close']} onClick={() => onRemove(image.filename)}>
      <Icon name='close' color='white' size='xs' />
    </div>
  </div>
)

export default React.memo(ImageItem)
