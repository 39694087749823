const required = { value: true, message: 'Обязательное поле' }
const maxLength = (number: number) => ({ value: number, message: `Максимальная длина ${number} символов.` })

const fieldRules: Record<string, Record<string, any>> = {
  product: {
    productName: { required, maxLength: maxLength(200) },
    categories: { required },
    barcode: { maxLength: maxLength(13) },
    SKU: { required, maxLength: maxLength(20) },
    manufacturerSKU: { maxLength: maxLength(20) },
    vat: { required },
    discountPrice: { required, maxLength: maxLength(10) },
    regularPrice: { maxLength: maxLength(10) },
    'package.weight': { required, maxLength: maxLength(10) },
    'package.width': { required },
    'package.length': { required, maxLength: maxLength(10) },
    'package.height': { required, maxLength: maxLength(10) },
    Бренд: { required, maxLength: maxLength(5) },
    'Страна бренда': { required, maxLength: maxLength(5) },
    'Страна-производитель': { required, maxLength: maxLength(5) },
    'Тип товара': { required, maxLength: maxLength(10) },
    description: { maxLength: maxLength(4000) }
  }
}

export const getValidation = (name: string) => fieldRules.product?.[name] ?? {}
