import React from 'react'
import checkSuccessImg from 'public/checkout-success.png'
import { useAppSelector } from 'redux/hook'
import { Button, Text } from 'components/UI'
import s from './moderate-message-step.module.scss'

interface IModerateMessageStepProps {
  onClose: VoidFunction
}

const ModerateMessageStep = ({ onClose }: IModerateMessageStepProps) => {
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  return (
    <div className={s['moderate-message']}>
      <div className={s['moderate-message__img']}>
        <img src={checkSuccessImg} alt='' />
      </div>
      <Text as='p' className={s['moderate-message__title']} align='center'>
        Готово!
      </Text>
      <div className={s['moderate-message__text']}>
        <Text as='p' size='sm' align='center' family='object-sans' weight='regular'>
          Ваши данные отправлены на модерацию.
        </Text>
        <Text as='p' size='sm' align='center' family='object-sans' weight='regular'>
          О решении мы сообщим по электронной почте на адрес
          <Text size='sm' family='object-sans' weight='regular' color='blue'>
            {activeShop?.email ?? ''}
          </Text>
        </Text>
      </div>
      <Button size='lg' onClick={onClose} className='offset-top-32'>
        Вернуться в профиль
      </Button>
    </div>
  )
}

export default ModerateMessageStep
