import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { IInitRegistrationFormAddress, TSellerInfoAddress } from 'models'
import { useForm } from 'react-hook-form'
import { useAppSelector } from 'redux/hook'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { useMessage } from 'hooks'
import { Input, InputGroup, Modal, PageEmpty, Text } from 'components/UI'
import { SelectAddressModal } from 'components/registration'
import $api from 'components/http/axios'
import { UploadShopIcon } from './uploadShopIcon'
import s from './main-info-settings.module.scss'

interface IFormData {
  name: string
  shopAddress: string
  phone: string
  email: string
}

const MainInfoSettings = () => {
  const [isOpenAddressModal, setIsOpenAddressModal] = useState<boolean>(false)
  const [getSellerInfo] = useGetSellerInfoMutation()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const [image, setImage] = useState<string>(activeShop?.image || '')

  const { register, setValue } = useForm<IFormData>({
    defaultValues: {
      name: activeShop?.name ?? '',
      shopAddress: (activeShop?.addresses?.[0] as TSellerInfoAddress | undefined)?.fullname,
      phone: activeShop?.phoneNumber ?? '',
      email: activeShop?.email ?? ''
    }
  })

  const { mutate: changeAddress, isLoading } = useMutation({
    mutationFn: async (data: IInitRegistrationFormAddress) => {
      setIsOpenAddressModal(false)
      const response = await $api.patch(`/api/seller/${activeShop?._id}/address`, { address: data })

      if (response.status === 200) {
        await getSellerInfo()
        useMessage(response.data.message)
      }
    }
  })

  const closeAddressModal = () => setIsOpenAddressModal(false)

  useEffect(() => {
    setValue('shopAddress', (activeShop?.addresses?.[0] as TSellerInfoAddress | undefined)?.fullname ?? '')
  }, [activeShop?.addresses])

  if (!activeShop) {
    return <PageEmpty title='Данных нет' fullHeight={false} />
  }

  return (
    <div className={s['main-info-settings']}>
      <Text size='lg'>Магазин</Text>
      <UploadShopIcon
        sellerId={activeShop?._id as string}
        image={image}
        setImage={setImage}
        onSuccess={getSellerInfo}
      />
      <Input
        name='name'
        classNameInputWrapper='offset-top-16'
        label='Название магазина'
        register={register}
        required
        fluid
        disabled
      />
      <Text as='p' size='xs' className='offset-top-6' color='middle-grey' family='secondary'>
        Это название будет отображаться у вас в профиле на сервисе Kovkert
      </Text>
      <Input
        name='shopAddress'
        classNameInputWrapper='offset-top-12'
        className={s['shop-address-input']}
        label='Адрес магазина'
        suffix='location'
        onClick={() => setIsOpenAddressModal(true)}
        style={{ cursor: 'pointer' }}
        disabled={isLoading}
        register={register}
        required
        fluid
        readOnly
      />
      <Text as='p' size='lg' className='offset-top-32'>
        Данные продавца
      </Text>
      <InputGroup className='offset-top-12' gap={6}>
        <Input name='phone' type='tel' label='Телефон' register={register} required fluid disabled />
        <Input name='email' label='Почта' register={register} required fluid disabled />
      </InputGroup>

      <Modal isOpen={isOpenAddressModal} onRequestClose={closeAddressModal} size='full' closePlace={null}>
        <SelectAddressModal
          initSelectedAddress={activeShop?.addresses?.[0] as TSellerInfoAddress | undefined}
          onSuccess={changeAddress}
          onClose={closeAddressModal}
          mapZoom={15}
        />
      </Modal>
    </div>
  )
}

export default MainInfoSettings
