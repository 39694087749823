import React, { useEffect } from 'react'
import { IProductCharacteristic, IProductMainInfo } from 'models'
import { setSpaceBetweenCharacters } from 'helpers'
import { Button, ButtonGroup, Text } from 'components/UI'
import s from './preview-preview.module.scss'

interface IProductPreviewProps {
  mainInfo: IProductMainInfo | object | any
  characteristics: IProductCharacteristic[]
  description: string
  possiblyNextStep: number
  onSuccessTriggerForm: (step: number) => void
  onSubmit: VoidFunction
}

const ProductPreviewSection = ({
  mainInfo,
  characteristics,
  description,
  possiblyNextStep,
  onSuccessTriggerForm,
  onSubmit
}: IProductPreviewProps) => {
  useEffect(() => onSuccessTriggerForm(possiblyNextStep), [possiblyNextStep])

  const ProductInfoRow = ({ title, value }: { title: string; value: string }) => (
    <div className={s['product-info-row']}>
      <div className={s['product-info-row__title']}>
        <Text color='middle-grey' size='md' family='secondary' weight='regular'>
          {title}
        </Text>
      </div>
      <Text className={s['product-info-row__content']} size='md' family='secondary' weight='regular' align='end'>
        {value}
      </Text>
    </div>
  )

  return (
    <div>
      <Text as='p' size='lg'>
        Основная информация
      </Text>
      <div className='offset-top-12'>
        <ProductInfoRow title='Название' value={mainInfo?.productName} />
        <ProductInfoRow title='Штрихкод' value={mainInfo?.barcode} />
        <ProductInfoRow title='Артикул' value={mainInfo?.SKU} />
        <ProductInfoRow title='Цена' value={`${setSpaceBetweenCharacters(mainInfo?.discountPrice) as string} ₽`} />
        <ProductInfoRow
          title='Категория'
          value={mainInfo?.categories?.map((item: { name: string; _id: string }) => <p key={item._id}>{item.name}</p>)}
        />
      </div>
      <Text as='p' size='lg' className='offset-top-24'>
        Дополнительная информация
      </Text>
      <Text as='p' color='middle-grey' size='md' family='secondary' weight='regular' className='offset-top-12'>
        Описание
      </Text>
      <Text as='p' size='md' family='secondary' weight='regular' className='offset-top-8'>
        {description || '-'}
      </Text>
      <Text as='p' size='lg' className='offset-top-24'>
        Габариты
      </Text>
      <div className='offset-top-12'>
        <ProductInfoRow title='Вес' value={`${mainInfo?.package?.weight || '-'} гр.`} />
        <ProductInfoRow title='Длина' value={`${mainInfo?.package?.length || '-'} мм.`} />
        <ProductInfoRow title='Высота' value={`${mainInfo?.package?.height || '-'} мм.`} />
        <ProductInfoRow title='Ширина' value={`${mainInfo?.package?.width || '-'} мм.`} />
      </div>
      <Text as='p' size='lg' className='offset-top-24'>
        Характеристики
      </Text>
      <div className='offset-top-12'>
        {characteristics?.map((characteristic: IProductCharacteristic) => {
          if (characteristic?.value?.length) {
            return (
              <ProductInfoRow
                key={characteristic._id}
                title={characteristic.title}
                value={characteristic?.value?.join(', ')}
              />
            )
          }
        })}
      </div>
      <ButtonGroup justifyContent='space-between' className='offset-top-32'>
        <Button onClick={() => onSuccessTriggerForm(2)} size='sm' color='light-blue'>
          Назад
        </Button>
        <Button onClick={onSubmit} size='sm'>
          Отправить на модерацию
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default ProductPreviewSection
