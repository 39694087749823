import { AuthentificationData, AuthentificationFormTitles, Steps } from './types'

export const initialData: AuthentificationData = {
  account: '',
  bankName: '',
  bik: '',
  companyType: undefined,
  inn: undefined,
  shopId: '',
  birthDate: '',
  kpp: '',
  name: '',
  ogrn: '',
  phone: '',
  firstName: '',
  lastName: '',
  middleName: '',
  country: undefined,
  city: undefined,
  street: '',
  zip: '',
  middleNameRequired: true,
  type: true
}

export const formTitles: AuthentificationFormTitles = {
  [Steps.REQUISITES]: 'Добавьте реквизиты',
  [Steps.CHECK_DATA]: 'Проверьте данные',
  [Steps.COMPANY_ADDRESS]: 'Добавьте адрес компании',
  [Steps.BANK_REQUISITES]: 'Добавьте банковские реквизиты'
}
