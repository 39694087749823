import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import { IProduct } from 'models'
import { useMessage, useDebounce } from 'hooks'
import { Button, CloseIcon, PageHeader, Select, Text } from 'components/UI'
import { ProductAddSearchCard } from 'components/product'
import $api from 'components/http/axios'
import s from './product-add-page.module.scss'

const ProductAddPage = () => {
  const [products, setProducts] = useState<IProduct[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [focus, setFocus] = useState(false)
  const debouncedValue = useDebounce<string>(searchValue as string, 300)
  const navigate = useNavigate()

  const fetchProducts = async () => {
    if ((searchValue as string).trim().length) {
      await $api
        .post(`/api/card/searchsite/${debouncedValue}`)
        .then((response) => setProducts(response.data.message))
        .catch(() => useMessage('Ошибка поиска', 'error'))
    } else {
      setProducts([])
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [debouncedValue])

  return (
    <div className={s['product-add']}>
      <PageHeader
        title='Добавление товара в каталог'
        button={<CloseIcon onClick={() => navigate(routes.PRODUCTS)} color='white-grey' />}
        withBorder={false}
      />
      <div className={s['product-add__content']}>
        <Text as='p' size='lg'>
          Поиск товара на Kovkert
        </Text>
        <Text as='p' size='sm' family='secondary' className={s.description}>
          Если у нас уже продаётся такой же товар, вам останется заполнить всего несколько полей
        </Text>
        <Select
          name='search'
          mode='search'
          searchValue={searchValue}
          classNameInputWrapper='offset-top-16'
          placeholder='Введите ссылку, штрихкод или артикул'
          onSearch={setSearchValue}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          view='disabled-search-select'
          open={focus && Boolean(products?.length)}
          fluid
          showSearch
          allowClear
        >
          {products.map((v: IProduct) => (
            <Select.Option key={v._id} value={v._id}>
              <ProductAddSearchCard product={v} />
            </Select.Option>
          ))}
        </Select>
        <Text as='p' size='lg' className='offset-top-32'>
          Новый товар
        </Text>
        <Text as='p' size='sm' family='secondary' className='offset-top-8'>
          Если товара нет в магазине, то добавьте его сами
        </Text>
        <Button className='offset-top-16' onClick={() => navigate(routes.PRODUCTS_ADD_NEW)}>
          Добавить новый товар
        </Button>
      </div>
    </div>
  )
}

export default ProductAddPage
