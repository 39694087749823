import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TSellerInfo, TSellerShop } from 'models'

interface ISellerState {
  data: TSellerInfo | null
  activeShop: TSellerShop | null
}

const initialState: ISellerState = {
  data: null,
  activeShop: null
}

export const sellerSlice = createSlice({
  initialState,
  name: 'seller',
  reducers: {
    setSellerData: (state, action: PayloadAction<TSellerInfo>) => ({
      ...state,
      data: action.payload
    }),
    setActiveSellerShop: (state, action: PayloadAction<TSellerShop>) => ({
      ...state,
      activeShop: action.payload
    }),
    logoutSeller: () => initialState
  }
})

export default sellerSlice.reducer

export const { setSellerData, setActiveSellerShop, logoutSeller } = sellerSlice.actions
