export const getTitleWithCopy = (title: string) => {
  if (!title) return null

  const regExp = /\(КОПИЯ*.\d*\)/gi

  if (title.match(regExp)) {
    return title.replace(regExp, (copy: string) => {
      const isStringHasNumber: boolean = !!copy.match(/\d+/g)

      if (isStringHasNumber) {
        return copy.replace(/\d+/gi, (n: string): number | any => parseInt(n, 10) + 1)
      }

      return '(КОПИЯ 2)'
    })
  }

  return `${title} (КОПИЯ)`
}
