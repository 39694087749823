import React from 'react'
import { ProductLayout } from 'components/product'
import Product from 'components/product/Product'

const ProductEditPage = () => (
  <ProductLayout title='Редактирование товара'>
    <Product mode='edit' />
  </ProductLayout>
)

export default ProductEditPage
