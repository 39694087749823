import React from 'react'
import cx from 'classnames'
import { Text } from 'components/UI'
import s from './styles.module.scss'

interface IRegStepsProps {
  activeStep: number
}

const RegSteps = ({ activeStep }: IRegStepsProps) => {
  const steps = [1, 2, 3, 4]

  return (
    <div className={s['reg-steps']}>
      <Text color='gray' family='secondary'>
        Шаг {activeStep} из 4
      </Text>
      <ul className={s['reg-steps__list']}>
        {steps.map((step: number) => (
          <li
            key={step}
            className={cx(s.step, {
              [s['step-active']]: step === activeStep,
              [s['step-done']]: step < activeStep
            })}
          />
        ))}
      </ul>
    </div>
  )
}

export default RegSteps
