import React from 'react'
import { Icon, Text } from 'components/UI'
import s from './selected-menu.module.scss'

interface IButtonProps {
  icon: string
  text: string
  onClick: VoidFunction
}

const Button = ({ icon, text, onClick }: IButtonProps) => (
  <div className={s.button} onClick={onClick}>
    <Icon name={icon} size='md' color='middle-grey' />
    <Text className={s.button__text} size='sm' color='white'>
      {text}
    </Text>
  </div>
)

export default Button
