import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IInitRegistrationForm, IInitRegistrationFormAddress, IResponseError } from 'models'
import { useRegistrationSellerMutation } from 'redux/auth/authApi'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { getMyLocation } from 'helpers'
import { useMessage } from 'hooks'
import { Button, ButtonGroup, Input, Modal, Select, Textarea } from 'components/UI'
import { validateInn } from 'utils/validateInn'
import { getPasteData } from 'utils/getPasteData'
import { companyTypes, Fields } from '../steps/requisitesStep/const'
import { SelectAddressModal } from '../modals'
import s from './init-registration.module.scss'

interface IInitRegistrationProps {
  onButtonBackClick: VoidFunction
  onSuccess?: VoidFunction
}

const InitRegistration = ({ onButtonBackClick, onSuccess }: IInitRegistrationProps) => {
  const [isOpenAddressModal, setIsOpenAddressModal] = useState<boolean>(false)
  const [registrationSeller, { isLoading }] = useRegistrationSellerMutation()
  const [getSellerInfo] = useGetSellerInfoMutation()

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm<IInitRegistrationForm>({
    mode: 'onChange'
  })

  const closeAddressModal = () => setIsOpenAddressModal(false)

  const onSuccessSelectAddress = (data: IInitRegistrationFormAddress) => {
    if (data) {
      closeAddressModal()
      clearErrors('address')
      setValue('address', data)
    }
  }

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = getPasteData(e)
    const regExp = /["'«»]/gi

    if (pastedData) {
      setTimeout(() => setValue('shopName', pastedData.replace(regExp, '').trim()), 0)
      setTimeout(() => clearErrors('shopName'), 0)
    }
  }

  const onSubmit = async (formData: IInitRegistrationForm) => {
    try {
      await registrationSeller(formData).unwrap()
      await getSellerInfo()
      onSuccess?.()
    } catch (err: unknown) {
      useMessage((err as IResponseError)?.data?.message, 'error')
    }
  }

  const validateInnFunc = (value?: string) => {
    const companyTypeVal = getValues(Fields.companyType)
    return validateInn(value, companyTypeVal)
  }

  useEffect(() => {
    getMyLocation()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.registration}>
      <Controller
        name='companyType'
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field }) => (
          <Select view='auth' errors={errors} label='Тип организации' {...field} required fluid>
            {companyTypes.map(({ key, value }) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      />
      <Input
        name='inn'
        view='auth'
        register={register}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: /^\d*$/, message: 'Некорректный формат' },
          minLength: { value: 10, message: 'Некорректный ИНН' },
          maxLength: { value: 12, message: 'Некорректный ИНН' },
          validate: validateInnFunc
        }}
        errors={errors}
        minLength={10}
        maxLength={12}
        classNameInputWrapper='offset-top-8'
        label='ИНН'
        required
        fluid
      />
      <Input
        name='email'
        view='auth'
        type='email'
        register={register}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        errors={errors}
        classNameInputWrapper='offset-top-8'
        label='Email'
        required
        fluid
      />
      <Controller
        name='shopName'
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: /^[A-zА-яёЁ0-9.\-_ ]*$/, message: 'Некорректное название магазина' },
          minLength: { value: 2, message: 'Минимальное количество символов 2' }
        }}
        render={({ field: { name, value, onChange } }) => (
          <Input
            name={name}
            view='auth'
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value.replace(/["'«»]/g, ''))}
            onPaste={onPaste}
            errors={errors}
            minLength={2}
            classNameInputWrapper='offset-top-8'
            label='Название магазина'
            tooltip={`Символы, которые можно использовать:\n. \\ - _`}
            tooltipSettings={{ size: 'sm' }}
            required
            fluid
          />
        )}
      />
      <Controller
        name='address.fullname'
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field: { value, onChange } }) => (
          <Textarea
            name='fullname'
            view='auth'
            value={value}
            onChange={onChange}
            errors={errors.address}
            classNameInputWrapper='offset-top-8'
            label='Адрес магазина'
            onClick={() => setIsOpenAddressModal(true)}
            style={{ cursor: 'pointer' }}
            suffix='location'
            required
            fluid
            rows={1}
            readOnly
            autoHeight
          />
        )}
      />
      <ButtonGroup className='offset-top-24'>
        <Button color='light-blue' size='lg' onClick={onButtonBackClick}>
          Назад
        </Button>
        <Button disabled={isLoading} view='primary' type='submit' size='lg'>
          Готово
        </Button>
      </ButtonGroup>

      <Modal isOpen={isOpenAddressModal} onRequestClose={closeAddressModal} size='full' closePlace={null}>
        <SelectAddressModal
          initSelectedAddress={getValues('address')}
          onSuccess={onSuccessSelectAddress}
          onClose={closeAddressModal}
          mapZoom={13}
        />
      </Modal>
    </form>
  )
}

export default InitRegistration
