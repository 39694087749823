import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { IProductCharacteristic, IProductImage, IProductMainInfo } from 'models'
import { getTitleWithCopy } from 'helpers'
import { useAppSelector } from 'redux/hook'
import { useMessage } from 'hooks'
import { Button, PageEmpty, Text } from 'components/UI'
import $api from 'components/http/axios'
import {
  PreviewCard,
  ProductCharacteristicsSection,
  ProductMainInfoSection,
  ProductMediaFilesSection,
  ProductNavigationMenuItem,
  ProductPreviewSection
} from '.'
import s from './product.module.scss'

interface IProductToBackend {
  mainInfo: IProductMainInfo
  characteristics: IProductCharacteristic[]
  description: string
  images: IProductImage[]
  productId?: string
}

interface IProductProps {
  characteristicsInit?: IProductCharacteristic[]
  mainInfoInit?: IProductMainInfo
  categoryIdInit?: string | undefined
  descriptionInit?: string
  imagesInit?: IProductImage[]
  mode?: 'create' | 'edit' | 'copy'
}

interface INavigationMenu {
  name: string
  complete: boolean
}

type IFormValues = {
  mainInfo: IProductMainInfo | object
  characteristics: IProductCharacteristic[] | []
  description?: string
}

const Product = ({
  characteristicsInit,
  mainInfoInit,
  categoryIdInit,
  descriptionInit,
  imagesInit,
  mode = 'create'
}: IProductProps) => {
  const [step, setStep] = useState<number>(0)
  const [possiblyNextStep, setPossiblyNextStep] = useState<number>(-1)
  const [categoryId, setCategoryId] = useState<string | undefined>(categoryIdInit ?? undefined)
  const [images, setImages] = useState<IProductImage[]>(imagesInit ?? [])
  const [success, setSuccess] = useState(false)
  const [copyImagesLoaded, setCopyImagesLoaded] = useState<boolean>(false)
  const [isOpenProductPreview, setIsOpenProductPreview] = useState<boolean>(false)
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const location = useLocation()
  const locationState = location.state as { productId: string }
  const isEdit = mode === 'edit'
  const isCopy = mode === 'copy'

  const { setValue, getValues } = useForm<IFormValues>({
    defaultValues: {
      mainInfo:
        mainInfoInit && Object.keys(mainInfoInit)?.length
          ? {
              ...mainInfoInit,
              productName: isCopy ? getTitleWithCopy(mainInfoInit?.productName) : mainInfoInit?.productName,
              SKU: isCopy ? '' : mainInfoInit?.SKU,
              barcode: isCopy ? '' : mainInfoInit?.barcode,
              vat: (isCopy || isEdit) && mainInfoInit?.vat === undefined ? 0 : mainInfoInit?.vat
            }
          : {},
      characteristics: characteristicsInit?.length ? [...characteristicsInit] : [],
      description: descriptionInit ?? ''
    }
  })

  const requiredCharacteristics = {
    Бренд: true,
    'Тип товара': true,
    'Страна бренда': true,
    'Страна-производитель': true
  } as { [key: string]: boolean }

  const navigationMenu: INavigationMenu[] = [
    { name: 'Основная информация', complete: false },
    { name: 'Характеристики', complete: false },
    { name: 'Медиафайлы', complete: false },
    { name: 'Предпросмотр', complete: false }
  ]

  const onSuccessTriggerForm = (pageTo: number) => {
    setPossiblyNextStep(pageTo)
    if (pageTo !== -1) setStep(pageTo)
  }

  const onSuccessMainInfo = (formData: IProductMainInfo, nextPage: number) => {
    setValue('mainInfo', formData)
    onSuccessTriggerForm(nextPage)
  }

  const onSuccessCharacteristics = (
    formData: {
      characteristics: IProductCharacteristic[]
      description?: string
    },
    nextPage: number
  ) => {
    setValue('characteristics', formData.characteristics)
    setValue('description', formData.description)
    onSuccessTriggerForm(nextPage)
  }

  const handleCategoryId = (id: string | undefined) => {
    if (id && categoryId !== id && getValues('characteristics')?.length) {
      setValue('characteristics', [])
    }

    setCategoryId(id)
  }

  const onRemoveImage = useCallback(
    async (name: string) => {
      try {
        const response = await $api.delete(`/api/seller/${activeShop?._id}/products/images?filename=${name}`, {
          headers: { 'Content-Type': 'application/json' }
        })

        if (response.status === 204) {
          setImages((prev: IProductImage[]) => prev.filter((v: IProductImage) => name !== v.filename))
          useMessage(`Изображение удалено`)
        }
      } catch (e: any) {
        useMessage(e?.response?.data?.error || 'Произошла ошибка при удалении изображения', 'error')
        console.error(e)
      }
    },
    [images]
  )

  const submitCard = async (e?: Event) => {
    try {
      e?.preventDefault()
      const characteristicsData = getValues('characteristics')
      const errorsCharacteristicsIndexes: IProductCharacteristic[] = []

      characteristicsData.forEach((v: IProductCharacteristic) => {
        if (requiredCharacteristics[v.title] && !v.value?.length) {
          useMessage(`В разделе характеристики не заполнено поле "${v.title}"`, 'error')
          errorsCharacteristicsIndexes.push(v)
        }
      })

      if (!errorsCharacteristicsIndexes?.length) {
        const defaultProductData = {
          mainInfo: getValues('mainInfo'),
          characteristics: getValues('characteristics'),
          description: getValues('description'),
          images
        } as IProductToBackend

        if (!defaultProductData.mainInfo.regularPrice) {
          defaultProductData.mainInfo.regularPrice = 0
        }

        if (isEdit) {
          defaultProductData.productId = locationState.productId

          await $api.patch(`/api/seller/${activeShop?._id}/products`, JSON.stringify(defaultProductData), {
            headers: { 'Content-Type': 'application/json' }
          })
        } else {
          await $api.post(`/api/seller/${activeShop?._id}/products`, JSON.stringify(defaultProductData), {
            headers: { 'Content-Type': 'application/json' }
          })
        }

        setSuccess(true)
      }
    } catch (error: any) {
      useMessage(
        `Ошибка при ${isEdit ? 'сохранении' : 'создании'} товара. ${error?.response?.data?.message ?? ''}`,
        'error'
      )
    }
  }

  useEffect(() => {
    if (isEdit) {
      if (mainInfoInit?.manufacturerSKU) {
        setValue('mainInfo.manufacturerSKU', '')
      }
    }
  }, [isEdit])

  if (success) return <PageEmpty title='Товар успешно добавлен и проходит модерацию!' />

  return (
    <div className={s.layout}>
      <div className={s.main}>
        {step === 0 && (
          <ProductMainInfoSection
            data={getValues('mainInfo')}
            characteristics={getValues('characteristics')}
            setCategoryId={handleCategoryId}
            possiblyNextStep={possiblyNextStep}
            onSuccessTriggerForm={onSuccessTriggerForm}
            onSuccess={onSuccessMainInfo}
            mode={mode}
            sellerId={activeShop?._id as string}
          />
        )}
        {step === 1 && (
          <ProductCharacteristicsSection
            data={{
              characteristics: getValues('characteristics'),
              description: getValues('description')
            }}
            categoryId={categoryId}
            requiredCharacteristics={requiredCharacteristics}
            possiblyNextStep={possiblyNextStep}
            onSuccessTriggerForm={onSuccessTriggerForm}
            onSuccess={onSuccessCharacteristics}
          />
        )}
        {step === 2 && (
          <ProductMediaFilesSection
            sellerId={activeShop?._id as string}
            images={images}
            setImages={setImages}
            onRemoveImage={onRemoveImage}
            possiblyNextStep={possiblyNextStep}
            onSuccessTriggerForm={onSuccessTriggerForm}
            copyImagesLoaded={copyImagesLoaded}
            onSuccessCopyImagesLoaded={() => setCopyImagesLoaded(true)}
            mode={mode}
          />
        )}
        {step === 3 && (
          <ProductPreviewSection
            mainInfo={getValues('mainInfo')}
            characteristics={getValues('characteristics')}
            description={getValues('description') || ''}
            possiblyNextStep={possiblyNextStep}
            onSuccessTriggerForm={onSuccessTriggerForm}
            onSubmit={submitCard}
          />
        )}
      </div>
      <div className={s.nav}>
        {navigationMenu.map((v: INavigationMenu, i: number) => (
          <ProductNavigationMenuItem
            key={v.name}
            index={i}
            name={v.name}
            completed={v.complete}
            activeIndex={step}
            onClick={setPossiblyNextStep}
          />
        ))}
        {step === 3 && (
          <div className={s.nav__prompt}>
            <Text size='xs' weight='regular' family='secondary'>
              Перед отправкой перепроверьте введенные данные а также рекомендуем посмотреть как будет выглядеть страница
              в магазине
            </Text>
            <Button className='offset-top-16' color='middle-grey' onClick={() => setIsOpenProductPreview(true)} fluid>
              Посмотреть
            </Button>
          </div>
        )}
      </div>

      <PreviewCard
        data={{
          ...getValues('mainInfo'),
          characteristics: getValues('characteristics'),
          description: getValues('description'),
          images
        }}
        isOpen={isOpenProductPreview}
        onClose={() => setIsOpenProductPreview(false)}
      />
    </div>
  )
}

export default Product
