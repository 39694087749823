import { useSearchParams } from 'react-router-dom'

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getQueryParams = (query: string) => searchParams.get(query)

  const setQueryParams = (query: string, value: string) => {
    searchParams.set(query, value)
    setSearchParams(searchParams)
  }

  const deleteQueryParams = (query: string) => {
    setSearchParams((params) => {
      params.delete(query)
      return params
    })
  }

  return { setQueryParams, getQueryParams, deleteQueryParams, searchParams }
}
