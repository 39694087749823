import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { Text } from 'components/UI'
import logo from 'public/Logo.svg'
import s from './auth-layout.module.scss'

interface IAuthLayoutProps {
  children: React.ReactNode
  title?: string
  subTitle?: string
}

const AuthLayout = ({ children, title, subTitle }: IAuthLayoutProps) => (
  <div className={s['auth-layout']}>
    <Link to={routes.HOME}>
      <img className={s.logo} src={logo} alt='Логотип Kovkert' />
    </Link>
    <div className={s['auth-layout__modal']}>
      {title && (
        <Text as='h2' className={s.title} family='object-sans' weight='medium' align='center'>
          {title}
        </Text>
      )}
      {subTitle && (
        <Text as='p' family='object-sans' className={s['sub-title']} align='center'>
          {subTitle}
        </Text>
      )}
      {children}
    </div>
  </div>
)

export default AuthLayout
