import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { useAppSelector } from 'redux/hook'
import {
  EOrderPaymentMethodLabels,
  EOrderStatus,
  IProduct,
  TOrderCard,
  TOrderItem,
  TOrderItemProductImage
} from 'models'
import { useQueryParams } from 'hooks'
import { setSpaceBetweenCharacters } from 'helpers'
import { Button, DotsLoader, PageEmpty, Table, Text } from 'components/UI'
import { useTimeHook } from 'components/Hooks/useTime'
import { PreviewCard, ProductImage } from 'components/product'
import $api from 'components/http/axios'
import OrderStatus from './OrderStatus'
import OrderStatusLabel from './OrderStatusLabel'
import s from './orders.module.scss'

const OrderInfoDrawer = () => {
  const [previewCard, setPreviewCard] = useState<IProduct | undefined>(undefined)
  const { getQueryParams } = useQueryParams()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const orderId = getQueryParams('order')
  const queryClient = useQueryClient()

  const { data, isLoading, error } = useQuery<TOrderCard>({
    queryKey: [`api/seller/${activeShop?._id}/orders/${orderId}`],
    queryFn: async () => {
      const { status, data: requestData } = await $api.get(`api/seller/${activeShop?._id}/orders/${orderId}`)
      if (status !== 200) throw new Error('Ошибка получения заказа!')
      return requestData.order
    },
    enabled: Boolean(orderId)
  })

  const { mutate: onChangeStatusOrder, isLoading: isLoadingChangeStatus } = useMutation({
    mutationFn: async (status: EOrderStatus) => {
      if (activeShop?._id && data) {
        await $api.post(`/api/seller/${activeShop?._id}/orders/${data._id}/status`, { status })
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [`api/seller/${activeShop?._id}/orders/${orderId}`] })
  })

  const { mutate: getProductById } = useMutation({
    mutationFn: async (productId: string) => {
      const response = await $api.get<{ message: IProduct }>(`/api/seller/get_product_card?productId=${productId}`)
      return response.data.message
    },
    onSuccess: (responseData) => setPreviewCard(responseData)
  })

  const isDeliveryType = data?.deliveryType?.label === 'Доставка курьером'

  const renderRecipientInfoBlock = (title: string, value: (string | number)[]) => (
    <div className={s['recipient-info-block']}>
      <Text size='xxs' color='middle-grey'>
        {title}
      </Text>
      {value.map((v: string | number) => (
        <Text key={v} size='md' className={s['recipient-info-block__info']} family='secondary'>
          {v}
        </Text>
      ))}
    </div>
  )

  const columns: ColumnDef<TOrderItem>[] = [
    {
      accessorKey: 'product.images',
      header: 'Фото',
      size: 58,
      minSize: 58,
      cell: (info: CellContext<TOrderItem, unknown>) => {
        const images = info.getValue() as TOrderItemProductImage[]
        return <ProductImage src={images?.[0]?.link} width={26} height={26} className={s['image-cell-wrapper']} />
      }
    },
    {
      accessorKey: 'product.productName',
      header: 'Наименование',
      minSize: 230
    },
    {
      accessorKey: 'SKU',
      header: 'Артикул',
      size: 140,
      minSize: 140
    },
    {
      accessorKey: 'quantity',
      header: 'Кол–во, шт',
      size: 86,
      minSize: 86,
      cell: (info: CellContext<TOrderItem, unknown>) => setSpaceBetweenCharacters(info.getValue() as number)
    },
    {
      accessorKey: 'totalPrice',
      header: 'Цена, ₽',
      size: 96,
      minSize: 96,
      cell: (info: CellContext<TOrderItem, unknown>) => setSpaceBetweenCharacters(info.getValue() as number)
    }
  ]

  if (isLoading) return <DotsLoader center />

  // @ts-ignore
  if (error?.response?.data?.error) return <PageEmpty title={error?.response?.data?.error} />
  if (!orderId || !data) return <PageEmpty title='Произошла ошибка' />

  return (
    <div className={s['order-info']}>
      <div className={s['order-info__header']}>
        <div className={s['order-number']}>
          <div>
            <Text size='xl'>{data.orderNumber}</Text>
            <OrderStatusLabel status={data.status} className={s.status} />
          </div>
          {data?.status === EOrderStatus.CREATED && data?.paymentMethod?.label === EOrderPaymentMethodLabels.cod && (
            <Button
              style={{ marginLeft: 20 }}
              size='xs'
              color='white'
              onClick={() => onChangeStatusOrder(EOrderStatus.CANCELLED_BY_SELLER)}
            >
              Отменить заказ
            </Button>
          )}
        </div>
        <Text as='p' size='xxs' color='middle-grey' className='offset-top-4' family='secondary' weight='regular'>
          {useTimeHook(data.createdAt)?.minDate || ''}
        </Text>
      </div>
      <div className={s['order-info__content']}>
        <OrderStatus order={data} onChangeStatus={onChangeStatusOrder} disabled={isLoadingChangeStatus} />
        <div className={s['recipient-info']}>
          {renderRecipientInfoBlock('Получатель', [
            `${data?.recipient?.lastName || '-'} ${data?.recipient?.firstName || ''}`,
            data?.recipient?.phone || ''
          ])}
          {renderRecipientInfoBlock(data.deliveryType.label, [data.deliveryAddress])}
          {renderRecipientInfoBlock('Способ оплаты', [data.paymentMethod.label])}
          {renderRecipientInfoBlock('Товаров на сумму', [`${setSpaceBetweenCharacters(data.totalPrice)} ₽`])}
          {isDeliveryType && data?.deliveryPrice && (
            <>
              {renderRecipientInfoBlock('Доставка', [`${setSpaceBetweenCharacters(data?.deliveryPrice)} ₽`])}
              {renderRecipientInfoBlock('Итого', [
                `${setSpaceBetweenCharacters(data.totalPrice + data.deliveryPrice)} ₽`
              ])}
            </>
          )}
        </div>
        <div className={s['order-items-table']}>
          <Table
            defaultData={data.orderItems}
            defaultColumns={columns}
            onRowClick={(rowData) => getProductById(rowData.product._id)}
            resize
            fluid
          />
        </div>
      </div>
      <PreviewCard data={previewCard} isOpen={Boolean(previewCard)} onClose={() => setPreviewCard(undefined)} />
    </div>
  )
}

export default OrderInfoDrawer
