import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './header'
import { Navigation } from './navigation'
import { VerificationInfo } from './verificationInfo'
import Notifications from '../Notifications/Notifications'
import s from './layout.module.scss'

const Layout = () => (
  <div className={s.layout}>
    <Header />
    <main className={s.main}>
      <div className={s['nav-wrapper']}>
        <Navigation />
        <div className={s.verification}>
          <VerificationInfo />
        </div>
      </div>
      <div className={s['content-wrapper']}>
        <Outlet />
      </div>
    </main>
    <Notifications />
  </div>
)

export default Layout
