import React, { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import useMountTransition from 'components/Hooks/useMountTransition'
import { CloseIcon } from 'components/UI'
import s from './style.module.scss'

interface IDrawerProps {
  show: boolean
  onClose: VoidFunction
  children?: React.ReactNode
  slideDirection?: 'right' | 'left'
  width?: string
  closeOnOutSide?: boolean
}

const Drawer = ({ show, onClose, children, slideDirection = 'right', width, closeOnOutSide = true }: IDrawerProps) => {
  const shouldRenderChild = useMountTransition(show, 300)
  const isDirectionRight = slideDirection === 'right'

  const closeOnEscKey = useCallback(
    (e: any) => {
      if (e.charCode || e.keyCode === 27) onClose()
    },
    [onClose]
  )

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscKey)
    return () => document.body.addEventListener('keydown', closeOnEscKey)
  }, [closeOnEscKey])

  useEffect(() => {
    document.body.classList.toggle(s['overflow-hidden'], show)
  }, [show])

  const renderDrawer = () => (
    <div
      className={cx(s['drawer-wrapper'], {
        [s.right]: isDirectionRight,
        [s['right--slide-in']]: isDirectionRight && show,
        [s['right--slide-out']]: isDirectionRight && !show,
        [s.left]: !isDirectionRight,
        [s['left--slide-in']]: !isDirectionRight && show,
        [s['left--slide-out']]: !isDirectionRight && !show
      })}
      style={{ width }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={s.drawer}>
        <div className={s.drawer__content}>{children}</div>
        <CloseIcon className={s['drawer__close-icon']} onClick={onClose} />
      </div>
    </div>
  )

  if (!shouldRenderChild) return null

  return ReactDOM.createPortal(
    <>
      {shouldRenderChild && (
        <>
          <div
            className={cx(s['drawer-overlay'], {
              [s['drawer-overlay--enter']]: show,
              [s['drawer-overlay--exit']]: !show
            })}
            onClick={closeOnOutSide ? onClose : () => {}}
          />
          {renderDrawer()}
        </>
      )}
    </>,
    document.getElementById('root')!
  )
}

export default Drawer
