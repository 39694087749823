import React, { useEffect, useState } from 'react'
import { Icon, Text, Tooltip } from 'components/UI'
import { useAppSelector } from 'redux/hook'
import { useParams } from 'react-router-dom'
import { useChatContext } from '../../../pages/Chat/Chat'
import { SOCKET_EVENTS } from '../constants_chat'
import styles from './styles/messages_header.module.scss'

function MessagesHeader() {
  const { id } = useParams()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const [companion, setCompanion] = useState<any | null>(null)

  const { dialogs, socket } = useChatContext()

  const deleteDialog = () => {
    socket.emit(SOCKET_EVENTS.CLEAR_DIALOG, {
      conversation: id,
      user: activeShop?._id
    })
  }

  useEffect(() => {
    setCompanion(dialogs.find((item: any) => item._id === id)?.companion || null)
  }, [dialogs, companion, id])

  return (
    <div className={styles.header}>
      {companion && <div className={styles.header__name}>{companion.name}</div>}
      <Tooltip className={styles.tooltip} closeAfterClickOnContent>
        <div className={styles.tooltip__content} onClick={deleteDialog}>
          <Icon color='red' name='delete' />
          <Text className={styles.text} color='red'>
            Удалить
          </Text>
        </div>
      </Tooltip>
    </div>
  )
}

export default MessagesHeader
