import React from 'react'
import cx from 'classnames'
import { productDeclension } from 'helpers'
import { Icon, Text } from 'components/UI'
import Button from './Button'
import s from './selected-menu.module.scss'

interface ISelectedMenuProps {
  children: React.ReactNode
  active: boolean
  selectedNumber?: number
  onClose: VoidFunction
}

const SelectedMenu = ({ children, active = false, selectedNumber = 0, onClose }: ISelectedMenuProps) => (
  <div className={cx(s['selected-menu'], { [s.active]: active })}>
    <Text size='md' family='secondary' color='white'>
      {selectedNumber === 1 ? 'Выбран' : 'Выбрано'} {selectedNumber} {productDeclension(selectedNumber)}
    </Text>
    <div className={s['selected-menu__content']}>{children}</div>
    <Icon className={s['selected-menu__close']} name='close' color='white' onClick={onClose} />
  </div>
)

export default Object.assign(SelectedMenu, {
  Button
})
