import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import cx from 'classnames'
import { useAppSelector } from 'redux/hook'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { ILegalInformationError, TSellerShop, VerificationStatus, VerificationStatusFromServer } from 'models'
import { Registration } from 'components/registration'
import { Button, Text } from 'components/UI'
import $api from 'components/http/axios'
import s from './verification-info.module.scss'

interface IVerificationInfoModalData {
  title: string
  description: string
  buttonAction?: VoidFunction
  buttonText?: string
}

const VerificationInfoContent = ({
  title,
  description,
  buttonAction,
  buttonText = 'Подробнее'
}: IVerificationInfoModalData) => (
  <>
    <Text size='lg' color='white'>
      {title}
    </Text>
    <Text size='md' color='white' family='secondary'>
      {description}
    </Text>
    {buttonAction && (
      <Button view='link' className={s.button} onClick={buttonAction}>
        {buttonText}
      </Button>
    )}
  </>
)

const VerificationInfo = () => {
  const [formFieldErrors, setFormFieldErrors] = useState<ILegalInformationError[] | undefined>(undefined)
  const [formGlobalErrors, setFormGlobalErrors] = useState<string | undefined>(undefined)
  const [showModal, setShowModal] = useState<boolean>(false)

  const [getSellerInfo] = useGetSellerInfoMutation()
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  const { mutate: handleCloseAlert } = useMutation({
    mutationFn: async () => {
      await $api.patch(`/api/seller/${activeShop?._id}/legal-information/confirm`)
    },
    onSuccess: () => getSellerInfo()
  })

  const { mutate: getError } = useMutation({
    mutationFn: async () => $api.get(`/api/seller/${activeShop?._id}/legal-information/errors`),
    onSuccess: (data) => {
      setFormFieldErrors(data.data?.data?.validationErrors)
      setFormGlobalErrors(data.data?.data?.message)
      handleShowModal()
    }
  })

  const verificationInfoModalData: Record<VerificationStatusFromServer, IVerificationInfoModalData> = {
    new: {
      title: 'Завершите регистрацию',
      description:
        'Добавьте реквизиты и дождитесь модерации. Если все в порядке вы сможете принять оферту и начать торговать.',
      buttonAction: handleShowModal
    },
    moderation: {
      title: 'На модерации',
      description:
        'Ваши данные проходят проверку. Это займет немного времени. Мы пришлем уведомление вам на почту вместе с договором.'
    },
    error: {
      title: 'Ошибка!',
      description: 'Введенные реквизиты не прошли модерацию! Пожалуйста проверьте замечания и исправьте их.',
      buttonAction: getError
    },
    verified: {
      title: 'Готово!',
      description: 'Ваша компания прошла модерацию! Можете приступать к продажам!',
      buttonAction: handleCloseAlert,
      buttonText: 'Закрыть'
    },
    confirmed: { title: '', description: '' }
  }

  useEffect(() => {
    if (formFieldErrors?.length) setFormFieldErrors(undefined)
    if (formGlobalErrors) setFormGlobalErrors(undefined)
  }, [activeShop?._id])

  if (activeShop?.verification === VerificationStatus.confirmed) return null

  return (
    <>
      <div className={cx(s.verification, s[`verification--${activeShop?.verification}`])}>
        {/* @ts-ignore */}
        <VerificationInfoContent {...verificationInfoModalData[activeShop?.verification]} />
      </div>
      <Registration
        shop={activeShop as TSellerShop}
        showModal={showModal}
        onClose={handleHideModal}
        fieldErrors={formFieldErrors}
        globalErrors={formGlobalErrors}
        mode={activeShop?.verification === VerificationStatus.error ? 'edit' : 'create'}
      />
    </>
  )
}

export default VerificationInfo
