import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import useSocket from '../Hooks/useSocket'
import styles from './notifications.module.scss'

const Notifications = () => {
  const { notification, removeNotification } = useSocket()

  return ReactDOM.createPortal(
    <div className={styles.notifications}>
      {notification.map((message: any, index: number) => (
        <div className={styles.notifications__message_block} key={JSON.stringify(message)}>
          <div className={styles.notifications__block_top}>
            <div className={styles.notifications__message_title}>
              <span>{message.title}</span>
              <span className={styles.notifications__animate} />
            </div>
            <div className={styles.notifications__message_body}>{message.description}</div>
          </div>

          <div className={styles.notifications__sub_list}>
            {message.items.map((subItemList: any) => (
              <div key={JSON.stringify(subItemList)} className={styles.notifications__sub_list_name}>
                {subItemList.name}
              </div>
            ))}
          </div>
          <div className={styles.notifications__message_btn}>
            <button onClick={() => removeNotification(index)}>
              <Link to={`/orders/${message.href}`}>Посмотреть</Link>
            </button>
          </div>
        </div>
      ))}
    </div>,
    document.getElementById('root') as Element
  )
}

export default Notifications
