import axios, { AxiosResponse } from 'axios'
import { authApi } from 'redux/auth/authApi'
import { store } from 'redux/store'
import { setToken } from '../../redux/auth/authSlice'

export const API_URL = process.env.REACT_APP_SERVER_URL || 'https://staging-api.kovkert.ru'

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL
})

$api.interceptors.request.use((config: any) => {
  config.headers.authorization = `Bearer ${store.getState().store.auth.token}`
  config.headers.shop = store.getState().store?.seller?.activeShop?._id
  return config
})

$api.interceptors.response.use(
  (config: AxiosResponse) => config,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._isRetry) {
      try {
        originalRequest._isRetry = true

        const response = await $api.get('/api/auth/token/refresh', {
          withCredentials: true
        })

        store.dispatch(setToken(response.data?.accessToken))

        return await $api.request(originalRequest)
      } catch (err) {
        console.error(err)
      }
    }

    if (error.response.status === 403) {
      store.dispatch(authApi.endpoints.logoutUser.initiate())
      return
    }

    throw error
  }
)

export default $api
