import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDebounce } from 'hooks'
import { DaData } from 'services/dadata'
import { Button, ButtonGroup, Checkbox, Input, Select } from 'components/UI'
import { AuthentificationData, AuthentificationFormProps } from '../../types'
import { setDadataValueInForm, setFormError } from '../../utils'
import { Fields } from './const'
import { Cities } from './types'

const CompanyAddressStep = ({ data, fieldErrors, goToPrevStep, goToNextStep, mode }: AuthentificationFormProps) => {
  const [focus, setFocus] = useState<string>('')
  const [cities, setCities] = useState<Cities[]>([])
  // const [countries, setCountries] = useState<Countries[]>([])
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors }
  } = useForm<AuthentificationData>({
    mode: 'onChange',
    defaultValues: {
      ...data
    }
  })

  const debouncedCityValue = useDebounce<string>(watch(Fields.city), 300)
  // const debouncedCountryValue = useDebounce<string>(watch(Fields.country), 300)

  // useEffect(() => {
  //   if (!debouncedCountryValue) return
  //   DaData.findByAddress(debouncedCountryValue).then(setCountries)
  // }, [debouncedCountryValue])

  useEffect(() => {
    if (!debouncedCityValue) return
    DaData.findByAddress(debouncedCityValue).then(setCities)
  }, [debouncedCityValue])

  useEffect(() => {
    setFormError(fieldErrors, Fields, getValues, setError)
  }, [])

  useEffect(() => {
    if (mode === 'create') setDadataValueInForm(data.inn ?? '', setValue)
  }, [mode])

  return (
    <form onSubmit={handleSubmit(goToNextStep)}>
      {/* <Controller */}
      {/*  name={Fields.country} */}
      {/*  control={control} */}
      {/*  rules={{ required: { value: true, message: 'Обязательное поле' } }} */}
      {/*  render={({ field: { name, value, onChange } }) => ( */}
      {/*    <Select */}
      {/*      name={name} */}
      {/*      view='auth' */}
      {/*      mode='search' */}
      {/*      label='Область / Республика / Край' */}
      {/*      searchValue={value} */}
      {/*      defaultValue={value} */}
      {/*      value={value} */}
      {/*      onSearch={onChange} */}
      {/*      onChange={onChange} */}
      {/*      onFocus={() => setFocus(name)} */}
      {/*      onBlur={() => setFocus('')} */}
      {/*      open={focus === name && Boolean(countries?.length)} */}
      {/*      errors={errors} */}
      {/*      autoComplete='none' */}
      {/*      fluid */}
      {/*      showSearch */}
      {/*      required */}
      {/*    > */}
      {/*      {countries.map((v: Countries) => ( */}
      {/*        <Select.Option key={v.value} value={v.value}> */}
      {/*          {v.value} */}
      {/*        </Select.Option> */}
      {/*      ))} */}
      {/*    </Select> */}
      {/*  )} */}
      {/* /> */}
      <Controller
        name={Fields.city}
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field: { name, value, onChange } }) => (
          <Select
            name={name}
            view='auth'
            mode='search'
            label='Город'
            classNameInputWrapper='offset-top-16'
            searchValue={value}
            defaultValue={value}
            value={value}
            onSearch={onChange}
            onChange={onChange}
            onFocus={() => setFocus(name)}
            onBlur={() => setFocus('')}
            open={focus === name && Boolean(cities?.length)}
            errors={errors}
            autoComplete='none'
            fluid
            showSearch
            required
          >
            {cities.map((v: Cities) => (
              <Select.Option key={v.value} value={v.value}>
                {v.value}
              </Select.Option>
            ))}
          </Select>
        )}
      />
      <Controller
        name={Fields.street}
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='Улица, дом, корпус, помещение'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            {...rest}
            required
            fluid
          />
        )}
      />
      <Controller
        name={Fields.zip}
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          maxLength: { value: 6, message: 'Некорректный индекс' }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='Индекс'
            type='number'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            {...rest}
            required
            fluid
          />
        )}
      />
      <Controller
        name={Fields.type}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <Checkbox
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            name={name}
            label='Юридический адрес'
            size='md'
            classNameInputWrapper='offset-top-12'
          />
        )}
      />
      <ButtonGroup gap={8} className='offset-top-24'>
        <Button color='light-blue' onClick={() => goToPrevStep(getValues())} size='lg'>
          Назад
        </Button>
        <Button type='submit' size='lg'>
          Далее
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default CompanyAddressStep
