export const getDeepObjectKeys = <T>(obj: T) => {
  let keys: string[] = []

  for (const key in obj) {
    keys.push(key)

    if (typeof obj[key] === 'object') {
      const subKeys = getDeepObjectKeys(obj[key])
      keys = keys.concat(subKeys.map((subKey) => `${key}.${subKey}`))
    }
  }

  return keys
}
