import React, { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import useSWR from 'swr'
import { CharacteristicsGenericResponse, IProductCharacteristic } from 'models'
import { sanitzeToValidNumber } from 'utils/Sanitizers'
import { getDeepObjectKeys } from 'helpers'
import { useMessage } from 'hooks'
import { Button, ButtonGroup, DotsLoader, Input, PageEmpty, Select, Text, Textarea } from 'components/UI'
import $api from 'components/http/axios'
import { getValidation } from '../models'

type IFormData = {
  characteristics: IProductCharacteristic[] | []
  description?: string
}

interface IProductCharacteristicsProps {
  data?: IFormData
  categoryId: string | undefined
  possiblyNextStep: number
  requiredCharacteristics: { [key: string]: boolean }
  onSuccessTriggerForm: (step: number) => void
  onSuccess: (data: IFormData, page: number) => void
}

const fetcher = (url: string) => $api.get(url).then((res) => res.data)

const ProductCharacteristicsSection = ({
  data,
  categoryId,
  possiblyNextStep,
  requiredCharacteristics,
  onSuccessTriggerForm,
  onSuccess
}: IProductCharacteristicsProps) => {
  const {
    data: characteristicsResponse,
    error,
    isLoading
  } = useSWR<CharacteristicsGenericResponse>(
    () => (categoryId ? `/api/card/get_category_characteristics?id=${categoryId}` : null),
    fetcher
  )

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { errors }
  } = useForm<IFormData>({
    defaultValues: {
      ...data
    }
  })

  const { fields } = useFieldArray({ control, name: 'characteristics' })

  const setInitialCharacteristics = () => {
    const initialCharacteristics = characteristicsResponse?.message?.characteristics.map(
      (characteristic: IProductCharacteristic) => {
        const result: IProductCharacteristic = {
          ...characteristic,
          value: [],
          options: characteristic?.value
        }

        if (result?.__v) delete result.__v

        return result
      }
    )

    if (initialCharacteristics) setValue('characteristics', initialCharacteristics)
  }

  const submitForm = (
    formData: {
      characteristics: IProductCharacteristic[]
      description?: string
    },
    nextPage: number
  ) =>
    onSuccess(
      {
        ...formData,
        characteristics: formData.characteristics.map((v: IProductCharacteristic) => {
          if (!Array.isArray(v.value)) return { ...v, value: [v?.value] }
          return v
        })
      },
      nextPage
    )

  const onSubmit = (formData: IFormData | any) =>
    submitForm(
      {
        ...formData,
        characteristics: formData.characteristics.map((v: IProductCharacteristic) => {
          if (v.type === 'number' && !Array.isArray(v?.value)) {
            return {
              ...v,
              value: v?.value ? [sanitzeToValidNumber(v?.value)] : []
            }
          }

          return v
        })
      },
      2
    )

  useEffect(() => {
    if (characteristicsResponse && !data?.characteristics?.length) {
      setInitialCharacteristics()
    }
  }, [characteristicsResponse])

  useEffect(() => {
    if (possiblyNextStep > -1) {
      const fetchData = async () => {
        const isFormValueFieldFilled = await trigger(getDeepObjectKeys(getValues()) as any)

        if (!isFormValueFieldFilled) {
          setTimeout(() => clearErrors(), 2000)
          useMessage('Необходимо заполнить все обязательные поля', 'error')
          return onSuccessTriggerForm(-1)
        }

        submitForm(getValues(), possiblyNextStep)
      }

      fetchData()
    }
  }, [possiblyNextStep])

  if (isLoading) return <DotsLoader center />
  if (error) return <PageEmpty title='Произошла ошибка при загрузке характеристик' />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text as='p' size='lg'>
        Основные характеристики
      </Text>
      <div className='offset-top-12'>
        {fields
          .filter((v: IProductCharacteristic) => requiredCharacteristics[v.title])
          .map((v: IProductCharacteristic) => {
            const correctIndex = getValues('characteristics').findIndex(
              (characteristic: IProductCharacteristic) => characteristic._id === v._id
            )
            const validate = getValidation(v.title)

            return (
              <Controller
                key={v._id}
                name={`characteristics.${correctIndex}.value`}
                control={control}
                rules={validate}
                render={({ field: { value, onChange } }) => (
                  <Select
                    classNameInputWrapper='offset-top-12'
                    name='value'
                    label={v.title}
                    onChange={onChange}
                    value={value}
                    maxCount={validate?.maxLength?.value}
                    mode={v.title === 'Тип товара' ? 'multiple' : 'tags'}
                    errors={errors.characteristics?.[correctIndex]}
                    required
                    fluid
                  >
                    {v?.options?.map((opt: null | undefined | string) => (
                      <Select.Option key={opt} value={opt}>
                        {opt}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            )
          })}
      </div>
      <Textarea
        maxLength={4000}
        rules={getValidation('description')}
        name='description'
        classNameInputWrapper='offset-top-12'
        style={{ height: 88 }}
        register={register}
        label='Описание'
        fluid
      />
      <Text as='p' size='lg' className='offset-top-32'>
        Дополнительные характеристики
      </Text>
      <div className='offset-top-12'>
        {fields
          .filter((v: IProductCharacteristic) => !requiredCharacteristics[v.title])
          .map((v: IProductCharacteristic) => {
            const correctIndex = getValues('characteristics').findIndex(
              (characteristic: IProductCharacteristic) => characteristic._id === v._id
            )

            if (v.type === 'number') {
              return (
                <Controller
                  key={v._id}
                  name={`characteristics.${correctIndex}.value`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      classNameInputWrapper='offset-top-12'
                      name='value'
                      max={10000000}
                      type='number'
                      label={v.title}
                      value={value}
                      onChange={onChange}
                      fluid
                    />
                  )}
                />
              )
            }

            return (
              <Controller
                key={v._id}
                name={`characteristics.${correctIndex}.value`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    classNameInputWrapper='offset-top-12'
                    name='value'
                    label={v.title}
                    onChange={onChange}
                    value={value}
                    mode={v.type === 'string' ? 'tags' : undefined}
                    fluid
                    arrowIcon
                  >
                    {v?.options?.map((opt: null | undefined | string) => (
                      <Select.Option key={opt} value={opt}>
                        {opt}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            )
          })}
      </div>
      <ButtonGroup justifyContent='space-between' className='offset-top-32'>
        <Button onClick={() => onSuccessTriggerForm(0)} size='sm' color='light-blue'>
          Назад
        </Button>
        <Button type='submit' size='sm'>
          Далее
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default ProductCharacteristicsSection
