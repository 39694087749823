import React, { useState } from 'react'
import $api from 'components/http/axios'
import { useAppSelector } from 'redux/hook'
import { TReview } from 'models/review'
import { useDate, useMessage } from 'hooks'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Icon, Modal, Text, Textarea } from 'components/UI'
import s from './review_reply.module.scss'

const ReviewReply: React.FC<TReview> = ({ reply, _id }) => {
  const queryClient = useQueryClient()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const [deleteModal, setDeleteModal] = useState(false)
  const [inputState, setStateInput] = useState(reply?.text ?? '')
  const [replyInputActive, setReplyInputActive] = useState(false)
  const { dayLong, monthLong, yearLong } = useDate(reply?.createdAt ?? new Date())

  const closeDeleteModal = () => setDeleteModal(false)
  const openDeleteModal = () => setDeleteModal(true)

  const addReply = async () => {
    try {
      await $api.patch(`/api/seller/${activeShop?._id}/reviews/reply`, { reviewId: _id, text: inputState })
      queryClient.invalidateQueries({ queryKey: ['get_reviews'] })
      setReplyInputActive(false)
      setStateInput('')
    } catch (error) {
      useMessage('Не удалось добавить ответ')
    }
  }

  const deleteReply = async () => {
    try {
      await $api.delete(`/api/seller/${activeShop?._id}/reviews/reply/${_id}`)
      queryClient.invalidateQueries({ queryKey: ['get_reviews'] })
      setReplyInputActive(false)
      setStateInput('')
    } catch (error) {
      useMessage('Не удалось добавить ответ', 'error')
    }
    closeDeleteModal()
  }

  return (
    <div className={s.reply}>
      {replyInputActive ? (
        <div className={s.reply_input}>
          <Textarea
            value={inputState}
            onChange={(e) => setStateInput(e.target.value)}
            autoHeight
            fluid
            placeholder='Текст ответа'
            label='Текст ответа'
            name='reply'
          />
          <div className={s.reply_input__actions_btn}>
            <Button color='light-blue' onClick={() => setReplyInputActive(false)}>
              Отменить
            </Button>
            <Button onClick={addReply}>{reply ? 'Сохранить' : 'Отправить'} </Button>
          </div>
        </div>
      ) : reply ? (
        <div className={s.reply__text_wrapper}>
          <Text weight='regular' as='div' className={s.reply__text}>
            {reply.text}
          </Text>
          <div className={s.reply__text_footer}>
            <Text className={s.reply__date} weight='regular'>
              {dayLong} {monthLong} {yearLong}
            </Text>
            <div>
              <Icon
                onClick={() => setReplyInputActive(true)}
                name='edit'
                color='middle-grey'
                hoverColor='dark'
                className={s.reply__edit}
              />
              <Icon
                onClick={openDeleteModal}
                name='delete'
                color='middle-grey'
                hoverColor='dark'
                className={s.reply__delete}
              />
            </div>
          </div>
        </div>
      ) : (
        <Button color='light-blue' onClick={() => setReplyInputActive(true)} className={s.reply__set_actions}>
          Ответить
        </Button>
      )}

      <Modal size='sm' isOpen={deleteModal} onRequestClose={closeDeleteModal}>
        <div className={s.modal}>
          <Text size='lg' as='div'>
            Удаление комментария
          </Text>
          <Text weight='regular' as='div' className='offset-top-16'>
            Вы уверены что хотите удалить комментарий?
          </Text>
          <div className={s.modal__buttons}>
            <Button onClick={closeDeleteModal} view='link'>
              Отменить
            </Button>
            <Button onClick={deleteReply}>Удалить</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ReviewReply
