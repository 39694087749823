import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, ButtonGroup, Input, Text } from 'components/UI'
import { AuthentificationData, AuthentificationFormProps } from '../../types'
import { Fields } from './const'
import s from '../steps.module.scss'

interface BankRequisitesFormProps extends AuthentificationFormProps {
  isFetching: boolean
  globalErrors?: string
}

const BankRequisitesStep = ({
  data,
  fieldErrors,
  globalErrors,
  goToNextStep,
  goToPrevStep,
  isFetching
}: BankRequisitesFormProps) => {
  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors }
  } = useForm<AuthentificationData>({
    mode: 'onChange',
    defaultValues: {
      ...data
    }
  })

  const onSubmit = async (formData: AuthentificationData) =>
    goToNextStep({
      ...formData,
      [Fields.bankName]: formData[Fields.bankName]?.trim()
    })

  useEffect(() => {
    // @ts-ignore
    const errorsForCurrentFields = fieldErrors?.filter((v) => Fields[v.field])

    if (errorsForCurrentFields) {
      errorsForCurrentFields.forEach((v) => {
        if (v.rejectedValue === getValues(v.field)) {
          setError(v.field, { message: v.defaultMessage })
        }
      })
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Boolean(globalErrors) && (
        <div className={s['global-errors']}>
          <Text as='p' color='red' align='center' whiteSpace='pre-line'>
            {globalErrors}
          </Text>
        </div>
      )}
      <Controller
        name={Fields.bankName}
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field }) => (
          <Input view='auth' label='Наименование банка' errors={errors} maxLength={300} required fluid {...field} />
        )}
      />
      <Controller
        name={Fields.account}
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: /^\d*$/, message: 'Некорректный формат' },
          minLength: { value: 20, message: 'Некорректный расчетный счет' },
          maxLength: { value: 20, message: 'Некорректный расчетный счет' }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='Расчетный счет'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            minLength={20}
            maxLength={20}
            {...rest}
            required
            fluid
          />
        )}
      />
      <Controller
        name={Fields.bik}
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: /^\d*$/, message: 'Некорректный формат' },
          minLength: { value: 9, message: 'Некорректный БИК' },
          maxLength: { value: 9, message: 'Некорректный БИК' }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='БИК'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            minLength={9}
            maxLength={9}
            {...rest}
            required
            fluid
          />
        )}
      />
      <ButtonGroup gap={8} className='offset-top-24'>
        <Button disabled={isFetching} onClick={() => goToPrevStep(getValues())} color='light-blue' size='lg'>
          Назад
        </Button>
        <Button disabled={isFetching} type='submit' size='lg'>
          Отправить на проверку
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default BankRequisitesStep
