import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { YMaps } from '@pbe/react-yandex-maps'
import * as Sentry from '@sentry/react'
import { persistor, store } from './redux/store'
import { Router } from './Router'
import 'react-toastify/dist/ReactToastify.min.css'
import './styles/globals.scss'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  debug: false,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://server.kovkert.ru'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      Sentry.withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables
        })
        if (mutation.options.mutationKey && typeof mutation.options.mutationKey === 'string') {
          scope.setFingerprint(Array.from(mutation.options.mutationKey))
        }
        Sentry.captureException(err)
      })
    }
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      Sentry.withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash })
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')])
        Sentry.captureException(err)
      })
    }
  })
})

root.render(
  <YMaps
    query={{
      apikey: '1dc361aa-4db6-4596-8604-8d44cf601660'
    }}
  >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Router />
            <ToastContainer />
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </YMaps>
)

// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
