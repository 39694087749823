import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'redux/hook'
import { getProductById } from 'hooks'
import { IProduct } from 'models'
import { BaseFilter, OrderDateFilter, SearchFilter } from 'components/filters'
import { Category } from 'components/UI/selectCategory/types'
import { DeliveryOptions } from 'components/UI/charts/consts'
import { PreviewCard } from 'components/product'
import { BarChart, DotsLoader, Icon, PageEmpty, PageHeader, Text } from 'components/UI'
import $api from 'components/http/axios'
import s from './dashboard-page.module.scss'

interface IPeriodValue {
  fromDate: string
  toDate: string
}

const DashboardPage = () => {
  const [deliveryType, setDeliveryType] = useState('')
  const [product, setProduct] = useState<IProduct | null>(null)
  const [category, setCategory] = useState<(Category & { fullName: string }) | null>(null)
  const [periodValue, setPeriodValue] = useState<IPeriodValue | null>(null)
  const [isProductInfoOpen, setIsProductInfoOpen] = useState<boolean>(false)
  const { characteristics, mainInfo, description, images } = getProductById(product?._id)
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const handleDeliveryTypeQuery = (type: string) => {
    switch (type) {
      case DeliveryOptions.ByCourier:
        return 'Доставка курьером'
      case DeliveryOptions.Pickup:
        return 'Самовывоз из магазина'
      default:
        return ''
    }
  }

  const {
    data: statisticsData,
    isLoading,
    isError
  } = useQuery<any>({
    queryKey: ['getStatistics', activeShop?._id, deliveryType, periodValue, product, category],
    queryFn: async () => {
      const { status, data } = await $api.get(
        `/api/seller/${activeShop?._id}/orders/statistics?${deliveryType ? `&deliveryType=${handleDeliveryTypeQuery(deliveryType)}` : ''}${
          periodValue ? `&fromDate=${periodValue.fromDate}&toDate=${periodValue.toDate}` : ''
        }${product?._id ? `&product=${product._id}` : ''}${category?._id ? `&category=${category._id}` : ''}`
      )

      if (status !== 200) throw new Error('Данных не найдены')

      return data
    }
  })

  const onDateChange = (startDate: string | null, endDate?: string) => {
    setPeriodValue(startDate && endDate ? { fromDate: startDate, toDate: endDate } : null)
  }

  return (
    <div className={s['dashboard-page']}>
      <PageHeader title='Статистика'>
        <div className={s['dashboard-page__header']}>
          <OrderDateFilter label='Период' onFilterChange={onDateChange} disabled={false} />
          <SearchFilter
            icon={<Icon name='qr-code' color='dark-grey' />}
            title='Товар'
            onChange={setProduct}
            placeholder='Артикул или название товара'
            disabled={Boolean(category)}
            itemLabel={product?.barcode?.toString() || product?.SKU}
          />
          <SearchFilter
            icon={<Icon name='category' color='dark-grey' />}
            title='Категория'
            onChange={setCategory}
            placeholder='Поиск по категориям'
            disabled={Boolean(product)}
            itemLabel={category?.name}
          />
          <BaseFilter
            icon={<Icon name='delivery' color='dark-grey' />}
            label='Способ доставки'
            filterData={Object.values(DeliveryOptions)}
            onFilterChange={setDeliveryType}
            disabled={false}
          />
        </div>
      </PageHeader>
      {product && (
        <div onClick={() => setIsProductInfoOpen(true)} className={s.product}>
          <img className={s.product__image} src={product.images[0].link} alt={product.productName} />
          <Text as='p' family='object-sans' className={s.product__title} align='start'>
            {product.productName}
          </Text>
        </div>
      )}
      {category && (
        <div className={s.product}>
          <img className={s.product__image} src={category.img} alt={category.name} />
          <Text as='p' family='object-sans' className={s.product__title} align='start'>
            {category.fullName}
          </Text>
        </div>
      )}
      <div className={s['dashboard-page__content']}>
        {isLoading ? (
          <DotsLoader center />
        ) : !statisticsData?.formattedStatistics || isError ? (
          <PageEmpty title='Не удалось получить данные. Попробуйте еще раз' fullHeight={false} />
        ) : !statisticsData?.formattedStatistics?.length ? (
          <PageEmpty title='Нет данных' fullHeight={false} />
        ) : (
          <BarChart data={statisticsData} />
        )}
      </div>
      <PreviewCard
        data={{
          mainInfo,
          characteristics,
          description,
          images
        }}
        isOpen={isProductInfoOpen}
        onClose={() => setIsProductInfoOpen(false)}
      />
    </div>
  )
}
export default DashboardPage
