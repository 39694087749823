import React from 'react'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { IEmailAuthForm } from 'models'
import { useEmailAuthMutation } from 'redux/auth/authApi'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { routes } from 'constants/routes'
import { useMessage } from 'hooks'
import { Button, Input, Text } from 'components/UI'
import s from './email-auth.module.scss'

interface IEmailAuthProps {
  onAuthTypeChange: (data: string) => void
}

const EmailAuth = ({ onAuthTypeChange }: IEmailAuthProps) => {
  const [emailAuth, { isLoading }] = useEmailAuthMutation()
  const [getSellerInfo] = useGetSellerInfoMutation()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IEmailAuthForm>()

  const onSubmit = async (formData: IEmailAuthForm) => {
    try {
      await emailAuth(formData).unwrap()
      const sellerInfoResponse = await getSellerInfo().unwrap()

      if (sellerInfoResponse?.data?.shops?.length) {
        navigate(routes.DASHBOARD)
      } else {
        navigate(routes.REGISTRATION)
      }
    } catch (error: any) {
      if (!error.data) {
        useMessage('Нет ответа от сервера', 'error')
      } else if (error.data.message) {
        useMessage(error.data.message, 'error')
      }
    }
  }

  return (
    <form className={cx(s['email-auth'], 'offset-top-40')} onSubmit={handleSubmit(onSubmit)}>
      <Text as='p' family='object-sans' weight='regular' className={s.number}>
        Адрес электронной почты
      </Text>
      <Text as='p' family='object-sans' weight='regular' className={s.description} color='gray'>
        Только для зарегистрированных пользователей
      </Text>
      <Input
        name='email'
        type='email'
        view='auth'
        rules={{ required: { value: true, message: 'Не заполнено поле Email' } }}
        register={register}
        errors={errors}
        disabled={isLoading}
        classNameInputWrapper='offset-top-16'
        placeholder='Email'
        autoFocus
        fluid
      />
      <Input
        name='password'
        type='password'
        view='auth'
        rules={{
          required: { value: true, message: 'Не заполнено поле Пароль' },
          minLength: { value: 3, message: 'Минимальная длина 3 символа' }
        }}
        register={register}
        errors={errors}
        disabled={isLoading}
        classNameInputWrapper='offset-top-8'
        placeholder='Пароль'
        fluid
      />
      <Button type='submit' className={cx('offset-top-12', s['button-submit'])} disabled={isLoading} fluid>
        Войти
      </Button>
      <Button className='offset-top-92' onClick={() => onAuthTypeChange('phone')} view='link' fluid>
        Войти по номеру
      </Button>
    </form>
  )
}

export default EmailAuth
