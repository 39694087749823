import { ILegalInformationError } from '../../models'

export interface AuthentificationData {
  account: string
  bankName: string
  bik: string
  companyType: string | undefined
  inn: string | undefined
  birthDate: string | Date
  kpp: string
  name?: string
  phone: string
  ogrn: string
  shopId: string | undefined
  firstName: string
  email?: string
  lastName: string
  middleName: string
  country: string | undefined
  city: string | undefined
  street: string
  zip: string
  middleNameRequired?: boolean
  type: boolean
}

export enum Steps {
  REQUISITES = 1,
  CHECK_DATA = 2,
  COMPANY_ADDRESS = 3,
  BANK_REQUISITES = 4,
  MODERATE = 5
}

export interface AuthentificationFormProps {
  data: AuthentificationData
  fieldErrors?: Array<ILegalInformationError>
  goToPrevStep: (values: AuthentificationData) => void
  goToNextStep: (values: AuthentificationData) => void
  mode: 'create' | 'edit'
}

export type AuthentificationFormTitles = { [k in Exclude<Steps, Steps.MODERATE>]: string }

export interface ILegalInformation {
  ceo: ILegalInformationCeo
  bankAccount: ILegalInformationBankAccount
  _id: string
  billingDescriptor: string
  name: string
  fullName: string
  inn: string
  ogrn: string
  kpp: '000000000' | string
  addresses: ILegalInformationAddress[]
  email: string
  siteUrl: string
  createdAt: Date
  updatedAt: Date
  __v: number
}

export interface ILegalInformationAddress {
  type: 'actual' | 'legal'
  zip: string
  country: 'RUS'
  city: string
  street: string
}

export interface ILegalInformationBankAccount {
  tax: number
  account: string
  bankName: string
  bik: string
  details: string
}

export interface ILegalInformationCeo {
  firstName: string
  lastName: string
  middleName: string
  country: string
  phone: string
  birthDate: Date
}
