export const compareTwoArrays = (arr1: Array<unknown>, arr2: Array<unknown>) => {
  if (!arr1 || !arr2) return false

  const array2Sorted = arr2.slice().sort()

  return (
    arr1.length === arr2.length &&
    arr1
      .slice()
      .sort()
      .every((value, index) => value === array2Sorted[index])
  )
}
