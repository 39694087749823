import React from 'react'
import { Text } from 'recharts'
import { getDayFromStringDate } from 'utils/getDayFromStringDate'

const CustomTick = (e: any) => {
  const {
    payload: { value }
  } = e
  e.fill = getDayFromStringDate(value) === 'Monday' ? '#5E6774' : '#BDBEBF'
  return <Text {...e}>{value}</Text>
}

export default CustomTick
