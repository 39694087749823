export enum EProductCharacteristicType {
  Boolean = 'boolean',
  Number = 'number',
  String = 'string'
}

type TProductBase = {
  _id: string
  productName: string
  SKU: string
  discountPrice: number
  stock: number
}

export interface IProduct extends TProductBase {
  package: {
    weight: number
    width: number
    height: number
    length: number
  }
  shop: string
  categories: [{ name: string; _id: string }]
  barcode: number
  characteristics: IProductCharacteristic[]
  description: string
  regularPrice: number
  active: boolean
  verified: boolean
  offers: any[]
  images: [
    {
      order: number
      link: string
    }
  ]
  createdAt: Date
  updatedAt: Date
  manufacturerSKU: string
  vat: number
  __v: number
}

export interface TProductList extends TProductBase {
  categories: string[]
  barcode?: number
  regularPrice?: number
  index: number
  images: IProductImage[]
  rating?: number
}

export interface IProductMainInfo {
  productName: string
  SKU: string
  manufacturerSKU?: string
  barcode: string | number
  regularPrice: string | number
  discountPrice?: string | number
  categories: { name: string; _id: string }[]
  package: {
    weight: string | number
    width: string | number
    height: string | number
    length: string | number
  }
  vat?: string | number
}

// export interface IProductCharacteristic {
//   characteristic: IProductCharacteristicList;
//   value: string[];
//   title: string;
//   _id: string;
// }
//
// export interface IProductCharacteristicList {
//   isDynamic: boolean;
//   _id: string;
//   title: string;
//   value: string[];
//   type: EProductCharacteristicType;
//   required: boolean;
//   __v: number;
//   category: string;
//   key: string;
//   measurementUnit: null | string;
// }

export interface IProductCharacteristic {
  options?: (null | undefined | string)[] | null
  required: boolean
  title: string
  type: EProductCharacteristicType
  value: (null | undefined | string)[] | null
  isDynamic: boolean
  category: string
  key: string
  measurementUnit: null | string
  __v?: number
  _id: string
}

export interface IProductImage {
  _id?: string
  filename: string
  link: string
  order: number
}

export type CharacteristicsGenericResponse = {
  status: object
  error: string
  message: {
    _id: string
    name: string
    characteristics: IProductCharacteristic[]
  }
}

export type TProductsTabCount = {
  archive: number
  errors: number
  in_moderation: number
  on_sale: number
  restock: number
}

export interface IProductError {
  _id: string
  product: IProductErrorProduct
  __v: number
  approved: boolean
  characteristics: IProductErrorCharacteristic[]
  images: IProductErrorImage[]
  mainInfo: IProductErrorMainInfo[]
}

export interface IProductErrorWithProduct extends IProductError {
  productData: TProductList | IProduct
}

export interface IProductErrorCharacteristic {
  _id: string
  key: string
  error: string
  label: string
  value: Array<number | string>
}

export interface IProductErrorImage {
  _id: string
  key: string
  error: string
}

export interface IProductErrorMainInfo {
  _id: string
  key: string
  error: string
  label: string
  value: number | string
}

export interface IProductErrorProduct {
  _id: string
  shop: string
}
