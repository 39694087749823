export const imagesDeclension = (num: number) => {
  if (!num) return 'изображений'

  let n = Math.abs(num)

  n %= 100

  if (n >= 5 && n <= 20) {
    return 'изображений'
  }

  n %= 10

  if (n === 1) {
    return 'изображение'
  }

  if (n >= 2 && n <= 4) {
    return 'изображения'
  }

  return 'изображений'
}
