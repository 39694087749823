import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLogoutUserMutation } from 'redux/auth/authApi'
import { Text, Icon } from 'components/UI'
import logo from 'public/LogoGray.svg'
import s from './header.module.scss'

const Header = () => {
  const [logout] = useLogoutUserMutation()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await logout().unwrap()
      navigate('/')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <header className={s.header}>
      <img className={s.header__logo} src={logo} alt='logo' />
      <div className={s.header__exit} onClick={handleLogout}>
        <Icon name='exit' color='middle-grey' />
        <Text as='p' color='middle-grey' size='sm'>
          Выход
        </Text>
      </div>
    </header>
  )
}

export default Header
