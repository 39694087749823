import React, { SetStateAction, useEffect, useState } from 'react'
import { IProductImage } from 'models'
import { useMessage } from 'hooks'
import { Button, ButtonGroup, Dnd, Text } from 'components/UI'
import Dropzone from './Dropzone'
import ImageItem from './ImageItem'
import s from './product-media-files.module.scss'

interface IProductMediaFilesProps {
  sellerId: string | null
  images: IProductImage[]
  setImages: React.Dispatch<SetStateAction<IProductImage[]>>
  onRemoveImage: (name: string) => void
  possiblyNextStep: number
  onSuccessTriggerForm: (step: number) => void
  copyImagesLoaded: boolean
  onSuccessCopyImagesLoaded: VoidFunction
  mode?: 'create' | 'edit' | 'copy'
}

const ProductMediaFilesSection = ({
  sellerId,
  images,
  setImages,
  onRemoveImage,
  possiblyNextStep,
  onSuccessTriggerForm,
  copyImagesLoaded,
  onSuccessCopyImagesLoaded,
  mode
}: IProductMediaFilesProps) => {
  const [cards, setCards] = useState<IProductImage[]>(images)

  const requirements = [
    { title: 'Формат:', value: 'JPG, PNG, JPEG' },
    { title: 'Разрешение:', value: 'от 200px до 7680px по большей стороне' },
    { title: 'Размер и количество:', value: 'Размер одной фотографии не больше 3 Мб и не больше 10 шт.' },
    { title: 'Фон:', value: 'Белый или серый' }
  ]

  const showImageError = () => useMessage('Превышено кол-во фотографий, уменьшите кол-во до 10', 'error')

  const handleNavigate = (page: number) => {
    if (images.length > 10) return showImageError()
    onSuccessTriggerForm(page)
  }

  useEffect(() => {
    if (images?.length !== cards?.length) setCards(images)
  }, [images, cards])

  useEffect(() => {
    setImages(cards.map((v: IProductImage, i: number) => ({ ...v, order: i })))
  }, [cards])

  useEffect(() => {
    if (possiblyNextStep > -1) {
      if (images.length > 10) {
        showImageError()
        return onSuccessTriggerForm(-1)
      }

      onSuccessTriggerForm(possiblyNextStep)
    }
  }, [possiblyNextStep])

  return (
    <div className={s['product-media-files']}>
      <Text as='p' size='lg'>
        Добавление изображений
      </Text>
      <ul className={s['image-requirements']}>
        {requirements.map((v) => (
          <li key={v.value} className={s['image-requirements__item']}>
            <Text as='p' size='xs' family='secondary' color='middle-grey'>
              {v.title}
            </Text>
            <Text as='p' size='xs' family='secondary'>
              {v.value}
            </Text>
          </li>
        ))}
      </ul>
      <div className='offset-top-24'>
        <div className={s['loaded-images']}>
          <Dnd>
            {cards.map((v: IProductImage, i) => (
              <Dnd.Item key={Math.random()} id={v?._id || Math.random()} index={i} onDrag={setCards}>
                <ImageItem image={v} onRemove={onRemoveImage} />
              </Dnd.Item>
            ))}
          </Dnd>
        </div>
        <Dropzone
          sellerId={sellerId}
          images={images}
          setImages={setImages}
          copyImagesLoaded={copyImagesLoaded}
          onSuccessCopyImagesLoaded={onSuccessCopyImagesLoaded}
          mode={mode}
        />
      </div>
      <ButtonGroup justifyContent='space-between' className='offset-top-32'>
        <Button onClick={() => onSuccessTriggerForm(1)} size='sm' color='light-blue'>
          Назад
        </Button>
        <Button onClick={() => handleNavigate(3)} size='sm'>
          Далее
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default ProductMediaFilesSection
