import { useEffect, useState } from 'react'
import { useYMaps } from '@pbe/react-yandex-maps'

export const useMap = () => {
  const [isMapInit, setIsMapInit] = useState<boolean>(false)
  const ymaps: any = useYMaps(['Map', 'geocode'])

  const getAddressByCoordinates = async (coordinates: Array<number> | undefined) => {
    const geocode = await ymaps?.geocode(coordinates)
    return geocode?.geoObjects?.get(0)?.getAddressLine()
  }

  useEffect(() => {
    setIsMapInit(Boolean(ymaps))
  }, [ymaps])

  return { ymaps, isMapInit, getAddressByCoordinates }
}
