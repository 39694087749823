import React, { FC, ReactElement, useRef, useState } from 'react'
import cx from 'classnames'
import { useOnClickOutside } from 'hooks'
import { Icon, Text } from 'components/UI'
import s from './filters.module.scss'

interface IBaseFilterProps {
  onFilterChange: (value: string) => void
  disabled?: boolean
  filterData: Array<string>
  label: string
  icon: ReactElement
}

const BaseFilter: FC<IBaseFilterProps> = ({ onFilterChange, disabled, filterData, label, icon }) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [dataFilter, setDataFilter] = useState<string>('')
  const filterWrapRef = useRef(null)
  const { onFocusElement } = useOnClickOutside(
    filterWrapRef,
    () => setOpenDropdown(!openDropdown),
    () => setOpenDropdown(false)
  )

  const onClickFilter = (value?: string) => {
    if (value) setOpenDropdown(false)

    if (value !== dataFilter) {
      setDataFilter(value || '')
      onFilterChange(value || '')
    }
  }

  return (
    <div className={cx(s['filter-block-wrapper'], s['base-filter'], { [s.disabled]: disabled })} ref={filterWrapRef}>
      <div
        className={cx(s['filter-block'], {
          [s['dropdown-open']]: openDropdown,
          [s['with-filter-data']]: dataFilter
        })}
        onClick={onFocusElement}
      >
        {icon}
        <Text color='dark-grey' size='sm'>
          {dataFilter || label}
        </Text>
      </div>
      {dataFilter && (
        <div className={s['filter-clear']} onClick={() => onClickFilter('')}>
          <Icon name='close' color='blue' size='md' />
        </div>
      )}
      <div className={cx(s['filter-dropdown'], { [s.active]: openDropdown })}>
        {filterData.map((v: string) => (
          <div
            key={v}
            className={s['status-item']}
            onClick={() => {
              if (!disabled) onClickFilter(v)
            }}
          >
            <Text size='sm'>{v}</Text>
            {v === dataFilter && <Icon name='check' color='blue' />}
          </div>
        ))}
      </div>
    </div>
  )
}

export default BaseFilter
