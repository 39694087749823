export const useTimeHook = (dateDB: Date) => {
  try {
    const time = new Date(dateDB)

    const dateFormat = Intl.DateTimeFormat('ru', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })

    const date = dateFormat.format(time)

    const minDateFormat = Intl.DateTimeFormat('ru', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    })

    const minDate = minDateFormat.format(time)

    const replaceMinDate = minDate.replace(', ', ' ')
    const replaceDate = date.replace('г.,', '  ')

    return {
      date: replaceDate.toString(),
      minDate: replaceMinDate.toString()
    }
  } catch (error) {
    return { date: new Date().toString() }
  }
}
