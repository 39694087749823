import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { setCoordinates } from 'helpers'
import { useMap, useMessage, useWindowSize } from 'hooks'
import { IInitRegistrationFormAddress } from 'models'
import { Button, ButtonGroup, DotsLoader, Icon, Text, Textarea } from 'components/UI'
import Maps from 'components/Map'
import s from './registation-modals.module.scss'

interface INewDeliveryAddressModalProps {
  initSelectedAddress?: InitSelectedAddress | undefined
  onClose: VoidFunction
  onSuccess: (data: IInitRegistrationFormAddress) => void
  mapZoom?: number
}

interface InitSelectedAddress {
  coordinates: Array<number>
  fullname?: string
}

interface IAddressWithCoords extends TFormData {
  coordinates: Array<number>
}

type TFormData = {
  fullname: string
}

const SelectAddressModal = ({ initSelectedAddress, onClose, onSuccess, mapZoom }: INewDeliveryAddressModalProps) => {
  const [isGettingUserLocalCoords, setIsGettingUserLocalCoords] = useState<boolean>(false)
  const [foundLocations, setFoundLocations] = useState<IAddressWithCoords[]>([])
  const [selectedAddress, setSelectedAddress] = useState<IAddressWithCoords | IInitRegistrationFormAddress | null>(null)
  const [openOptions, setOpenOptions] = useState<boolean>(false)
  const { deviceWidth } = useWindowSize()
  const { ymaps, isMapInit, getAddressByCoordinates } = useMap()
  const searchWrapRef = useRef(null)

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm<TFormData>()

  const getCoordinates = async (position: any) => {
    const { latitude, longitude } = position.coords
    const addressName = await getAddressByCoordinates([latitude, longitude])

    setValue('fullname', addressName)
    setSelectedAddress({ coordinates: [latitude, longitude], fullname: addressName || '' } as IAddressWithCoords)
    setIsGettingUserLocalCoords(false)
    await setCoordinates(position)
  }

  const setLocationData = (data: IAddressWithCoords) => {
    if (!data?.fullname) return

    setValue('fullname', data.fullname)
    setSelectedAddress(data as IInitRegistrationFormAddress)
    setFoundLocations([])
    setOpenOptions(false)
  }

  const onAddressSearchInput = async (address: string) => {
    if (isMapInit) {
      const results: IAddressWithCoords[] = []
      let geocode

      try {
        setOpenOptions(true)

        geocode = await ymaps.geocode(address, {
          kind: 'house',
          provider: 'yandex#map'
        })
      } catch {
        setOpenOptions(false)
        return setFoundLocations([])
      }

      for (let i = 0; i < geocode.geoObjects.getLength(); i++) {
        const element = geocode.geoObjects.get(i)

        results.push({
          fullname: element.getAddressLine(),
          coordinates: element.geometry.getCoordinates()
        })
      }

      setOpenOptions(Boolean(results?.length))
      setFoundLocations(results)
    }
  }

  const onClickOutsideResult = (e: any) => {
    const element = e.target

    // @ts-ignore
    if (searchWrapRef.current && !searchWrapRef.current?.contains(element)) {
      e.preventDefault()
      e.stopPropagation()
      setOpenOptions(false)
    }
  }

  const onFocusAddressInput = () => {
    document.body.addEventListener('click', onClickOutsideResult)
    if (foundLocations?.length) setOpenOptions(true)
  }

  const onUseMyLocation = async () => {
    const lsCoords = localStorage.getItem('sellerCoords')

    if (lsCoords) {
      const cord: Record<string, string> = JSON.parse(lsCoords)
      const addressName = await getAddressByCoordinates([+cord.latitude, +cord.longitude])

      setValue('fullname', addressName)
      setSelectedAddress({
        coordinates: [Number(cord.latitude), Number(cord.longitude)],
        fullname: addressName || ''
      } as IAddressWithCoords)
      setIsGettingUserLocalCoords(false)
      return
    }

    const errorOnGetMyLocation = (err: any) => {
      useMessage('Геолокация не поддерживается. Проверьте настройки браузера', 'error')
      setIsGettingUserLocalCoords(false)
      console.error(`ERROR(${err?.code}): ${err?.message}`)
    }

    if (navigator.geolocation) {
      setIsGettingUserLocalCoords(true)
      navigator.geolocation.getCurrentPosition(getCoordinates, errorOnGetMyLocation, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      })
    } else {
      useMessage('Геолокация не поддерживается', 'error')
    }
  }

  const onSubmit = async (data: TFormData) => {
    try {
      if (data && selectedAddress) {
        const geocode = isMapInit && (await ymaps.geocode(selectedAddress.coordinates, { kind: 'house' }))
        const locationObject: any = geocode?.geoObjects?.get(0)

        const finalData = {
          coordinates: selectedAddress.coordinates,
          street: `${(selectedAddress as IInitRegistrationFormAddress)?.street || locationObject?.getThoroughfare()} ${locationObject?.getPremiseNumber()}`,
          city: (selectedAddress as IInitRegistrationFormAddress)?.city || locationObject?.getLocalities()[0],
          fullname: data.fullname
        }

        onSuccess(finalData)
      }
    } catch (err: any) {
      console.error(err)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isMapInit && initSelectedAddress?.coordinates) {
        setSelectedAddress(initSelectedAddress as IInitRegistrationFormAddress)

        if (initSelectedAddress?.fullname) {
          return setValue('fullname', initSelectedAddress.fullname)
        }

        const addressName = await getAddressByCoordinates(initSelectedAddress.coordinates)
        setValue('fullname', addressName)
      }
    }

    fetchData()
  }, [initSelectedAddress, isMapInit])

  if (!isMapInit) return <DotsLoader center />

  return (
    <div className={s['select-address']}>
      <div className={s['select-address__map']}>
        <Maps
          width='100%'
          height='100%'
          coordinates={selectedAddress ? [selectedAddress] : []}
          onMapClick={setLocationData}
          zoom={mapZoom}
        />
      </div>
      <div className={s['select-address__form']}>
        <Button className={s['button-back']} onClick={onClose}>
          Назад
        </Button>
        <Text as='p' className={s.title} family='object-sans'>
          Адрес магазина
        </Text>
        <form className='offset-top-24' onSubmit={handleSubmit(onSubmit)} ref={searchWrapRef}>
          <div className={s['address-input-wrapper']}>
            <Controller
              name='fullname'
              control={control}
              rules={{ required: { value: true, message: 'Не заполнен адрес' } }}
              render={({ field: { name, value, onChange } }) => (
                <Textarea
                  name={name}
                  view='auth'
                  value={value || ''}
                  onChange={(e) => {
                    onChange(e)
                    onAddressSearchInput(e.target.value)
                  }}
                  onFocus={onFocusAddressInput}
                  label='Город, улица, номер дома*'
                  errors={errors}
                  rows={1}
                  fluid
                  autoHeight
                />
              )}
            />
            {openOptions && (
              <div className={s.options}>
                {foundLocations.map((v: IAddressWithCoords) => (
                  <Text
                    key={JSON.stringify(v.coordinates)}
                    as='p'
                    className={s.options__item}
                    onClick={() => setLocationData(v)}
                    family='object-sans'
                    cursor='pointer'
                  >
                    {v.fullname}
                  </Text>
                ))}
              </div>
            )}
          </div>
          {!isGettingUserLocalCoords ? (
            <div className={s['use-location']} onClick={onUseMyLocation}>
              <Icon name='pin' color='blue' />
              <Text as='p' color='blue' family='object-sans'>
                Использовать моё текущее местоположение
              </Text>
            </div>
          ) : (
            <div className={s['dots-loader']}>
              <DotsLoader />
            </div>
          )}
          <ButtonGroup className={s.buttons}>
            <Button type='submit' className={s['button-save']} fluid={deviceWidth !== 'small'}>
              Сохранить
            </Button>
            <Button className={s['button-back-mobile']} onClick={onClose} fluid={deviceWidth !== 'small'}>
              Назад
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </div>
  )
}

export default SelectAddressModal
