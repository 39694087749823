import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import {
  IProductCharacteristic,
  IProductErrorCharacteristic,
  IProductErrorImage,
  IProductErrorMainInfo,
  IProductErrorWithProduct,
  IProductImage,
  IProductMainInfo
} from 'models'
import { compareTwoArrays } from 'helpers'
import { useAppSelector } from 'redux/hook'
import { getProductById, useMessage } from 'hooks'
import { Button, Input, Select, Text } from 'components/UI'
import { ProductImage } from '../productImage'
import { getValidation } from '../models'
import $api from '../../http/axios'
import Dropzone from '../sections/productMediaFilesSection/Dropzone'
import s from './product-errors.module.scss'

interface IProductToBackend {
  mainInfo: IProductMainInfo
  characteristics: IProductCharacteristic[]
  description: string
  images: IProductImage[]
  productId?: string
}

interface IProductErrorForm {
  mainInfo: Record<string, number | string>
  characteristics: IProductErrorCharacteristic[]
  images: IProductErrorImage[]
}

interface IProductErrorsDrawerProps {
  data: IProductErrorWithProduct | undefined
}

const ProductErrorsDrawer = ({ data }: IProductErrorsDrawerProps) => {
  const [newImages, setNewImages] = useState<IProductImage[]>([])
  const [showUploadPhotoBlock, setShowUploadPhotoBlock] = useState<boolean>(false)
  const { mainInfo, characteristics, description, images, loading } = getProductById(data?.product?._id)
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm<IProductErrorForm>({
    defaultValues: {
      mainInfo: data?.mainInfo.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {}),
      characteristics: data?.characteristics,
      images: data?.images
    }
  })

  const removeImage = async (name: string) => {
    try {
      await $api.delete(`/api/seller/${activeShop?._id}/products/images?filename=${name}`, {
        headers: { 'Content-Type': 'application/json' }
      })
    } catch (e: any) {
      useMessage(e?.response?.data?.error || 'Произошла ошибка при удалении старых изображений', 'error')
      console.error(e)
    }
  }

  const removeOldImages = async (oldImages: IProductErrorImage[]) => {
    for (const image of oldImages) {
      const splitImageName = image.key.split('/')
      await removeImage(splitImageName[splitImageName.length - 1])
    }
  }

  const setFormError = (name: string | any, message: string) =>
    setError(name, {
      type: 'manual',
      message
    })

  const triggerErrors = (formData: IProductErrorForm) => {
    let hasErrors = false

    Object.entries(formData.mainInfo).forEach(([key, value]) => {
      const initFieldData = data?.mainInfo.find((v) => v.key === key)

      if (initFieldData?.value === value) {
        hasErrors = true
        setFormError(`mainInfo.${key}`, initFieldData.error)
      }
    })

    formData.characteristics.forEach((characteristic, i) => {
      const initFieldData = data?.characteristics.find((v) => characteristic.key === v.key)

      if (compareTwoArrays(initFieldData?.value as Array<unknown>, characteristic.value)) {
        hasErrors = true
        setFormError(`characteristics.${i}.value`, initFieldData?.error as string)
      }
    })

    if (formData?.images?.length && !newImages.length) {
      hasErrors = true
      useMessage('Необходимо загрузить новые фотографии', 'error')
    }

    return hasErrors
  }

  const sendForm = async (formData: IProductErrorForm) => {
    if (!triggerErrors(formData)) {
      const getNewImages = () => {
        const uniqImages = images?.length
          ? images.filter((img) => !formData.images.some((v) => v.key === img.link))
          : []
        return [...uniqImages, ...newImages].map((v, i) => ({ ...v, order: i }))
      }

      if (formData.images) await removeOldImages(formData.images)

      try {
        const productData = {
          mainInfo: formData.mainInfo ? { ...mainInfo, ...formData.mainInfo } : mainInfo,
          characteristics: formData.characteristics
            ? characteristics?.map((characteristic: IProductCharacteristic) => {
                const foundCharacteristic = formData.characteristics.find((v) => v.key === characteristic.title)

                if (foundCharacteristic) {
                  return {
                    ...characteristic,
                    value: foundCharacteristic.value
                  }
                }

                return characteristic
              })
            : characteristics,
          description,
          images: formData.images ? getNewImages() : images,
          productId: data?.product?._id
        } as IProductToBackend

        await $api.patch(`/api/seller/${activeShop?._id}/products`, JSON.stringify(productData), {
          headers: { 'Content-Type': 'application/json' }
        })
      } catch (error: any) {
        useMessage(`Ошибка при сохранении товара. ${error?.response?.data?.message ?? ''}`, 'error')
      }
    }
  }

  useEffect(() => {
    data?.mainInfo.forEach((v) => setFormError(`mainInfo.${v.key}`, v.error))
    data?.characteristics.forEach((v, i) => setFormError(`characteristics.${i}.value`, v.error))
  }, [])

  if (!data) return null

  return (
    <form className={s['product-errors']} onSubmit={handleSubmit(sendForm)}>
      <div className={s['product-errors__header']}>
        <Text as='p' size='xl'>
          {data?.productData?.productName}
        </Text>
        <Text as='p' size='xxs' color='middle-grey' family='secondary' className='offset-top-4'>
          {data?.productData?.SKU}
        </Text>
      </div>
      <div className={s['product-errors__content']}>
        {Boolean(data?.mainInfo?.length) && (
          <article>
            <Text as='p' size='lg'>
              Информация о товаре
            </Text>
            <div className='offset-top-16'>
              {data.mainInfo.map((v: IProductErrorMainInfo, i: number) => (
                <Controller
                  key={v._id}
                  name={`mainInfo.${v.key}`}
                  control={control}
                  rules={getValidation(v.key)}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      classNameInputWrapper={cx({ 'offset-top-12': i })}
                      name={v.key}
                      label={v.label}
                      value={value}
                      onChange={onChange}
                      errors={errors?.mainInfo}
                      required={getValidation(v.key)?.required}
                      fluid
                    />
                  )}
                />
              ))}
            </div>
          </article>
        )}
        {Boolean(data?.characteristics?.length) && (
          <article>
            <Text as='p' size='lg'>
              Характеристики
            </Text>
            <div className='offset-top-16'>
              {data.characteristics.map((v: IProductErrorCharacteristic, i: number) => {
                const characteristicsFromProduct = characteristics?.find(
                  (characteristic) => characteristic.title === v.key
                )

                return (
                  <Controller
                    key={v._id}
                    name={`characteristics.${i}.value`}
                    control={control}
                    rules={getValidation(v.key)}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        classNameInputWrapper={cx({ 'offset-top-12': i })}
                        name='value'
                        label={v.label}
                        onChange={onChange}
                        value={value?.filter((option) => option)}
                        mode='tags'
                        errors={errors?.characteristics?.[i]}
                        required={getValidation(v.key)?.required}
                        fluid
                        arrowIcon
                      >
                        {characteristicsFromProduct?.options?.map((opt: null | undefined | string) => (
                          <Select.Option key={opt} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  />
                )
              })}
            </div>
          </article>
        )}
        {Boolean(data?.images?.length) && (
          <article>
            <Text as='p' size='lg'>
              Изображения
            </Text>
            {!showUploadPhotoBlock ? (
              <>
                <div className={cx('offset-top-16', s['images-block'])}>
                  {data?.images?.map((v: IProductErrorImage) => (
                    <div key={v?.key} className={s['images-block__image-item']}>
                      <ProductImage src={v?.key} width={105} height={105} border={false} fit='cover' />
                    </div>
                  ))}
                </div>
                <Text as='p' size='xs' color='red' family='secondary' className='offset-top-8'>
                  Изображения отклонены, так как не отражают суть товара.
                </Text>
                <Text as='p' size='xs' family='secondary' className={s['add-new-photos']}>
                  Вы можете
                  <Text size='xs' family='secondary' color='blue' onClick={() => setShowUploadPhotoBlock(true)}>
                    добавить
                  </Text>
                  другие изображения
                </Text>
              </>
            ) : (
              <>
                <div className={cx('offset-top-16', s['images-block'])}>
                  {newImages?.map((v: IProductImage) => (
                    <div key={v?._id}>
                      <ProductImage src={v?.link} width={105} height={105} border={false} fit='cover' />
                    </div>
                  ))}
                </div>
                <div className='offset-top-16'>
                  <Dropzone
                    sellerId={activeShop?._id as string}
                    images={newImages}
                    setImages={setNewImages}
                    mode='edit'
                  />
                </div>
              </>
            )}
          </article>
        )}
      </div>
      <div className={s['product-errors__footer']}>
        <Button type='submit' disabled={loading}>
          Подтвердить
        </Button>
      </div>
    </form>
  )
}

export default ProductErrorsDrawer
