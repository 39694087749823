export enum Fields {
  name = 'name',
  kpp = 'kpp',
  ogrn = 'ogrn',
  lastName = 'lastName',
  firstName = 'firstName',
  middleName = 'middleName',
  phone = 'phone',
  birthDate = 'birthDate'
}
