import { createApi } from '@reduxjs/toolkit/query/react'
import { IResponse, TSellerInfo, TSellerShop } from 'models'
import { AppState } from '../store'
import { baseQueryWithReauth } from '../baseQuery'
import { setIsAuthCompleted } from '../auth/authSlice'
import { setActiveSellerShop, setSellerData } from './sellerSlice'

export const sellerApi = createApi({
  reducerPath: 'seller/api',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSellerInfo: builder.mutation<IResponse<TSellerInfo>, void>({
      query: () => ({
        url: '/api/seller/me',
        method: 'GET',
        credentials: 'include'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const state: any | AppState = getState()
          const selectedSellerId = state?.store?.seller?.activeShop?._id

          dispatch(setSellerData(data.data))

          if (!selectedSellerId) {
            dispatch(setActiveSellerShop(data.data.shops[0]))
          } else {
            const selectedShop = data.data.shops.find((v: TSellerShop) => v._id === selectedSellerId)
            if (selectedShop) dispatch(setActiveSellerShop(selectedShop))
          }

          dispatch(setIsAuthCompleted(Boolean(data?.data?.shops?.length)))
        } catch (error) {
          console.error(error)
        }
      }
    })
  })
})

export const { useGetSellerInfoMutation } = sellerApi
