import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import useSWR from 'swr'
import $api from 'components/http/axios'
import { Button, Icon, Text } from 'components/UI/index'
import { Category, CategoryResponse, SubCategory } from './types'
import { SearchCategoryInput } from './searchCategoryInput'
import s from './style.module.scss'

interface ICategoryPickModalProps {
  onSuccess: (categories: { name: string; _id: string }[], categoryId: string | undefined) => void
}

const SelectCategory = ({ onSuccess }: ICategoryPickModalProps) => {
  const [categories, setCategories] = useState<(Category | SubCategory | undefined)[]>()
  const [selectedCategories, setSelectedCategories] = useState<Array<Category | undefined>>([])
  const { data: categoryResponse } = useSWR<CategoryResponse>('/api/card/category', (url: string) =>
    $api.get(url).then((res) => res.data)
  )

  const findCategory = (category: Category | SubCategory | undefined) => {
    if (categoryResponse && category) {
      return categoryResponse.message.find((element) => element.name === category.name)
    }
    console.error('Категория не найдена')
  }

  const separateMainCategoires = (data: CategoryResponse) =>
    data.message.filter((category: Category) => {
      if (!category.nesting) return category
    })

  const handleBackToPreviousCategory = () => {
    const pickedCategoriesCopy = [...selectedCategories]

    pickedCategoriesCopy.pop()
    setSelectedCategories(pickedCategoriesCopy)

    const foundCategory = findCategory(selectedCategories[selectedCategories.length - 2])

    if (foundCategory) {
      setCategories(foundCategory.sub)
    } else {
      setSelectedCategories([])
    }
  }

  const isFinalCategory = () => {
    const lastCategory = selectedCategories[selectedCategories.length - 1]

    if (lastCategory) {
      const foundCategory = findCategory(lastCategory)

      return foundCategory && !foundCategory?.sub.length
    }
  }

  const submitCategory = () => {
    const submitCategories: { name: string; _id: string }[] = selectedCategories.map((item) => ({
      name: item?.name as string,
      _id: item?._id as string
    }))
    const categoryId = selectedCategories[selectedCategories.length - 1]?._id

    onSuccess(submitCategories, categoryId)
  }

  useEffect(() => {
    if (selectedCategories.length > 0) {
      const foundCategory = findCategory(selectedCategories[selectedCategories.length - 1])
      if (foundCategory) {
        setCategories(foundCategory.sub)
      }
    } else if (categoryResponse) {
      const mainCategories = separateMainCategoires(categoryResponse)
      if (mainCategories) {
        setCategories(mainCategories)
      }
    }
  }, [selectedCategories, categoryResponse])

  return (
    <div className={s['select-category']}>
      <div className={s['select-category__header']}>
        <Text as='p' size='xl'>
          Выбор категории товара
        </Text>
        <Text as='p' size='xxs' className='offset-top-4' family='secondary' weight='regular'>
          Выберите из списка или начните ввод
        </Text>
      </div>
      <div className={s['select-category__category-search']}>
        <SearchCategoryInput
          categories={categoryResponse?.message}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
      </div>
      <ul className={s['select-category__categories-list']}>
        {categories && (
          <>
            {Boolean(selectedCategories.length) && (
              <li className={cx(s['category-item'], s['prev-category'])} onClick={handleBackToPreviousCategory}>
                <Icon name='arrow-left' color='middle-grey' size='xs' />
                <Text size='md'>{selectedCategories[selectedCategories.length - 1]?.name}</Text>
              </li>
            )}
            {categories.map((category) => (
              <li
                key={category?._id}
                className={s['category-item']}
                onClick={() => setSelectedCategories([...selectedCategories, category])}
              >
                <Text size='md' weight='regular' family='secondary'>
                  {category?.name}
                </Text>
                <Icon name='arrow-right' color='middle-grey' size='xs' />
              </li>
            ))}
          </>
        )}
      </ul>
      <div className={s['select-category__button']}>
        <Button disabled={!isFinalCategory()} onClick={submitCategory}>
          Подтвердить
        </Button>
      </div>
    </div>
  )
}

export default SelectCategory
