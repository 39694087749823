import { CompanyTypes } from './types'

export const companyTypes = [
  { key: CompanyTypes.OOO, value: 'ООО' },
  { key: CompanyTypes.IP, value: 'ИП' }
  // { key: CompanyTypes.OAO, value: 'ОАО' },
  // { key: CompanyTypes.ZAO, value: 'ЗАО' },
  // { key: CompanyTypes.AO, value: 'АО' }
]

export enum Fields {
  companyType = 'companyType',
  inn = 'inn'
}
