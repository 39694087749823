import React from 'react'
import { IProductCharacteristic } from 'models'
import { Button, Text } from 'components/UI'
import styles from './ProductСharacteristics.module.scss'

interface IProductCharacteristicsProps {
  count?: number
  data: IProductCharacteristic[]
  onScrollTo?: VoidFunction
}

const ProductCharacteristics = ({ count = 2, data, onScrollTo }: IProductCharacteristicsProps) => {
  const items = data.slice(0, count)

  return (
    <div className={styles['product-characteristics']}>
      <div>
        {items.map((item: IProductCharacteristic) => {
          if (item.value !== null && item.value.length > 0)
            return (
              <dl key={item._id} className={styles.dl}>
                <dt className={styles.dt}>
                  <Text family='secondary' color='middle-grey'>
                    {item.title}
                  </Text>
                </dt>
                <div className={styles.dd}>
                  {item.value.map((value, index) => (
                    <dd key={value} className={styles.dd}>
                      <Text family='secondary'>
                        {value}
                        {item.value !== null && index !== item.value.length - 1 && ',  '}
                      </Text>
                    </dd>
                  ))}
                </div>
              </dl>
            )
        })}
      </div>
      {count < data.length && (
        <Button view='link' onClick={() => onScrollTo?.()} className='offset-top-10'>
          Все характеристики
        </Button>
      )}
    </div>
  )
}

export default ProductCharacteristics
