import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import { IOtpForm } from 'models'
import { useVerifyOTPMutation } from 'redux/auth/authApi'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { Button, FormError, Input, Text, Timer } from 'components/UI'
import s from './phone-auth.module.scss'

interface IOtpProps {
  phone: string
  resendTimer: number
  onAuthTypeChange: (data: string) => void
  onChangeNumber: (data: boolean) => void
  onRepeatCode: (data: { phoneNumber: string }) => void
}

const Otp = ({ phone, resendTimer, onAuthTypeChange, onChangeNumber, onRepeatCode }: IOtpProps) => {
  const [due, setDue] = useState(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [verifyOTP] = useVerifyOTPMutation()
  const [getSellerInfo] = useGetSellerInfoMutation()
  const navigate = useNavigate()

  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = useForm<IOtpForm>()

  const onSubmitOtp = async (otp: string) => {
    try {
      await verifyOTP({ otp, phoneNumber: phone.replace(/[- )(]/g, '') }).unwrap()
      const sellerInfoResponse = await getSellerInfo().unwrap()

      if (sellerInfoResponse?.data?.shops?.length) {
        navigate(routes.DASHBOARD)
      } else {
        navigate(routes.REGISTRATION)
      }
    } catch (err: any) {
      if (!err?.status) {
        setErrMsg('Нет ответа от сервера')
      } else if (err.status) {
        setErrMsg(err.data.message)
        setValue('otp', '')
      } else {
        setErrMsg('Регистрация не удалась')
      }
    }
  }

  const resendOtp = async () => {
    setDue(false)
    setErrMsg('')
    onRepeatCode({ phoneNumber: phone })
  }

  useEffect(() => {
    if (watch('otp')?.length === 5) {
      setErrMsg('')
      onSubmitOtp(watch('otp'))
    }
  }, [watch('otp')])

  if (!phone) {
    onChangeNumber(false)
    return null
  }

  return (
    <form className={cx(s['phone-auth'], 'offset-top-40')}>
      <Text as='p' family='object-sans' weight='regular' className={s.number}>
        Введите код из СМС
      </Text>
      <Text as='p' family='object-sans' weight='regular' className={s.description} color='gray'>
        Код подтверждения был отправлен на номер
        <Text family='object-sans' weight='regular' className={s['change-number']}>
          {phone}
          <Button onClick={() => onChangeNumber(false)} view='link'>
            Изменить
          </Button>
        </Text>
      </Text>
      <Input
        name='otp'
        view='auth'
        register={register}
        rules={{ required: { value: true, message: 'Не заполнено поле телефон' } }}
        errors={errors}
        classNameInputWrapper='offset-top-16'
        className={s['otp-input']}
        autoComplete='off'
        maxLength={5}
        autoFocus
        textCenter
        fluid
      />
      <FormError message={errMsg} />
      <div className='offset-top-32'>
        {due ? (
          <Button onClick={resendOtp} view='link' fluid>
            Получить новый код
          </Button>
        ) : (
          <Text
            as='p'
            size='md'
            color='gray'
            family='object-sans'
            weight='regular'
            align='center'
            className={s['get-new-code']}
          >
            Новый код можно будет запросить через
            <Timer setDue={setDue} range={resendTimer} />
          </Text>
        )}
      </div>
      <Button className='offset-top-92' onClick={() => onAuthTypeChange('email')} view='link' fluid>
        Войти с помощью почты
      </Button>
    </form>
  )
}

export default Otp
