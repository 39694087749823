export const SOCKET_EVENTS = {
  CLEAR_DIALOG: 'clear_dialog',
  NEW_MESSAGE: 'new_message',
  TYPING_MESSAGE: 'typing_message',
  UPDATE_DIALOGS: 'update_dialogs',
  READING_MESSAGES: 'reading_messages',
  PLAY_AUDIO: 'play_audio',
  NEW_MESSAGE_ADDED: 'new_message_added',
  GET_MESSAGE: 'get_message',
  GET_APPEAL_MESSAGE: 'get_appeal_message',
  APPEAL_MESSAGE: 'appeal_message',
  MESSAGES: 'messages',
  ADD_MANAGER: 'add_manager',
  GET_COMPANION: 'get_companiom',
  COMPANION: 'companion'
}
