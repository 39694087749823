import React, { ReactElement, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { Icon, Input, Text } from 'components/UI'
import { useOnClickOutside, useDebounce, useQueryParams, useMessage } from 'hooks'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'redux/hook'
import $api from 'components/http/axios'
import { handleCategorySearch } from 'hooks/handleCategorySearch'
import s from './style.module.scss'

interface ISearchFilterProps {
  onChange: (value: any) => void
  placeholder?: string
  disabled?: boolean
  title: string
  icon: ReactElement
  itemLabel?: string
}

const SearchFilter = ({ onChange, placeholder = 'Поиск', disabled, title, icon, itemLabel }: ISearchFilterProps) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [value, setValue] = React.useState<string>('')
  const filterWrapRef = useRef(null)
  const searchInputRef = useRef(null)
  const debouncedValue = useDebounce<string>(value, 300)
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const { getQueryParams, setQueryParams, searchParams, deleteQueryParams } = useQueryParams()

  const { onFocusElement, onClickOutside } = useOnClickOutside(
    filterWrapRef,
    () => setOpenDropdown(true),
    () => setOpenDropdown(false),
    false
  )

  const { data: products } = useQuery({
    queryKey: ['getProducts', activeShop?._id, ...searchParams.entries()],
    queryFn: async () => {
      const { data, status } = await $api.get(
        `/api/seller/${activeShop?._id}/products?${new URLSearchParams(searchParams).toString()}`
      )
      if (status !== 200) return useMessage('Ошибка получения товаров!', 'error')
      return data?.products
    },
    retry: 2,
    enabled: Boolean(getQueryParams('text'))
  })

  const { categories } = handleCategorySearch(getQueryParams('category') || '')

  const onClearInput = () => {
    setOpenDropdown(false)
    setValue('')
  }

  useEffect(() => {
    setQueryParams(title === 'Товар' ? 'text' : 'category', debouncedValue.trim() || '')
  }, [debouncedValue])

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)

    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [filterWrapRef])

  const chooseProduct = (e: any) => {
    onChange(e)
    deleteQueryParams('text')
    deleteQueryParams('category')
  }

  useEffect(() => {
    onClearInput()
  }, [itemLabel])

  return (
    <div
      ref={filterWrapRef}
      className={cx(s.search, { [s.disabled]: disabled })}
      onClick={(e) => {
        e.stopPropagation()
        onFocusElement()
        deleteQueryParams('text')
      }}
    >
      <div
        className={cx(s['filter-block'], {
          [s['dropdown-open']]: openDropdown,
          [s['with-filter-data']]: itemLabel
        })}
      >
        {icon}
        <Text color='dark-grey' size='sm'>
          {itemLabel || title}
        </Text>
      </div>
      {itemLabel && (
        <div className={s['filter-clear']} onClick={() => onChange(null)}>
          <Icon name='close' color='blue' size='md' />
        </div>
      )}
      {openDropdown && (
        <div className={s['search-input-wrapper']}>
          <Input
            ref={searchInputRef}
            name='search'
            type='search'
            view='search'
            placeholder={placeholder}
            value={value}
            classNameInputWrapper={s['search-input']}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!disabled) {
                setValue(e.target.value)
              }
            }}
            onClear={onClearInput}
            fluid
            autoFocus
          />
          <div className={s['list-items']}>
            {products &&
              products.map((e: any) => (
                <div key={Math.random()} onClick={() => chooseProduct(e)} className={s['list-item']}>
                  <img className={s['list-item__image']} src={e.images[0].link} alt={e.productName} />
                  <Text as='p' family='object-sans' className={s['list-item__title']} align='start'>
                    {e.productName}
                  </Text>
                </div>
              ))}
            {categories &&
              categories.map((e: any) => (
                <div
                  key={Math.random()}
                  onClick={() => chooseProduct({ ...e.v[0], fullName: e.text })}
                  className={s['list-item']}
                >
                  <Text as='p' family='object-sans' className={s['list-item__title']} align='start'>
                    {e.text}
                  </Text>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchFilter
