import { CompanyTypes } from 'components/registration/steps/requisitesStep/types'
import { isIp, isOOO } from './checkInn'

export const validateInn = (value?: string, companyTypeVal?: string) => {
  if (companyTypeVal === CompanyTypes.IP) {
    return isIp(value) ? true : 'Некорректный ИНН'
  }
  if (companyTypeVal === CompanyTypes.OOO) {
    return isOOO(value) ? true : 'Некорректный ИНН'
  }
  return 'Некорректный ИНН'
}
