import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, ButtonGroup, Calendar, Checkbox, Input } from 'components/UI'
import { isIp, isOOO } from 'utils/checkInn'
import { Fields } from './const'
import { setDadataValueInForm, setFormError } from '../../utils'
import { AuthentificationData, AuthentificationFormProps } from '../..'

const CheckDataStep = ({ data, fieldErrors, goToPrevStep, goToNextStep, mode }: AuthentificationFormProps) => {
  const {
    handleSubmit,
    control,
    register,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors }
  } = useForm<AuthentificationData>({
    mode: 'onChange',
    defaultValues: {
      ...data
    }
  })

  const lastNameIsRequired = watch(`${Fields.middleName}Required`) ?? true

  const onSubmit = async (formData: AuthentificationData) =>
    goToNextStep({
      ...formData,
      [Fields.name]: formData[Fields.name]?.trim(),
      [Fields.phone]: formData[Fields.phone].replace(/[- )(]/g, ''),
      [Fields.middleName]: !lastNameIsRequired ? 'ОТСТУСТВУЕТ' : formData[Fields.middleName]
    })

  useEffect(() => {
    if (!lastNameIsRequired && errors?.[Fields.middleName]) {
      delete errors?.[Fields.middleName]
    }
  }, [lastNameIsRequired])

  useEffect(() => {
    setFormError(fieldErrors, Fields, getValues, setError)
  }, [])

  useEffect(() => {
    if (mode === 'create') setDadataValueInForm(data.inn ?? '', setValue)
  }, [mode])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isIp(data?.inn) && (
        <Controller
          name={Fields.ogrn}
          control={control}
          rules={{
            required: { value: true, message: 'Обязательное поле' },
            pattern: { value: /^\d*$/, message: 'Некорректный формат' },
            minLength: { value: 15, message: 'Некорректная длинна ОГРНИП' },
            maxLength: { value: 15, message: 'Некорректная длинна ОГРНИП' }
          }}
          render={({ field: { onChange, ...rest } }) => (
            <Input
              view='auth'
              label='ОГРНИП'
              classNameInputWrapper='offset-top-16'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
              errors={errors}
              minLength={15}
              maxLength={15}
              {...rest}
              required
              fluid
            />
          )}
        />
      )}
      {isOOO(data?.inn) && (
        <>
          <Controller
            name={Fields.name}
            control={control}
            rules={{
              required: { value: true, message: 'Обязательное поле' },
              minLength: { value: 3, message: 'Не меньше трех символов' }
            }}
            render={({ field }) => (
              <Input
                view='auth'
                label='Юридическое название организации'
                errors={errors}
                maxLength={300}
                required
                fluid
                {...field}
              />
            )}
          />
          <Controller
            name={Fields.kpp}
            control={control}
            rules={{
              required: { value: true, message: 'Обязательное поле' },
              pattern: { value: /^\d*$/, message: 'Некорректный формат' },
              minLength: { value: 9, message: 'Некорректная длинна КПП' },
              maxLength: { value: 9, message: 'Некорректная длинна КПП' }
            }}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                view='auth'
                label='КПП'
                classNameInputWrapper='offset-top-16'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
                errors={errors}
                minLength={9}
                maxLength={9}
                {...rest}
                required
                fluid
              />
            )}
          />
          <Controller
            name={Fields.ogrn}
            control={control}
            rules={{
              required: { value: true, message: 'Обязательное поле' },
              pattern: { value: /^\d*$/, message: 'Некорректный формат' },
              minLength: { value: 13, message: 'Некорректная длинна ОГРН' },
              maxLength: { value: 13, message: 'Некорректная длинна ОГРН' }
            }}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                view='auth'
                label='ОГРН'
                classNameInputWrapper='offset-top-16'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
                errors={errors}
                minLength={13}
                maxLength={13}
                {...rest}
                required
                fluid
              />
            )}
          />
        </>
      )}
      <Controller
        name={Fields.lastName}
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          minLength: { value: 2, message: 'Не меньше двух символов' }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='Фамилия генерального директора'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            maxLength={50}
            {...rest}
            required
            fluid
          />
        )}
      />
      <Controller
        name={Fields.firstName}
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          minLength: { value: 2, message: 'Не меньше двух символов' }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='Имя генерального директора'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            maxLength={50}
            {...rest}
            required
            fluid
          />
        )}
      />
      <Controller
        name={Fields.middleName}
        control={control}
        rules={{
          required: { value: lastNameIsRequired, message: 'Обязательное поле' },
          minLength: { value: 2, message: 'Не меньше двух символов' }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <Input
            view='auth'
            label='Отчество генерального директора'
            classNameInputWrapper='offset-top-16'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange((e.target.value as string)?.trim())}
            errors={errors}
            maxLength={60}
            required={lastNameIsRequired}
            disabled={!lastNameIsRequired}
            {...rest}
            fluid
          />
        )}
      />
      <Controller
        name={`${Fields.middleName}Required`}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <Checkbox
            checked={!value}
            onChange={(e) => onChange(!e.target.checked)}
            name={name}
            label='нет отчества'
            size='md'
            classNameInputWrapper='offset-top-8'
          />
        )}
      />
      <Input
        name={Fields.phone}
        view='auth'
        type='tel'
        register={register}
        label='Номер телефона генерального директора'
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: /\+\d{1}\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}/, message: 'Неверный номер телефона' }
        }}
        errors={errors}
        classNameInputWrapper='offset-top-16'
        autoComplete='none'
        fluid
        required
        isActive
      />
      <Controller
        name={Fields.birthDate}
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        render={({ field: { name, value, onChange } }) => (
          <Calendar
            classNamesWrapper='offset-top-16'
            view='auth'
            name={name}
            startDate={value}
            onChange={onChange}
            label='Дата рождения генерального директора*'
            dateFormat='d MMMM yyyy'
            yearDropdownItemNumber={100}
            errors={errors}
            showYearDropdown
            showMonthDropdown
            fluid
          />
        )}
      />
      <ButtonGroup gap={8} className='offset-top-24'>
        <Button color='light-blue' onClick={() => goToPrevStep(getValues())} size='lg'>
          Назад
        </Button>
        <Button type='submit' size='lg'>
          Далее
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default CheckDataStep
