import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useParams } from 'react-router-dom'
import { useChatContext } from 'pages/Chat/Chat'
import { useAppSelector } from '../../../redux/hook'
import { SOCKET_EVENTS } from '../constants_chat'
import ConservationItems from './ConservationItems'
import HeaderConservation from './HeaderConservation'
import { IChat, IMessage } from '../types'
import styles from './styles/conservation.module.scss'

type Props = {
  setSendLoading: (value: boolean) => void
  clearDialog: (dialogId: string) => void
}

const Conservation: React.FC<Props> = ({ setSendLoading, clearDialog }) => {
  const { setDialogs, socket, dialogs, setPending, setTypingDialogs } = useChatContext()
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const [searchDialogsResult, setSearchDialogsResult] = useState<IChat | null>(null)
  const { id } = useParams()

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.NEW_MESSAGE_ADDED, (message: IChat) => {
        setPending((prev: any) => prev.filter((uid: string) => uid !== message.uid))
      })

      socket.on(SOCKET_EVENTS.UPDATE_DIALOGS, (dialog: IChat) => {
        const item: any = dialogs.find(({ _id }: { _id: string }) => _id.toString() === dialog._id)

        if (item) {
          item.lastMessage = dialog.lastMessage
          item.messages = dialog.messages

          if (dialog._id !== id) {
            item.counter = dialog.messages.filter((message) => message.user !== activeShop?._id && !message.read).length
          } else {
            item.counter = 0
          }

          setDialogs((prev: IChat[]) => [item, ...prev.filter((d) => d._id !== dialog._id)])
        } else {
          dialog.counter = dialog.messages.filter((m: IMessage) => m.user !== activeShop?._id && !m.read).length

          setDialogs((prev: IChat[]) => [dialog, ...prev])
        }

        setTypingDialogs((prev: any) => prev.filter((d: any) => d !== dialog._id))
        setSendLoading(false)
      })

      socket.on(SOCKET_EVENTS.READING_MESSAGES, (dialog: string) => {
        const item = dialogs.find(({ _id }: { _id: string }) => _id.toString() === dialog)

        if (item) {
          item.messages.forEach((message: any) => (message.read = true))
          setDialogs([...dialogs])
        }
      })

      // Очищение диалога
      socket.on(SOCKET_EVENTS.CLEAR_DIALOG, clearDialog)
    }

    return () => {
      socket.off(SOCKET_EVENTS.NEW_MESSAGE_ADDED)
      socket.off(SOCKET_EVENTS.CLEAR_DIALOG)
      socket.off(SOCKET_EVENTS.UPDATE_DIALOGS)
      socket.off(SOCKET_EVENTS.READING_MESSAGES)
    }
  }, [dialogs, id])

  return (
    <div className={styles.conservation}>
      <HeaderConservation dialogs={dialogs} setSearchDialogsResult={setSearchDialogsResult} />
      <Scrollbars autoHide>
        {searchDialogsResult
          ? [searchDialogsResult].map((item: any) => <ConservationItems {...item} />)
          : dialogs.map((item: any) => <ConservationItems {...item} />)}
      </Scrollbars>
    </div>
  )
}

export default Conservation
