import React from 'react'

export const getPasteData = (e: React.ClipboardEvent<HTMLInputElement>) => {
  // @ts-ignore
  const pasted = e.clipboardData || window.clipboardData

  if (pasted) {
    return pasted.getData('Text')
  }
}
