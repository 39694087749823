import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DndProvider } from 'react-dnd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { HTML5Backend } from 'react-dnd-html5-backend'
import Item from './Item'

interface IDndProps {
  children?: React.ReactNode
}

const Dnd = ({ children }: IDndProps) => <DndProvider backend={HTML5Backend}>{children}</DndProvider>

export default Object.assign(Dnd, { Item })
