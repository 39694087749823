import React, { useCallback, useRef } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDrag, useDrop } from 'react-dnd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Identifier, XYCoord } from 'dnd-core'
// eslint-disable-next-line import/no-extraneous-dependencies
import update from 'immutability-helper'

interface IItemProps {
  children: React.ReactNode
  id: number | string
  index: number
  onDrag?: any
  className?: string
}

interface DragItem {
  index: number
  id: string
  type: string
}

const Item = ({ children, id, index, onDrag, className }: IItemProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    onDrag((prevCards: any[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
  }, [])

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'box',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'box',
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(drop(ref))

  const opacity = isDragging ? 0 : 1

  return (
    <div ref={ref} style={{ opacity }} className={className} data-handler-id={handlerId}>
      {children}
      {/* {Children.map(children, (child) => { */}
      {/*  if (!isValidElement(child)) return null */}

      {/*  return cloneElement(child, { */}
      {/*    ...child.props, */}
      {/*    dndIsDragging: isDragging */}
      {/*  }) */}
      {/* })} */}
    </div>
  )
}

export default Item
