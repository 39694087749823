import React from 'react'
import cx from 'classnames'

interface IIconProps {
  children?: React.ReactNode
  name?: string
  className?: string
  style?: React.CSSProperties
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs' | string
  color?: 'primary' | 'white' | 'dark-grey' | 'middle-grey' | 'extra-grey' | 'blue' | 'red'
  title?: string
  hoverColor?: 'gray' | 'dark'
  onClick?: (data?: any) => void
  onBlur?: VoidFunction
  disable?: boolean
}

function Icon({
  children,
  name,
  className,
  style,
  size = 'md',
  color = 'primary',
  title,
  hoverColor,
  onClick,
  onBlur,
  disable = false,
  ...props
}: IIconProps) {
  const classNames = cx(
    'icon',
    {
      [`icon-${name}`]: name,
      [`size-${size}`]: size,
      [`color-${color}`]: color,
      [`hover-${hoverColor}`]: hoverColor
    },
    className
  )

  if (!name) return null

  return (
    <i
      className={classNames}
      style={style}
      title={title}
      onClick={!disable ? onClick : () => {}}
      onBlur={!disable ? onBlur : () => {}}
      {...props}
    >
      {children}
    </i>
  )
}

export default Icon
