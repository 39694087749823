import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import { SHOP_URL } from 'constants/variables'
import { Text } from 'components/UI'
import logo from 'public/Logo.svg'
import s from './home-page.module.scss'

type IFooterItemProps = {
  link: string
  title: string
}

const HomePage = () => {
  const FooterItem = ({ title, link }: IFooterItemProps) => (
    <Link to={link} target='_blank'>
      <Text className={s.footer__link} family='object-sans'>
        {title}
      </Text>
    </Link>
  )

  return (
    <div className={s['home-page']}>
      <div className={s.layout}>
        <header className={s.header}>
          <Link to={routes.AUTH} className={cx(s.link, s.white)}>
            Личный кабинет
          </Link>
        </header>
        <main className={s.main}>
          <img className={s.logo} src={logo} alt='Логотип Kovkert' />
          <Text as='h1' family='object-sans' className={cx('offset-top-54', s.title)}>
            Маркетплейс строительных и&nbsp;хозяйственных товаров
          </Text>
          <Text className={cx('offset-top-16 offset-sm-top-20', s.description)}>
            Пройдите регистрацию и&nbsp;начните <br />
            продавать свои товары по&nbsp;всей России!
          </Text>
          <Link to={routes.AUTH} className={cx(s.link, s.black)}>
            Стать продавцом
          </Link>
          <Link to={routes.AUTH} className={cx(s.link, s.white, s.isMobile)}>
            Личный кабинет
          </Link>
        </main>
        <footer className={s.footer}>
          <FooterItem title='Помощь' link={`${SHOP_URL}/docs`} />
          <FooterItem
            title='Условия обработки данных'
            link={`${SHOP_URL}/docs?to=usloviya-obrabotki-personalnikh-dannikh`}
          />
          <FooterItem title='Оферта для продавцов' link={`${SHOP_URL}/docs?to=oferta_dlya_prodavcov`} />
        </footer>
      </div>
    </div>
  )
}

export default HomePage
