import React from 'react'
import cx from 'classnames'
import { EOrderStatus as OS, TOrderCard } from 'models'
import { Icon, Text } from 'components/UI'
import s from './orders.module.scss'

interface IOrderStatusProps {
  order: TOrderCard
  onChangeStatus: (data: OS) => void
  disabled?: boolean
}

const OrderStatus = ({ order, onChangeStatus, disabled }: IOrderStatusProps) => {
  const isPaymentOnline = order.paymentMethod.label === 'Картой онлайн'

  const correctStatusNames: Record<
    string,
    {
      [key: string]: {
        status: Record<string, string>
        button: string
      }
    }
  > = {
    [OS.BEING_ASSEMBLED]: {
      'Самовывоз из магазина': {
        status: {
          actual: 'Сборка',
          passed: 'В сборке'
        },
        button: 'Начать'
      },
      'Доставка курьером': {
        status: {
          actual: 'Сборка',
          passed: 'В сборке'
        },
        button: 'Начать'
      }
    },
    [OS.DELIVERED]: {
      'Самовывоз из магазина': {
        status: {
          actual: 'Выдан',
          passed: 'Выдан'
        },
        button: 'Завершить'
      },
      'Доставка курьером': {
        status: {
          actual: 'Доставлен',
          passed: 'Доставлен'
        },
        button: 'Завершить'
      }
    }
  }

  const actualStatuses: OS[] = Object.values(OS).filter(
    (v: string) =>
      !(v === OS.CANCELLED_BY_CUSTOMER || v === OS.CANCELLED_BY_SELLER || (!isPaymentOnline && v === OS.PAID))
  )

  if (!order) return null

  return (
    <div className={s['order-status']}>
      <div className={cx(s['status-block'], s['passed-status'])} style={{ width: `${100 / 33}%` }}>
        <div className={s.point}>
          <div className={s.point__circle}>
            <Icon name='check' color='white' className={s['check-icon']} />
          </div>
          <Text className={s.point__text} family='secondary'>
            Создан
          </Text>
        </div>
      </div>
      {actualStatuses.map((v: string, i: number, arr: string[]) => {
        if (v === OS.CREATED) return
        const isPassedStatus = arr.indexOf(order.status) >= i
        const isCanActualStatus = arr.indexOf(order.status) + 1 === i

        return (
          <div
            key={v}
            className={cx(s['status-block'], s[v], {
              [s['passed-status']]: isPassedStatus,
              [s['actual-status']]: isCanActualStatus
            })}
            style={{ width: `${100 / (arr.length - 1)}%` }}
          >
            <div className={s.divider} />
            <div className={s.point}>
              <div className={s.point__circle}>
                {isPassedStatus && <Icon name='check' color='white' className={s['check-icon']} />}
              </div>
              <Text className={s.point__text} family='secondary'>
                {correctStatusNames?.[v]?.[order.deliveryType.label]?.status?.[isPassedStatus ? 'passed' : 'actual'] ||
                  v}
              </Text>
              {isCanActualStatus && (
                <div
                  className={cx(s.point__button, { [s.disabled]: disabled })}
                  onClick={() => onChangeStatus(v as OS)}
                >
                  <Text size='xxs' color='dark-grey'>
                    {correctStatusNames?.[v]?.[order.deliveryType.label]?.button}
                  </Text>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default OrderStatus
