import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { sanitizeAndConvertToNumber } from 'utils/Sanitizers'
import { IProductCharacteristic, IProductMainInfo } from 'models'
import { getDeepObjectKeys } from 'helpers'
import { useMessage, useDebounce } from 'hooks'
import { Button, ButtonGroup, Drawer, Input, InputGroup, Select, Text, SelectCategory } from 'components/UI'
import $api from 'components/http/axios'
import { getValidation } from '../models'

interface IMainInfoProps {
  data?: IProductMainInfo | object
  characteristics: IProductCharacteristic[] | []
  setCategoryId: (id?: string | undefined) => void
  possiblyNextStep: number
  onSuccessTriggerForm: (step: number) => void
  onSuccess: (data: IProductMainInfo, page: number) => void
  mode?: 'create' | 'edit' | 'copy' | undefined
  sellerId: string | null
}

interface IBackendErrors {
  [key: string]: string
}

type IFormValues = {
  productName: string
  SKU: string
  manufacturerSKU?: string
  barcode: string | number | undefined
  regularPrice: string | number | undefined
  discountPrice?: string | number | undefined
  categories: { name: string; _id: string }[]
  vat: string | number
  package: {
    weight: string | number | undefined
    width: string | number | undefined
    height: string | number | undefined
    length: string | number | undefined
  }
}

const ProductMainInfoSection = ({
  data,
  characteristics,
  setCategoryId,
  possiblyNextStep,
  onSuccess,
  onSuccessTriggerForm,
  mode = 'create',
  sellerId
}: IMainInfoProps) => {
  const [showPickCategoryModal, setShowPickCategoryModal] = useState(false)
  const [skuValue, setSkuValue] = useState<string>('')
  const [backendErrors, setBackendErrors] = useState<IBackendErrors>({})

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<IFormValues>({
    defaultValues: {
      ...data
    }
  })
  const debouncedSkuValue = useDebounce<string>(skuValue, 300)
  const navigate = useNavigate()
  const isEdit = mode === 'edit'
  const isCreate = mode === 'create'

  const onSuccessCategory = (categories: { name: string; _id: string }[], categoryId: string | undefined) => {
    setCategoryId(categoryId)
    setValue('categories', categories)
    clearErrors('categories')
    setShowPickCategoryModal(false)
  }

  const onFocusCategoryInput = () => {
    if (!showPickCategoryModal) setShowPickCategoryModal(true)
  }

  const convertAndSubmitForm = async (formData: IFormValues, nextPage: number) => {
    if (Object.keys(backendErrors).length) {
      Object.entries(backendErrors).forEach((v: any) => setError(v[0], { message: v[1] }))
      return onSuccessTriggerForm(-1)
    }

    onSuccess(
      {
        ...formData,
        barcode: sanitizeAndConvertToNumber(formData.barcode),
        regularPrice: sanitizeAndConvertToNumber(formData.regularPrice),
        discountPrice: sanitizeAndConvertToNumber(formData.discountPrice),
        vat: sanitizeAndConvertToNumber(formData.vat),
        SKU: formData.SKU.trim(),
        productName: formData.productName.trim(),
        manufacturerSKU: formData?.manufacturerSKU ? formData.manufacturerSKU.trim() : '',
        package: {
          ...formData.package,
          weight: sanitizeAndConvertToNumber(formData.package.weight),
          width: sanitizeAndConvertToNumber(formData.package.width),
          height: sanitizeAndConvertToNumber(formData.package.height),
          length: sanitizeAndConvertToNumber(formData.package.length)
        }
      } as IProductMainInfo,
      nextPage
    )
  }

  const checkSku = async (value: string | undefined) => {
    if (value) {
      try {
        clearErrors('SKU')
        setBackendErrors({})

        await $api.get(`/api/seller/${sellerId}/products/skucheck?SKU=${value}`)
      } catch (error: any) {
        if (error?.response?.data?.message) {
          setError('SKU', { message: error.response.data.message })
          setBackendErrors((prev: IBackendErrors) => ({
            ...prev,
            SKU: error.response.data.message
          }))
        } else {
          console.error(error)
        }
      }
    }
  }

  const onSubmit = (formData: IFormValues) => convertAndSubmitForm(formData, 1)

  useEffect(() => {
    if (possiblyNextStep > 0) {
      const fetchData = async () => {
        const isFormValueFieldFilled: boolean = await trigger(getDeepObjectKeys(getValues()) as any)

        if (!isFormValueFieldFilled) {
          setTimeout(() => clearErrors(), 2000)
          useMessage('Необходимо заполнить все обязательные поля', 'error')
          return onSuccessTriggerForm(-1)
        }

        convertAndSubmitForm(getValues(), isCreate && !characteristics.length ? 1 : possiblyNextStep)
      }

      fetchData()
    }
  }, [possiblyNextStep])

  useEffect(() => {
    // @ts-ignore
    if (data?.SKU !== debouncedSkuValue) {
      checkSku(debouncedSkuValue)
    }
  }, [debouncedSkuValue, data])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name='productName'
        register={register}
        rules={getValidation('productName')}
        maxLength={200}
        errors={errors}
        label='Наименование'
        fluid
      />
      <Controller
        name='categories'
        control={control}
        rules={getValidation('categories')}
        render={({ field: { name, value } }) => (
          <Input
            classNameInputWrapper='offset-top-12'
            name={name}
            label='Категория'
            value={value ? value?.map((item: { name: string; _id: string }) => item.name).join(' • ') : ''}
            disabled={isEdit}
            tooltip={isEdit ? 'Нельзя менять категорию при редактировании товара' : ''}
            suffix='arrow-right'
            onFocus={onFocusCategoryInput}
            errors={errors}
            fluid
            readOnly
            required
          />
        )}
      />
      <Input
        classNameInputWrapper='offset-top-12'
        name='barcode'
        label='Штрихкод'
        type='number'
        rules={getValidation('barcode')}
        register={register}
        errors={errors}
        tooltip='Максимальная длина 13 символов.'
        fluid
      />
      <Controller
        name='SKU'
        control={control}
        rules={getValidation('SKU')}
        render={({ field: { onChange, value, ...rest } }) => (
          <Input
            maxLength={20}
            classNameInputWrapper='offset-top-12'
            label='Артикул (в вашей системе)'
            tooltip='Артикул (код) товара в вашей учетной системе (1С, МойСклад и тд.). Не путать с артикулом производителя. Максимальная длина 20 символов'
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const trimValue = e.target.value.trim()
              setSkuValue(trimValue)
              onChange(trimValue)
            }}
            errors={errors}
            fluid
            required
            {...rest}
          />
        )}
      />
      <Input
        classNameInputWrapper='offset-top-12'
        name='manufacturerSKU'
        label='Артикул производителя.'
        maxLength={20}
        rules={getValidation('manufacturerSKU')}
        register={register}
        errors={errors}
        fluid
      />
      <InputGroup className='offset-top-12'>
        <Controller
          name='vat'
          control={control}
          rules={getValidation('vat')}
          render={({ field: { value, ...rest } }) => (
            <Select
              label='НДС %'
              value={value !== undefined ? String(value) : ''}
              errors={errors}
              required
              fluid
              {...rest}
            >
              {[0, 10, 20].map((v: number) => (
                <Select.Option key={v} value={String(v)}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          )}
        />
        <Input
          name='discountPrice'
          label='Цена'
          type='number'
          tooltip='Максимальная длина 10 символов.'
          register={register}
          rules={getValidation('discountPrice')}
          errors={errors}
          fluid
        />
        <Input
          name='regularPrice'
          label='Цена до скидки'
          type='number'
          register={register}
          rules={getValidation('regularPrice')}
          errors={errors}
          tooltip='Цена которую покупатель увидит зачеркнутой. Максимальная длина 10 символов'
          fluid
        />
      </InputGroup>
      <Text as='p' size='md' className='offset-top-32'>
        Вес и габариты товара в упаковке
      </Text>
      <InputGroup className='offset-top-24'>
        <Controller
          name='package.weight'
          control={control}
          rules={getValidation('package.weight')}
          render={({ field: { value, onChange } }) => (
            <Input
              name='weight'
              label='Вес, г'
              type='number'
              tooltip='Максимальная длина 10 символов'
              value={value || ''}
              onChange={onChange}
              errors={errors.package}
              fluid
              required
            />
          )}
        />
        <Controller
          name='package.width'
          control={control}
          rules={getValidation('package.width')}
          render={({ field: { value, onChange } }) => (
            <Input
              name='width'
              label='Ширина, мм'
              type='number'
              value={value || ''}
              onChange={onChange}
              errors={errors.package}
              fluid
              required
            />
          )}
        />
      </InputGroup>
      <InputGroup className='offset-top-12'>
        <Controller
          name='package.length'
          control={control}
          rules={getValidation('package.length')}
          render={({ field: { value, onChange } }) => (
            <Input
              name='length'
              label='Длина, мм'
              type='number'
              value={value || ''}
              onChange={onChange}
              errors={errors.package}
              fluid
              required
            />
          )}
        />
        <Controller
          name='package.height'
          control={control}
          rules={getValidation('package.height')}
          render={({ field: { value, onChange } }) => (
            <Input
              name='height'
              label='Высота, мм'
              type='number'
              value={value || ''}
              onChange={onChange}
              errors={errors.package}
              fluid
              required
            />
          )}
        />
      </InputGroup>
      <ButtonGroup justifyContent='space-between' className='offset-top-32'>
        <Button onClick={() => navigate('/products')} size='sm' color='light-blue'>
          Отмена
        </Button>
        <Button type='submit' size='sm'>
          Далее
        </Button>
      </ButtonGroup>

      <Drawer show={showPickCategoryModal} onClose={() => setShowPickCategoryModal(false)} width='402px'>
        <SelectCategory onSuccess={onSuccessCategory} />
      </Drawer>
    </form>
  )
}

export default ProductMainInfoSection
