import React, { useEffect, useState } from 'react'
import $api from 'components/http/axios'
import { TProductList } from 'models'
import { useMessage } from 'hooks'
import { useForm } from 'react-hook-form'
import { useAppSelector } from 'redux/hook'
import { Button, Checkbox, Input, Text } from 'components/UI'
import { ProductImage } from '../productImage'
import s from './edit-quantity.module.scss'

interface IEditQuantityProps {
  onClose: () => void
  product: TProductList | undefined
  onSuccess: () => void
}

interface IStockActions {
  increment: (a: number, b: number) => number
  decrement: (a: number, b: number) => number
}

interface IFormValues {
  quantity: number
}

const stockActions: IStockActions = {
  increment: (base: number, incrementValue: number) => base + incrementValue,
  decrement: (base: number, decremenValue: number) => {
    const result = base - decremenValue
    return result < 0 ? 0 : result
  }
}

const EditQuantity: React.FC<IEditQuantityProps> = ({ onClose, product, onSuccess }) => {
  const [action, setAction] = useState<keyof IStockActions>('increment')
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors }
  } = useForm<IFormValues>()

  const updateStockError = 'Не удалось обновить остатки.'

  const buttonHandler = async ({ quantity }: IFormValues) => {
    try {
      if (!product) return useMessage(updateStockError, 'error')

      const { status } = await $api.patch(`/api/seller/${activeShop?._id}/products/${product._id}/stock`, {
        quantity: stockActions[action](product.stock, Number(quantity))
      })

      if (status !== 200) useMessage(updateStockError, 'error')

      onSuccess()
      onClose()
    } catch (error) {
      useMessage(updateStockError, 'error')
    }
  }

  useEffect(() => {
    const quantityVal = watch('quantity')

    if (action === 'decrement' && product && quantityVal > product.stock) {
      setValue('quantity', product.stock)
      trigger('quantity')
    }
  }, [watch('quantity'), product])

  if (!product) return null

  return (
    <div className={s.modal}>
      <form onSubmit={handleSubmit(buttonHandler)}>
        <Text size='lg'>Изменить количество</Text>
        <Text weight='regular' as='div' className={s.modal__product}>
          <ProductImage src={product.images[0]?.link ?? ''} width={40} height={40} />
          {product.productName}
        </Text>
        <div className='offset-top-16'>
          <Checkbox
            id={action}
            name='quantity'
            type='radio'
            label='Увеличить на...'
            checked={action === 'increment'}
            onChange={() => setAction('increment')}
          />
        </div>
        <div>
          <Checkbox
            name='quantity'
            type='radio'
            label='Уменьшить на...'
            checked={action === 'decrement'}
            onChange={() => setAction('decrement')}
          />
        </div>
        <Input
          className={s.modal__input}
          rules={{ required: { value: true, message: 'Не заполнено поле' } }}
          register={register}
          errors={errors}
          name='quantity'
          type='number'
          placeholder='Количество'
          autoFocus
        />
        <div className={s.modal__quantity}>
          <Text weight='regular' as='div' color='gray'>
            Было <Text color='primary'>{product.stock}</Text> шт.
          </Text>
          <Text weight='regular' as='div' color='gray'>
            Станет <Text color='primary'>{stockActions[action](product.stock, Number(watch('quantity')) || 0)}</Text>{' '}
            шт.
          </Text>
        </div>
        <div className={s.modal__buttons}>
          <Button onClick={onClose} view='link'>
            Отмена
          </Button>
          <Button type='submit'>Сохранить</Button>
        </div>
      </form>
    </div>
  )
}

export default EditQuantity
