import React, { useState } from 'react'
import { Icon, Textarea } from 'components/UI'
import cx from 'classnames'
import s from './styles/send_message.module.scss'

interface Props {
  newMessageAdd: (message: string, file: File | null, setFIle: any) => void
  onInput: any
}

const SendMessage: React.FC<Props> = ({ newMessageAdd, onInput }) => {
  const [stateInput, setStateInput] = useState('')
  const [file, setFIle] = useState<File | null>(null)

  const changeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!file) {
      setStateInput(e.target.value)
    }
    onInput()
  }

  const sendMessage = () => {
    if (stateInput.length > 0 || file) {
      newMessageAdd(stateInput, file, setFIle)
      setStateInput('')
    }
  }

  // const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFIle(e.target.files && e.target.files[0])
  //   setStateInput('')
  // }

  const onKeyDown = (e: any, buttonSend: boolean = false) => {
    if (((!e.shiftKey && e.keyCode === 13) || buttonSend) && stateInput.trim().length) {
      sendMessage()
      setStateInput('')
      e.preventDefault()
    }
  }

  return (
    <>
      {file && (
        <div className={s.container__images}>
          <img src={URL.createObjectURL(file)} alt='' />
        </div>
      )}
      <div className={s['bottom-messenger-panel']}>
        <div className={s['bottom-messenger-panel__input_wrapper']}>
          {/* <label htmlFor='file' className={s['bottom-messenger-panel__attache']}>
            <input accept='image/*' hidden id='file' type='file' onChange={selectFile} />
            <Icon color='dark-grey' size='md' name='select_file' color='middle-grey' />
          </label> */}
          <Textarea
            rows={1}
            name='message'
            className={s['bottom-messenger-panel__input']}
            value={stateInput}
            placeholder='Сообщение...'
            onChange={changeMessage}
            onKeyDown={onKeyDown}
            view='light'
            autoHeight
            fluid
          />
        </div>

        <div className={cx(s['bottom-messenger-panel__send'], { [s.active]: stateInput })}>
          <Icon name='send' color='white' onClick={(e) => onKeyDown(e, true)} disable={!stateInput} />
        </div>
      </div>
    </>
  )
}

export default SendMessage
