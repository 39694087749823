import React, { useState } from 'react'
import { AuthLayout, PhoneAuth, EmailAuth } from 'components/auth'

const AuthPage = () => {
  const [authType, setAuthType] = useState<string>('phone')

  return (
    <AuthLayout title='Войти' subTitle='или создать профиль'>
      {authType === 'phone' && <PhoneAuth onAuthTypeChange={setAuthType} />}
      {authType === 'email' && <EmailAuth onAuthTypeChange={setAuthType} />}
    </AuthLayout>
  )
}

export default AuthPage
