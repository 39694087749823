import { Category, CategoryResponse } from 'components/UI/selectCategory/types'
import $api from 'components/http/axios'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

export const handleCategorySearch = (searchString: string) => {
  const [searchResult, setSearchResult] = useState<Category[][]>()

  const { data: categoryResponse } = useSWR<CategoryResponse>('/api/card/category', (url: string) =>
    $api.get(url).then((res) => res.data)
  )

  const pickedCategoriesToString = (data?: Category[], separator: string = ', ') => {
    const targetCategories = data || []
    return targetCategories.map((item) => item?.name).join(separator)
  }

  const getCategoryNesting = (currentCategory: Category) => {
    const nestingArray: Category[] = [currentCategory]

    while (currentCategory.nesting !== 0) {
      const prevCategory = categoryResponse?.message?.filter((category: Category) =>
        category.sub.find((element) => element.name === currentCategory.name)
      )
      if (prevCategory !== undefined && prevCategory.length > 0) {
        nestingArray.unshift(prevCategory[0])
        // eslint-disable-next-line prefer-destructuring
        currentCategory = prevCategory[0]
      } else {
        break
      }
    }
    return nestingArray
  }

  const searchCategory = (searchInput: string) => {
    if (categoryResponse?.message) {
      const result = categoryResponse?.message?.filter((element: Category) =>
        element.name.toLowerCase().includes(searchInput.toLowerCase())
      )

      setSearchResult(result.map((v) => getCategoryNesting(v)))
    }
  }

  useEffect(() => {
    if (searchString?.length > 2) {
      searchCategory(searchString)
    } else {
      setSearchResult([])
    }
  }, [searchString])

  return {
    categories:
      searchResult?.map((v: Category[]) => ({
        text: pickedCategoriesToString(v, ' • '),
        v
      })) || []
  }
}
