import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import { IProduct } from 'models'
import { Button, Text } from 'components/UI'
import { ProductImage } from '..'
import s from './product-add-search-card.module.scss'

interface IProductAddSearchCardProps {
  product: IProduct
}

const ProductAddSearchCard = ({ product }: IProductAddSearchCardProps) => {
  const navigate = useNavigate()

  const onCopyProduct = (productId: string) => navigate(routes.PRODUCTS_ADD_NEW, { state: { productId } })

  return (
    <div className={s['product-add-search-card']}>
      <div className={s['product-add-search-card__image']}>
        <ProductImage src={product?.images?.[0]?.link} height={44} width={51} />
      </div>
      <div className={s['product-add-search-card__title']}>
        <Text as='p' size='md' family='secondary'>
          {product.productName}
        </Text>
      </div>
      <div className={s['product-add-search-card__button']}>
        <Button onClick={() => onCopyProduct(product._id)} view='secondary'>
          Добавить
        </Button>
      </div>
    </div>
  )
}

export default ProductAddSearchCard
