import React, { useState } from 'react'
import { useTimeHook } from 'components/Hooks/useTime'
import { TReview } from 'models/review'
import { Text } from 'components/UI'
import { PreviewCard, ProductImage } from 'components/product'
import StarRating from 'components/StarRating/StarRating'
import ReviewReply from 'components/Review/ReviewReply/ReviewReply'
import s from './review.module.scss'

const Review: React.FC<TReview> = (review) => {
  const { createdAt, value, images, productCard, reviewer, deficiencies, advantages, comment } = review
  const [isOpenProductPreview, setIsOpenProductPreview] = useState(false)
  const { date } = useTimeHook(createdAt)

  return (
    <div className={s.review}>
      <div className={s.review__product}>
        <Text>Товар</Text>{' '}
        <Text cursor='pointer' color='blue' onClick={() => setIsOpenProductPreview(true)}>
          {productCard.productName}
        </Text>
      </div>
      <div className={s.review__images}>
        {images.map((image) => (
          <ProductImage src={image} width={56} height={56} />
        ))}
      </div>
      <div className={s.review__reviewer}>
        <Text weight='medium'>{reviewer.name ?? 'Анонимный комментарий'}</Text>
      </div>
      <div className={s.review__date}>
        <StarRating
          maxStars={5}
          emptyColor='#DADBDD'
          activeColor='#007AFF'
          width={16}
          height={14}
          defaultState={value}
          readOnly
        />
        <Text weight='regular'>{date}</Text>
      </div>
      <div className={s.review__advantages}>
        <Text className='offset-top-16' weight='medium' as='div'>
          Достоинства
        </Text>
        <Text className='offset-top-4' weight='regular' as='div'>
          {advantages}
        </Text>
      </div>
      <div className={s.review__deficiencies}>
        <Text className='offset-top-16' weight='medium' as='div'>
          Недостатки
        </Text>
        <Text className='offset-top-4' weight='regular' as='div'>
          {deficiencies}
        </Text>
      </div>
      <div className={s.review__advantages}>
        <Text className='offset-top-16' weight='medium' as='div'>
          Комментарий
        </Text>
        <Text className='offset-top-4' weight='regular' as='div'>
          {comment}
        </Text>
      </div>
      <ReviewReply {...review} />

      <PreviewCard isOpen={isOpenProductPreview} onClose={() => setIsOpenProductPreview(false)} data={productCard} />
    </div>
  )
}

export default Review
