import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import { AuthLayout } from 'components/auth'
import { InitRegistration } from 'components/registration'

const RegistrationPage = () => {
  const navigate = useNavigate()

  return (
    <AuthLayout>
      <InitRegistration onButtonBackClick={() => navigate(routes.AUTH)} />
    </AuthLayout>
  )
}

export default RegistrationPage
