import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { useOnClickOutside } from 'hooks'
import { Icon, Text } from 'components/UI'
import s from './style.module.scss'

interface IDropDownListProps<T> {
  value: string | number
  options: T[]
  onSelect: (value: T) => void
  label?: string
}

const DropDownList = <T,>({ value, options, onSelect, label }: IDropDownListProps<T>) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const dropDownListWrapRef = useRef(null)
  const { onFocusElement } = useOnClickOutside(
    dropDownListWrapRef,
    () => setOpenDropdown(!openDropdown),
    () => setOpenDropdown(false)
  )

  const handleClickItem = (item: T) => {
    onSelect(item)
    setOpenDropdown(!openDropdown)
  }

  return (
    <div className={s['drop-down-list']} ref={dropDownListWrapRef}>
      {label && (
        <Text size='sm' color='dark-grey' className={s['drop-down-list__label']} whiteSpace='nowrap'>
          {label}
        </Text>
      )}
      <div className={cx(s['drop-down-list__block'], { [s['show-list']]: openDropdown })} onClick={onFocusElement}>
        <Text size='sm' color='dark-grey'>
          {value}
        </Text>
        <Icon name={openDropdown ? 'arrow-up' : 'arrow-down'} className={s.icon} />
      </div>
      <div className={cx(s['drop-down-list__dropdown'], { [s.active]: openDropdown })}>
        {options.map((v: T) => (
          <div
            key={JSON.stringify(v)}
            onClick={() => handleClickItem(v)}
            className={s['drop-down-list__dropdown-item']}
          >
            <Text size='sm'>{v as unknown as string}</Text>
            {String(v) === String(value) && <Icon name='check' color='blue' />}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DropDownList
