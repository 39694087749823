import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react'
import { Input, Text } from 'components/UI/index'
import { Category } from '../types'
import s from './search-category.module.scss'

interface ISearchCategoryInputProps {
  categories: Category[] | undefined
  selectedCategories: (Category | undefined)[]
  setSelectedCategories: React.Dispatch<SetStateAction<Array<Category | undefined>>>
}

const SearchCategoryInput = ({ categories, setSelectedCategories, selectedCategories }: ISearchCategoryInputProps) => {
  const [searchString, setSearchString] = useState('')
  const [searchResult, setSearchResult] = useState<Category[][]>()
  const [focus, setFocus] = useState(false)
  const hasSelectedCategories = Boolean(selectedCategories?.length)

  const pickedCategoriesToString = (data?: Category[], separator: string = ', ') => {
    const targetCategories = data || selectedCategories
    return targetCategories.map((item) => item?.name).join(separator)
  }

  const getCategoryNesting = (currentCategory: Category) => {
    const nestingArray: Category[] = [currentCategory]

    while (currentCategory.nesting !== 0) {
      const prevCategory = categories?.filter((category: Category) =>
        category.sub.find((element) => element.name === currentCategory.name)
      )
      if (prevCategory !== undefined && prevCategory.length > 0) {
        nestingArray.unshift(prevCategory[0])
        // eslint-disable-next-line prefer-destructuring
        currentCategory = prevCategory[0]
      } else {
        break
      }
    }
    return nestingArray
  }

  const searchCategory = (searchInput: string) => {
    if (categories) {
      const result = categories.filter((element: Category) =>
        element.name.toLowerCase().includes(searchInput.toLowerCase())
      )

      setSearchResult(result.map((v) => getCategoryNesting(v)))
    }
  }

  useEffect(() => {
    if (searchString?.length > 2) {
      searchCategory(searchString)
    } else {
      setSearchResult([])
    }
  }, [searchString])

  return (
    <div className={s['search-category-input']}>
      <Input
        type='search'
        name='searchCategory'
        value={hasSelectedCategories ? pickedCategoriesToString() : searchString}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
        label='Категория'
        onClear={() => {
          setSelectedCategories([])
          setSearchResult([])
          setSearchString('')
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setTimeout(() => setFocus(false), 200)}
        disabled={hasSelectedCategories}
        fluid
      />
      {Boolean(searchResult?.length && !hasSelectedCategories && focus) && (
        <div className={s['search-category-input__options']}>
          {searchResult?.map((v: Category[]) => (
            <Text
              key={`${v?.[0]?._id}${Math.random()}`}
              as='p'
              size='md'
              weight='regular'
              family='secondary'
              className={s.option}
              onClick={() => setSelectedCategories(v)}
            >
              {pickedCategoriesToString(v, ' • ')}
            </Text>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchCategoryInput
