import React, { useState } from 'react'
import Star from './Star'
import styles from './star.module.scss'

type StarRatingProps = {
  maxStars: number
  emptyColor: string
  activeColor: string
  width: number
  height: number
  defaultState: number
  onChangeHover?: (data?: any) => any
  onChangeValue?: (data?: any) => any
  readOnly: boolean
}

const StarRating: React.FC<StarRatingProps> = ({
  maxStars,
  emptyColor,
  activeColor,
  width,
  height,
  defaultState,
  onChangeHover,
  onChangeValue,
  readOnly
}) => {
  const [rating, setRating] = useState(defaultState)
  const [hover, setHover] = useState(Number)

  const setRatingFn = (value: any) => {
    if (readOnly) return

    setRating(value)
    if (onChangeValue) onChangeValue(value)
  }

  const setHoverFn = (value: any) => {
    if (readOnly) return

    setHover(value)
    if (onChangeHover) onChangeHover(value)
  }

  return (
    <div className={styles.svg_container}>
      {[...Array(maxStars)].map((star, i) => {
        const value = i + 1

        return (
          <Star
            key={`${star}${i + 1}`}
            value={value}
            hover={hover}
            emptyColor={emptyColor}
            activeColor={activeColor}
            width={width}
            height={height}
            rating={rating}
            setHover={setHoverFn}
            setRating={setRatingFn}
          />
        )
      })}
    </div>
  )
}

export default StarRating
