import React from 'react'
import cx from 'classnames'
import moment from 'moment'
import { Icon, Text } from 'components/UI'
import { IMessage } from '../types'
import s from './styles/messages.module.scss'

interface ITextMessageProps {
  message: IMessage
  self: boolean
}

const TextMessage = ({ message, self }: ITextMessageProps) => (
  <div className={cx(s.message, { [s.message__self]: self })}>
    {self && (
      <>
        <Icon name={message.read ? 'msg_read' : 'msg_send'} color='blue' />
        <Text color='gray' as='div' size='xs' className={s.time}>
          {moment(message.createdAt).format('HH:mm')}
        </Text>
      </>
    )}
    <div className={cx(s.message__block)}>
      <Text as='div' color={self ? 'white' : undefined} className={s.text}>
        {message.message}
      </Text>
    </div>
    {!self && (
      <Text color='gray' as='div' size='xs' className={s.time}>
        {moment(message.createdAt).format('HH:mm')}
      </Text>
    )}
  </div>
)

export default TextMessage
