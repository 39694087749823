import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { QRCodeSVG } from 'qrcode.react'
import { TTelegramUser } from 'models'
import { Button, Icon, Text } from 'components/UI'
import $api from 'components/http/axios'
import s from './telegram-settings.module.scss'

const TelegramSettings = () => {
  const queryClient = useQueryClient()
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const { data: notificationsTg } = useQuery<TTelegramUser[] | undefined>({
    queryKey: ['notifications'],
    queryFn: async () => {
      const { data, status } = await $api.get(`/api/seller/${activeShop?._id}/notifications/tg`)
      if (status === 200) return data?.data as TTelegramUser[]
    },
    placeholderData: []
  })

  const { mutate: unsubscribeUser } = useMutation({
    mutationFn: async (chatId: number) => {
      await $api.patch(`/api/seller/${activeShop?._id}/notifications/tg/unsubscribe`, { chatId })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['notifications'] })
  })

  const { mutate: unsubscribeAllUserMutation } = useMutation({
    mutationFn: async () => {
      await $api.patch(`/api/seller/${activeShop?._id}/notifications/tg/unsubscribe/all`)
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['notifications'] })
  })

  const UserTag = ({ user }: { user: TTelegramUser }) => (
    <div className={s['user-tag']}>
      <Text size='xxs'>{user.firstName}</Text>
      <Icon
        name='close'
        color='middle-grey'
        size='sm'
        className={s['user-tag__icon']}
        onClick={() => unsubscribeUser(user.chatId)}
      />
    </div>
  )

  return (
    <div className={s['telegram-settings']}>
      <Text size='lg'>Настройка оповещений в Telegram</Text>
      <Text as='p' size='xxs' color='middle-grey' className='offset-top-8'>
        Мы доработали наши уведомления для вашего удобства, и теперь их можно бесплатно получать с помощью бота в
        Telegram. Чтобы начать получать уведомления — добавьте бота в личный чат или группу в Telegram. Больше не нужно
        проверять почту. Вы можете подключить до трех устройств одновременно.
      </Text>
      <div className={s['telegram-info']}>
        <div className={s['telegram-info__qr-code']}>
          <Link to={`${process.env.REACT_APP_TELEGRAM_LINK}?start=${activeShop?._id}`} target='_blank'>
            <QRCodeSVG
              value={`${process.env.REACT_APP_TELEGRAM_LINK}?start=${activeShop?._id}`}
              bgColor='#0FA4EC'
              fgColor='#FFFFFF'
              width={96}
              height={96}
            />
          </Link>
        </div>
        {notificationsTg && notificationsTg?.length ? (
          <div className={s['telegram-info__users']}>
            <Text size='md'>Подключения</Text>
            <div className={s['user-tags']}>
              {notificationsTg.map((v: TTelegramUser) => (
                <UserTag key={v.telegramId} user={v} />
              ))}
            </div>
            {notificationsTg.length > 10 && (
              <Button className={s['unsubscribe-all']} color='light-blue' onClick={unsubscribeAllUserMutation}>
                Отключить всех
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TelegramSettings
