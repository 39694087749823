import { createApi } from '@reduxjs/toolkit/query/react'
import { IEmailAuthForm, IPhoneAuthForm, IInitRegistrationForm, TAuthResponse, TPhoneAuthResponse } from 'models'
import { baseQueryWithReauth } from '../baseQuery'
import { logoutSeller } from '../seller/sellerSlice'
import { logout, setToken } from './authSlice'

const API = '/api/v1.0'

export const authApi = createApi({
  reducerPath: 'auth/api',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    emailAuth: builder.mutation<TAuthResponse, IEmailAuthForm>({
      query: (data) => ({
        url: `${API}/login_profile`,
        method: 'POST',
        body: { login: data.email, password: data.password },
        credentials: 'include'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          dispatch(setToken(response.data.accessToken))
        } catch (error) {
          console.error(error)
        }
      }
    }),
    phoneAuth: builder.mutation<TPhoneAuthResponse, IPhoneAuthForm>({
      query: (data) => ({
        url: `/api/auth/phone/send`,
        method: 'POST',
        body: data
      })
    }),
    verifyOTP: builder.mutation<TAuthResponse, { otp: string; phoneNumber: string }>({
      query: (data) => ({
        url: `/api/auth/phone/verify`,
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          dispatch(setToken(response.data.accessToken))
        } catch (error) {
          console.error(error)
        }
      }
    }),
    registrationSeller: builder.mutation<void, IInitRegistrationForm>({
      query: (data) => ({
        url: '/api/seller/registration',
        method: 'POST',
        body: data,
        credentials: 'include'
      })
    }),
    logoutUser: builder.mutation<void, void>({
      query: () => ({
        url: `${API}/logout`,
        credentials: 'include'
      }),
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(logout())
          dispatch(logoutSeller())
        } catch (error) {
          console.error(error)
        }
      }
    })
  })
})

export const {
  useEmailAuthMutation,
  usePhoneAuthMutation,
  useVerifyOTPMutation,
  useRegistrationSellerMutation,
  useLogoutUserMutation
} = authApi
